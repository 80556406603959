import { Box, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUserPersonalInfo } from "../api/user/useGetCurrentUserPersonalInfo";
import { useAppContext } from "../libs/contextLib";
import NotFound from "../pages/common/404";

const PrivateRoute = (props) => {
  const [personalInfo, setPersonalInfo] = useState({
    firstName: "",
    lastName: "",
    acceptedPolicy: null,
  });
  const { children, allowedRoles } = props;

  const { pathname, search } = useLocation();
  const { isAuthenticated, userRole } = useAppContext();
  const [hasFetchedPersonalInfo, setHasFetchedPersonalInfo] = useState(false);

  const { getUserPersonalInfo } = useUserPersonalInfo();

  const getPersonalInfo = async () => {
    try {
      const { firstName, lastName, acceptedPolicy } =
        await getUserPersonalInfo();
      setPersonalInfo({
        firstName: firstName,
        lastName: lastName,
        acceptedPolicy: acceptedPolicy,
      });
    } catch (error) {
      console.error("Ha ocurrido un error: ", error);
    } finally {
      setHasFetchedPersonalInfo(true);
    }
  };

  useEffect(() => {
    getPersonalInfo();
  }, []);

  if (!isAuthenticated) {
    return <Navigate to={`/login?redirect=${pathname}${search}`} />;
  }

  if (!userRole) {
    return (
      <Box
        display={"flex"}
        height={"100vh"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress />
      </Box>
    );
  }

  const hasValidPersonalInfo = (personalInfo) => {
    const { firstName, lastName, acceptedPolicy } = personalInfo;

    if (!firstName || firstName == "") {
      return false;
    }

    if (!lastName || lastName == "") {
      return false;
    }

    if (!acceptedPolicy) {
      return false;
    }

    return true;
  };

  const isAllowed = allowedRoles.some(
    (allowedRole) => allowedRole === userRole
  );

  if (!isAllowed) return <NotFound />;

  if (!hasFetchedPersonalInfo) {
    return children;
  }

  if (!hasValidPersonalInfo(personalInfo)) {
    return <Navigate to={"/user/info/change"} />;
  }

  return children;
};

PrivateRoute.propTypes = {
  allowedRoles: PropTypes.array,
  children: PropTypes.element,
};

export default PrivateRoute;
