const genericError =
  "Something went wrong. Please try again and contact support if the issue persists";

const isString = (variable) => {
  return typeof variable === "string";
};

export const mapErrorToMessage = (error) => {
  const response = error.response;
  if (!response || response.status >= 500) {
    return genericError;
  }

  if (!response.data || !response.data.detail) {
    return genericError;
  }

  if (!isString(response.data.detail)) {
    return genericError;
  }

  return response.data.detail;
};
