import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  DialogContentText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const BasicModal = ({ isOpen, onButtonClicked, title, description }) => {
  const theme = useTheme();
  return (
    <Dialog
      open={isOpen}
      onClose={onButtonClicked}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onButtonClicked}
          variant={"contained"}
          style={{
            backgroundColor: theme.palette.primary.main,
            marginLeft: "10px",
            color: "white",
          }}
          size={"medium"}
        >
          {"Continue"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BasicModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onButtonClicked: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default BasicModal;
