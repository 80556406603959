import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Grid, Step, StepLabel, Stepper } from "@mui/material";
import { MathJax } from "better-react-mathjax";

import theme from "components/foundations/themes";
import QuestionCodeEditor from "./QuestionCodeEditor.js";
import CustomEditor from "./StatementEditor";
import Choices from "./choices";
import Metadata from "./metadata";
import Preview from "./preview";
import { Subtitle, Title } from "../../elements/texts";
import CustomCard from "../cards";
import MasterPage from "../masterpage";
import styles from "pages/styles/questionDesigner.style";

import { useFindAllSkills } from "api/common/skills/useFindAllSkills";
import { useFindAllLanguages } from "api/common/skills/useFindAllLanguages";

import cleanUpParagraph from "utils/cleanSpan";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector, useDispatch } from "react-redux";
import {
  updateTestCasesEvaluationState,
  STATES,
} from "state/slices/questionDesignSlice";
import { toast } from "react-hot-toast";

const QUESTION_METADATA = "Question Metadata";
const QUESTION_STATEMENT = "Question Statement";
const QUESTION_CHOICES = "Question Choices";
const PREVIEW = "Preview";
const FINISHED = "Finished";

const Steps = [
  QUESTION_METADATA,
  QUESTION_STATEMENT,
  QUESTION_CHOICES,
  PREVIEW,
];

const stepsSequence = (steps, initialStep = steps[0]) => {
  let currentStepIndex = steps.indexOf(initialStep);

  const getNextStep = () => {
    if (currentStepIndex < steps.length - 1) {
      currentStepIndex++;
    }
    return steps[currentStepIndex];
  };

  const getPreviousStep = () => {
    if (currentStepIndex > 0) {
      currentStepIndex--;
    }
    return steps[currentStepIndex];
  };

  const isFirstStep = () => {
    return currentStepIndex === 0;
  };

  const isLastStep = () => {
    return currentStepIndex === steps.length - 1;
  };

  return { getNextStep, getPreviousStep, isFirstStep, isLastStep };
};

const QuestionTypes = {
  SINGLE_CHOICE: "single_choice",
  MULTIPLE_CHOICE: "multiple_choice",
  CODING: "coding",
};

export const DefaultWrapper = "Here you can put your custom code wrapper";

const useStyles = makeStyles(styles(theme));

const QuestionForm = ({ title, subtitle, question, onSubmit }) => {
  const dispatch = useDispatch();
  const questionDesign = useSelector((state) => state.questionDesign);

  const classes = useStyles();
  const [type, setType] = useState(question ? question.type : 0);
  const [skill, setSelectedSkill] = useState(question ? question.skill : 0);
  const [time, setTime] = useState(question ? question.time : 0);
  const [difficulty, setDifficulty] = useState(
    question ? question.difficulty : 1
  );
  const [activeStep, setActiveStep] = useState(Steps[0]);
  const [statement, setStatement] = useState(
    question ? question.statement : null
  );
  const [choices, setChoices] = useState(question ? question.choices : []);
  const [updatedAnswer, setUpdatedAnswer] = useState(null);
  const [codeEditors, setCodeEditors] = useState(
    question ? question.codeEditors : []
  );
  const [loading, setLoading] = useState(false);

  const sequense = stepsSequence(Steps, activeStep);
  const { skills } = useFindAllSkills(true);
  const { languages } = useFindAllLanguages(true);

  // Is mutated by QuestionCodeEditor and its children
  const [codingOptions, setCodingOptions] = useState({
    allowRunningTestCases: question?.allowRunningTestCases || false,
    allowedLanguages:
      question?.codingLanguages.map((obj) => obj.language) || [],
    informsTestCaseCorrectness: question?.informsTestCaseCorrectness || false,
    codeMode: question?.solutionCodeLanguage || "",
    solutionCode: question?.solutionCode || "Put your solution code here",
    testCases: [{ stdin: "", expected_output: "", weight: 1 }],
    outPutLines: question?.outputLinesPerTestCase || 1,
    customCodeWrapper: DefaultWrapper,
    useCodeWrapper: false,
  });

  const addAnswer = () => {
    let answers_temp = [...choices];
    answers_temp.push({
      text: null,
      is_correct_answer: false,
      fixed_position: null,
    });
    setChoices(answers_temp);
    changeMenuVisibility(null);
    window.tinymce.execCommand(
      "mceFocus",
      false,
      "editor_" + answers_temp.length - 1
    );
    setUpdatedAnswer(answers_temp.length - 1);
  };

  const setAnswer = (key, content) => {
    var answers_temp = [...choices];
    content = cleanUpParagraph(content);
    answers_temp[key].text = content;
    setChoices(answers_temp);
  };

  const changeMenuVisibility = (key) => {
    setUpdatedAnswer(key);
    for (const menu of document.getElementsByClassName(
      "tox-toolbar__primary"
    )) {
      menu.style.display = "none";
    }
    if (key !== null) {
      document.getElementsByClassName("tox-toolbar__primary")[
        key
      ].style.display = "flex";
    }
  };

  const setRightAnswer = (key) => {
    setUpdatedAnswer(key);
    var answers_temp = [...choices];
    if (type === QuestionTypes.MULTIPLE_CHOICE) {
      answers_temp[key].is_correct_answer =
        !answers_temp[key].is_correct_answer;
    }
    if (type === QuestionTypes.SINGLE_CHOICE) {
      var isCorrectAnswer = answers_temp[key].is_correct_answer;
      answers_temp.map((answer) => (answer.is_correct_answer = false));
      answers_temp[key].is_correct_answer = !isCorrectAnswer;
    }
    setChoices(answers_temp);
    changeMenuVisibility(key);
  };

  const fixPosition = (key) => {
    setUpdatedAnswer(key);
    var answers_temp = [...choices];
    answers_temp[key].fixed_position =
      answers_temp[key].fixed_position > 0 ? null : key + 1;
    setChoices(answers_temp);
    changeMenuVisibility(key);
  };

  const deleteAnswer = (key) => {
    var answers_temp = [...choices];
    answers_temp.splice(key, 1);
    setChoices(answers_temp);
    changeMenuVisibility(null);
  };

  var answerMethods = {
    add_answer: addAnswer,
    set_answer: setAnswer,
    change_visibility_menu: changeMenuVisibility,
    set_right_answer: setRightAnswer,
    fix_position: fixPosition,
    delete_answer: deleteAnswer,
  };

  const handleNextStep = async () => {
    setLoading(true);

    if (activeStep === PREVIEW) {
      let params = {
        type,
        skill,
        difficulty,
        time,
        statement,
        choices,
        resources: [],
        codeEditors,
      };
      if (type === QuestionTypes.CODING) {
        const customWrapper =
          codingOptions["useCodeWrapper"] === false
            ? null
            : codingOptions["customCodeWrapper"];
        params = {
          ...params,
          allowRunningTestCases: codingOptions["allowRunningTestCases"],
          allowedLanguages: codingOptions["allowedLanguages"],
          informsTestCaseCorrectness:
            codingOptions["informsTestCaseCorrectness"],
          codeMode: codingOptions["codeMode"],
          solutionCode: codingOptions["solutionCode"],
          testCases: codingOptions["testCases"],
          outputLinesPerTestCase: codingOptions["outPutLines"],
          customCodeWrapper: customWrapper,
        };
      }
      onSubmit(params);
      setLoading(false);
      return;
    }
    if (activeStep === QUESTION_CHOICES) {
      // Will handle this asynchronously

      if (type === QuestionTypes.CODING) {
        const tcStates = Array(
          questionDesign.testCasesEvaluationState.length
        ).fill(STATES.READY_TO_RUN);

        dispatch(updateTestCasesEvaluationState(tcStates));

        return;
      }
    }

    setLoading(false);
    setActiveStep(sequense.getNextStep());
  };

  useEffect(() => {
    if (activeStep !== QUESTION_CHOICES || type !== QuestionTypes.CODING) {
      return;
    }

    let tcStates = [...questionDesign.testCasesEvaluationState];
    const finishedAllTCs = !tcStates.some(
      (tc) => tc !== STATES.PASSED && tc !== STATES.FAILED
    );

    if (finishedAllTCs && tcStates.length > 0) {
      setLoading(false);

      tcStates = [...questionDesign.testCasesEvaluationState];

      const passedAllTCs = !tcStates.some((tc) => tc === STATES.FAILED);

      if (passedAllTCs && tcStates.length > 0) {
        setActiveStep(sequense.getNextStep());
      } else {
        toast.error("At least one test case didn't pass", { duration: 3000 });
      }
    }
  }, [questionDesign.testCasesEvaluationState]);

  const handlePreviousStep = () => {
    setActiveStep(sequense.getPreviousStep());
  };

  const extractEditorInfo = (htmlString) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(htmlString, "text/html");
    const preElements = htmlDoc.querySelectorAll("pre.code_editor");
    const result = [];

    preElements.forEach((pre) => {
      const editorInfo = {
        code: pre.textContent,
        language: pre.getAttribute("data-language"),
        allow_edit: pre.getAttribute("data-allow-edit") === "true",
        allow_copy: pre.getAttribute("data-allow-copy") === "true",
        allow_paste: pre.getAttribute("data-allow-paste") === "true",
      };

      result.push(editorInfo);
    });

    return result;
  };

  const handleStatementChange = (statement) => {
    const editors = extractEditorInfo(statement);
    statement = cleanUpParagraph(statement);
    setStatement(statement);
    setCodeEditors(editors);
  };

  const isMetadataValid = (type, skill, time) => {
    const isTypeValid = type !== 0;
    const isSkillValid = skill !== 0;
    const isTimeValid = time > 0;

    return isTypeValid && isSkillValid && isTimeValid;
  };

  const isStatementValid = (statement) =>
    statement !== null && statement !== "";

  const areChoicesValid = (answers) => {
    return (
      answers.length >= 2 &&
      answers.some((answer) => answer.is_correct_answer === true) &&
      answers.every((answer) => answer.text !== null && answer.text.length > 0)
    );
  };

  const areCodingOptionsValid = (codingOptions) => {
    return (
      codingOptions["allowedLanguages"].length >= 1 &&
      codingOptions["codeMode"] !== null &&
      codingOptions["solutionCode"] !== null &&
      codingOptions["testCases"].length >= 1
    );
  };

  const shoudBeNextButtonDisabled = () => {
    if (activeStep === QUESTION_METADATA) {
      return !isMetadataValid(type, skill, time);
    }

    if (activeStep === QUESTION_STATEMENT) {
      return !isStatementValid(statement);
    }

    if (activeStep === QUESTION_CHOICES && type === QuestionTypes.CODING) {
      return !areCodingOptionsValid(codingOptions);
    }

    if (activeStep === QUESTION_CHOICES) {
      return !areChoicesValid(choices);
    }

    return false;
  };

  const handleQuestionTypeChange = (newType) => {
    if (
      type === QuestionTypes.MULTIPLE_CHOICE &&
      newType === QuestionTypes.SINGLE_CHOICE
    ) {
      const newChoices = choices.map((choice) => ({
        ...choice,
        is_correct_answer: false,
      }));
      setChoices(newChoices);
    }
    setType(newType);
  };

  useEffect(() => {
    question?.testCases.length > 0 &&
      setCodingOptions((prevState) => ({
        ...prevState,
        testCases: question.testCases,
      }));
  }, [question?.testCases]);

  return (
    <MasterPage>
      <>
        <Title value={title} />
        <Subtitle value={subtitle} />
        <br />
        <MathJax>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CustomCard>
                <Box sx={{ width: "100%" }}>
                  <Stepper activeStep={Steps.indexOf(activeStep)}>
                    {Steps.map((label) => {
                      const stepProps = {};
                      const labelProps = {};
                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  <>
                    {activeStep === QUESTION_METADATA && (
                      <Grid>
                        <br />
                        <Metadata
                          questionType={type}
                          setQuestionType={handleQuestionTypeChange}
                          selectedSkill={skill}
                          setSelectedSkill={setSelectedSkill}
                          time={time}
                          setTime={setTime}
                          difficulty={difficulty}
                          setDifficulty={setDifficulty}
                          skills={skills}
                        />
                        <br />
                      </Grid>
                    )}
                    {activeStep === QUESTION_STATEMENT && (
                      <Grid>
                        <br />
                        <CustomEditor
                          languages={languages}
                          statement={statement}
                          onStatementChange={handleStatementChange}
                        />
                      </Grid>
                    )}
                    {activeStep === QUESTION_CHOICES &&
                      type === QuestionTypes.CODING && (
                        <QuestionCodeEditor
                          handleSetCodingOptions={setCodingOptions}
                          codingOptions={codingOptions}
                        />
                      )}
                    {activeStep === QUESTION_CHOICES &&
                      type !== QuestionTypes.CODING && (
                        <Grid>
                          <br />
                          <Choices
                            answers={choices}
                            setAnswers={setChoices}
                            updatedAnswer={updatedAnswer}
                            setUpdatedAnswer={setUpdatedAnswer}
                            answer_methods={answerMethods}
                            classes={classes}
                          />
                          <br />
                        </Grid>
                      )}
                    {activeStep === PREVIEW && (
                      <Grid>
                        <br />
                        <Preview
                          question={statement}
                          answers={choices}
                          isCoding={type === QuestionTypes.CODING}
                          classes={classes}
                          availableLanguages={codingOptions["allowedLanguages"]}
                        />
                        <br />
                      </Grid>
                    )}
                    {activeStep == FINISHED && (
                      <Grid>
                        <br />
                        <label>Question saved</label>
                        <br />
                      </Grid>
                    )}
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        variant="outlined"
                        disabled={activeStep === QUESTION_METADATA}
                        onClick={handlePreviousStep}
                        sx={{ mr: 1, textTransform: "none" }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <LoadingButton
                        variant="contained"
                        onClick={handleNextStep}
                        sx={{ textTransform: "none" }}
                        disabled={shoudBeNextButtonDisabled()}
                        loading={loading}
                      >
                        {activeStep === PREVIEW ? "Finish" : "Next"}
                      </LoadingButton>
                    </Box>
                  </>
                </Box>
              </CustomCard>
            </Grid>
          </Grid>
        </MathJax>
      </>
    </MasterPage>
  );
};

QuestionForm.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  question: PropTypes.object,
};

export default QuestionForm;
