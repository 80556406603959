import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: { language: "", question_id: 0, id: 1 },
  user_code: "",
  stdin: "",
  stdout: "",
  expected_output: "",
  passed_test_cases: "",
};

export const answerCodeSlice = createSlice({
  name: "answerCode",
  initialState,
  reducers: {
    cleanAnswerCode: () => initialState,
    updateLanguage: (state, action) => {
      state.language = action.payload;
    },
    updateCode: (state, action) => {
      state.user_code = action.payload;
    },
    updateSTDIN: (state, action) => {
      state.stdin = action.payload;
    },
    updateOUTPut: (state, action) => {
      state.stdout = action.payload;
    },
    updateExpectedOutput: (state, action) => {
      state.expected_output = action.payload;
    },
    updatePassedTestCases: (state, action) => {
      state.passed_test_cases = action.payload;
    },
    resetPassedTestCases: (state) => {
      state.passed_test_cases = "";
    },
  },
});

export const {
  updateLanguage,
  updateCode,
  updateSTDIN,
  updateOUTPut,
  cleanAnswerCode,
  updateExpectedOutput,
  updatePassedTestCases,
  resetPassedTestCases,
} = answerCodeSlice.actions;
export default answerCodeSlice.reducer;
