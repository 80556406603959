import { TextField } from "@mui/material";
import React, { useState } from "react";

const maxLength = 150;
const emptyError = "Can't be empty";

const DesignNameTextField = ({ name, onChange, forceShowErrors }) => {
  const [isDirty, setIsDirty] = useState(false);

  const validate = (name) => {
    if (name === "") {
      return emptyError;
    }

    if (name.length > maxLength) {
      return `Input too long: Maximum length is ${maxLength} characters`;
    }

    return null;
  };

  const handleNameChanged = (event) => {
    const { value } = event.target;
    const error = validate(value);

    setIsDirty(true);
    onChange({ value, hasErrors: error !== null });
  };

  const shouldShowError = () => {
    if ((forceShowErrors || isDirty) && name.hasErrors) {
      return true;
    }

    return false;
  };

  return (
    <TextField
      value={name.value}
      fullWidth
      sx={{ mb: 2 }}
      type="text"
      error={shouldShowError(name.value)}
      helperText={shouldShowError(name.value) ? validate(name.value) : ""}
      label={"Exam Design Name"}
      variant="outlined"
      onChange={handleNameChanged}
    />
  );
};

export default DesignNameTextField;
