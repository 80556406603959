import React, { useEffect, useRef, useState } from "react";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { CircularProgress, IconButton, lighten, Stack, TextField } from "@mui/material";
import PropTypes from "prop-types";
import { useRunDesignCode } from "../../../api/question/useRunDesignCode";
import { DefaultWrapper } from "./QuestionForm";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { STATES, updateTestCasesEvaluationStateIndex } from "state/slices/questionDesignSlice";

const defaultBackgroundColor = "transparent";

const TestCase = (
  {
    onRemove,
    onChange,
    index,
    codingOptions,
  }) => {

  const dispatch = useDispatch();
  const questionDesign = useSelector((state) => state.questionDesign);

  const theme = useTheme();

  const { handleRunDesignCode } = useRunDesignCode();

  const [backgroundColor, setBackgroundColor] = useState(defaultBackgroundColor);
  const [minHeight, setMinHeight] = useState();
  const stackRef = useRef(null);

  const testCase = codingOptions["testCases"][index];

  useEffect(() => {
    if (questionDesign.testCasesEvaluationState[index] === STATES.READY_TO_RUN) {
      dispatch(updateTestCasesEvaluationStateIndex({index, value: STATES.RUNNING}))
      setBackgroundColor(defaultBackgroundColor);

      const codeWrapper = codingOptions["customCodeWrapper"];

      handleRunDesignCode({
        language: codingOptions["codeMode"],
        custom_code_wrapper:
          codeWrapper === DefaultWrapper ? null : codeWrapper,
        solution_code: codingOptions["solutionCode"],
        test_case: testCase,
        output_lines_per_test_case: codingOptions["outPutLines"],
      })
        .then(({ passed_test_cases }) => {
          if (passed_test_cases) {
            setBackgroundColor(lighten(theme.palette.secondary.main, 0.3))
            dispatch(updateTestCasesEvaluationStateIndex({index, value: STATES.PASSED}))
          } else {
            setBackgroundColor(lighten(theme.palette.error.main, 0.3))
            dispatch(updateTestCasesEvaluationStateIndex({index, value: STATES.FAILED}));
          }
        })
        .catch(() => {
          setBackgroundColor(lighten(theme.palette.error.main, 0.3))
          dispatch(updateTestCasesEvaluationStateIndex({index, value: STATES.FAILED}));
        });
    }
    else {
      if (
        questionDesign.testCasesEvaluationState[index] === STATES.NULL ||
        questionDesign.testCasesEvaluationState[index] === STATES.RUNNING
      ) {
        setBackgroundColor(defaultBackgroundColor);
      }
    }
  }, [questionDesign.testCasesEvaluationState[index]]);

  useEffect(() => {
    if (questionDesign.testCasesEvaluationState[index] !== STATES.RUNNING && stackRef.current) {
      const calculatedHeight = stackRef.current.offsetHeight;
      setMinHeight(calculatedHeight);
    }
  }, [questionDesign.testCasesEvaluationState[index]]);

  return (
    <Stack
      ref={stackRef}
      spacing={2}
      border={1}
      borderRadius={1}
      marginTop={2}
      padding={2}
      style={{
        backgroundColor: backgroundColor,
        minHeight: minHeight || "auto",
        ...(questionDesign.testCasesEvaluationState[index] === STATES.RUNNING && {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }),
      }}
    >
      {
        questionDesign.testCasesEvaluationState[index] === STATES.RUNNING
          ? <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%"
          }}>
            <CircularProgress />
          </div>
          : (
            <>
              <IconButton
                edge="start"
                onClick={onRemove}
                style={{
                  padding: 0,
                  color: "red",
                }}
              >
                <DeleteOutlinedIcon />
              </IconButton>
              <TextField
                multiline
                label="STDIN"
                variant="standard"
                name="stdin"
                value={testCase?.stdin || ""}
                onChange={(e) => onChange(e)}
                inputProps={{ style: { fontFamily: "'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro'" } }}
              />
              <TextField
                multiline
                label="Expected Output"
                variant="standard"
                name="expected_output"
                value={testCase?.expected_output || ""}
                onChange={(e) => onChange(e)}
                inputProps={{ style: { fontFamily: "'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro'" } }}
              />
              <TextField
                label="Weight"
                variant="standard"
                name="weight"
                type="number"
                value={testCase?.weight || 1}
                onChange={(e) => onChange(e)}
                inputProps={{
                  max: 999999,
                  min: 1,
                  maxLength: 6,
                  style: { fontFamily: "'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro'" },
                }}
              />
            </>
          )
      }
    </Stack>
  );
};

TestCase.propTypes = {
  onRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  codingOptions: PropTypes.object.isRequired,
};

export default TestCase;
