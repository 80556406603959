import React from "react";
import PropTypes from "prop-types";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Grid, Alert, Link, Chip } from "@mui/material";
import { format, isValid } from "date-fns";

const formatDateTime = (dateTime) => {
  if (isValid(dateTime)) {
    return format(dateTime, "yyyy-MM-dd hh:mm a");
  }
  return null;
};

const formatTags = (tags) => {
  return tags.map((t, i) => <Chip key={i} label={t.name} variant="outlined" />);
};

const columns = [
  {
    field: "id",
    headerName: "ID",
    width: 80,
    renderCell: (params) => (
      <Link href={`/thebai/exam-instances/${params.row.id}/admin-report`}>
        <b>{params.row.id}</b>
      </Link>
    ),
  },
  {
    field: "examineeName",
    headerName: "Examinee Name",
    width: 250,
    renderCell: (params) => params.row.examineeName,
  },
  {
    field: "examineeEmail",
    headerName: "Examinee Email",
    width: 250,
    renderCell: (params) => params.row.examineeEmail,
  },
  {
    field: "examDesignName",
    headerName: "Exam Design",
    width: 250,
    renderCell: (params) => params.row.examDesignName,
  },
  {
    field: "score",
    headerName: "Score",
    width: 130,
    renderCell: (params) => params.row.score?.toFixed(2),
  },
  {
    field: "startTime",
    headerName: "Start Time",
    width: 180,
    renderCell: (params) => formatDateTime(params.row.startTime),
  },
  {
    field: "finishTime",
    headerName: "Finish Time",
    width: 180,
    renderCell: (params) => formatDateTime(params.row.finishTime),
  },
  {
    field: "tags",
    headerName: "Tags",
    width: 250,
    renderCell: (params) => <>{formatTags(params.row.tags)}</>,
  },
  {
    field: "availabeFrom",
    headerName: "Available From",
    width: 180,
    renderCell: (params) => formatDateTime(params.row.availableFrom),
  },
  {
    field: "availabeUntil",
    headerName: "Available Until",
    width: 180,
    renderCell: (params) => formatDateTime(params.row.availableUntil),
  },
  {
    field: "createdBy",
    headerName: "Created By",
    width: 250,
    renderCell: (params) => params.row.createdBy,
  },
  {
    field: "createdOn",
    headerName: "Created On",
    width: 250,
    renderCell: (params) => formatDateTime(params.row.createdOn),
  },
];

export const ExamInstancesTable = ({ examInstances, hasSearched }) => {
  return (
    <Grid>
      <br />
      <div
        style={{
          height: "80vh",
          width: "100%",
        }}
      >
        {!hasSearched ? null : examInstances.length === 0 ? (
          <Alert variant="filled" severity="warning">
            No questions found for this filter criteria.
          </Alert>
        ) : (
          <DataGrid
            columns={columns}
            rows={examInstances}
            getRowId={(row) => row.id}
            disableSelectionOnClick
            components={{
              Toolbar: GridToolbar,
            }}
          />
        )}
      </div>
    </Grid>
  );
};

ExamInstancesTable.propTypes = {
  examInstances: PropTypes.array.isRequired,
  hasSearched: PropTypes.bool.isRequired,
};
