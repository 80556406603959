import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import Swal from "sweetalert2";

import theme from "../../components/foundations/themes";
import CustomCard from "./../../components/modules/cards";
import FactoredLogo from "../../assets/images/factored_logo.png";

import { useUpdatePersonalInfo } from "../../api/user/useUpdatePersonalInfo";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      align="center"
      {...props}
      style={{ color: theme.palette.grey[400] }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://factored.ai/">
        Factored
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function UserInfo({ personalInfo }) {
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [acceptedGeneralPolicy, setAcceptedGeneralPolicy] = useState(false);
  const [acceptedApplicationPolicy, setAcceptedApplicationPolicy] = useState(false);
  const [hasUpdatedPersonalInfo, setHasUpdatedPersonalInfo] = useState(false);

  const { handleUpdatePersonalInfo } = useUpdatePersonalInfo();

  useEffect(() => {
    if (personalInfo) {
      setFirstName(personalInfo.firstName);
      setLastName(personalInfo.lastName);
      setAcceptedGeneralPolicy(personalInfo.acceptedPolicy);
      setAcceptedApplicationPolicy(personalInfo.acceptedPolicy);
    }
  }, [personalInfo]);

  const showError = (message) => {
    return Swal.fire({
      title: "Error",
      text: message,
      icon: "error",
      confirmButtonText: "Got it!",
      confirmButtonColor: theme.palette.primary.main,
    });
  };

  const showSuccess = (message) => {
    return Swal.fire({
      title: "Success",
      text: message,
      icon: "success",
      confirmButtonText: "Got it!",
      confirmButtonColor: theme.palette.primary.main,
    });
  };

  const isPersonalInfoValid = () => {
    if (!firstName || firstName === "") return false;
    if (!lastName || lastName === "") return false;
    if (!acceptedGeneralPolicy || !acceptedApplicationPolicy) return false;
    return true;
  };

  const changePersonalInfo = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const acceptedPolicy = acceptedGeneralPolicy && acceptedApplicationPolicy;
      await handleUpdatePersonalInfo({ firstName, lastName, acceptedPolicy});
      showSuccess(
        "Your personal information has been updated successfully."
      ).then(() => {
        setHasUpdatedPersonalInfo(true);
      });
    } catch (e) {
      showError(e.message);
    } finally {
      setLoading(false);
    }
  };

  if (hasUpdatedPersonalInfo) {
    return <Navigate to={"/thebai/exams-available"} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CustomCard>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={FactoredLogo} style={{ width: "50%" }} />
                <br />
                <Typography sx={{ fontWeight: "400", fontSize: 14 }}>
                  Please update your personal information
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <form onSubmit={changePersonalInfo}>
                    <br />
                    <Typography sx={{ fontWeight: "600", fontSize: 14 }}>
                      Given name(s)
                    </Typography>
                    <TextField
                      value={firstName}
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      name="first-name"
                      type="text"
                      onChange={(event) => setFirstName(event.target.value)}
                    />
                    <br />
                    <br />
                    <Typography sx={{ fontWeight: "600", fontSize: 14 }}>
                      Surname(s)
                    </Typography>
                    <TextField
                      value={lastName}
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      name="last-name"
                      type="text"
                      onChange={(event) => setLastName(event.target.value)}
                    />
                    <Box marginTop={1}>
                      <Stack direction="row" alignItems="center">
                        <Checkbox
                          onChange={() =>
                            setAcceptedGeneralPolicy(!acceptedGeneralPolicy)
                          }
                        />
                        <Typography fontSize={12}>
                          {"I have read "}
                          <Link target="_blank" rel="noopener noreferrer" href="/common/legal/general">{"Factored’s Personal Data Protection Policy."}</Link>
                        </Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center">
                        <Checkbox
                          onChange={() =>
                            setAcceptedApplicationPolicy(!acceptedApplicationPolicy)
                          }
                        />
                        <Typography fontSize={12}>
                          {"I authorize the processing of my data in accordance with "}
                          <Link target="_blank" rel="noopener noreferrer" href="/common/legal/thebai">{"Factored S.A.S Data Processing Authorization."}</Link>
                        </Typography>
                      </Stack>
                    </Box>
                    {loading ? (
                      <LoadingButton
                        loading
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, textTransform: "none" }}
                      >
                        Submit
                      </LoadingButton>
                    ) : (
                      <Button
                        disabled={!isPersonalInfoValid()}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, textTransform: "none" }}
                        type="submit"
                      >
                        Confirm
                      </Button>
                    )}
                  </form>
                </Box>
              </Box>
            </CustomCard>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </Box>
    </ThemeProvider>
  );
}
