import React, { useState } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import { TextField, Typography, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useAppContext } from "../../../libs/contextLib";
import { isEmail } from "../../../utils/validateEmail";

const ConfirmCodeForm = ({ email: defaultEmail, onChangePasswordWithCode }) => {
  const [email, setEmail] = useState(defaultEmail ?? "");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { onAuthStateChange } = useAppContext();

  const shouldDisableSubmit = () => {
    return (
      code &&
      code.length > 0 &&
      newPassword &&
      newPassword.length > 0 &&
      isEmail(email)
    );
  };

  const changePasswordWithCode = (e) => {
    e.preventDefault();
    setIsLoading(true);
    onChangePasswordWithCode(email, code, newPassword);
    setIsLoading(false);
    onAuthStateChange(false);
  };

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Typography sx={{ fontWeight: "400", fontSize: 16 }}>
          Use the provided code to set your password
        </Typography>
        <form onSubmit={changePasswordWithCode}>
          <br />
          <Typography sx={{ fontWeight: "600", fontSize: 14 }}>
            Email
          </Typography>
          <TextField
            value={email}
            variant="outlined"
            size="small"
            fullWidth
            required
            name="email"
            type="email"
            onChange={(event) => setEmail(event.target.value)}
          />
          <br />
          <br />
          <Typography sx={{ fontWeight: "600", fontSize: 14 }}>Code</Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            required
            name="code"
            type="text"
            onChange={(event) => setCode(event.target.value)}
          />
          <br />
          <br />
          <Typography sx={{ fontWeight: "600", fontSize: 14 }}>
            New Password
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            required
            name="new-password"
            type="password"
            onChange={(event) => setNewPassword(event.target.value)}
          />
          <br />
          <br />

          {isLoading ? (
            <LoadingButton
              loading
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, textTransform: "none" }}
            >
              Submit
            </LoadingButton>
          ) : (
            <Button
              disabled={!shouldDisableSubmit()}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, textTransform: "none" }}
              type="submit"
            >
              Submit
            </Button>
          )}
        </form>
      </Box>
    </>
  );
};

ConfirmCodeForm.propTypes = {
  email: PropTypes.string.isRequired,
  onChangePasswordWithCode: PropTypes.func.isRequired,
};

export default ConfirmCodeForm;
