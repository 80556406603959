import { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Badge,
  Button,
  Grid,
  Icon,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { Editor } from "@tinymce/tinymce-react";
import DeleteIcon from "@mui/icons-material/Delete";
import { MathJax } from "better-react-mathjax";

export default function Choices(props) {
  const { answers, updatedAnswer, answer_methods, classes } = props;

  useEffect(() => {
    for (const menu of document.getElementsByClassName("tox-statusbar")) {
      menu.style.border = 0;
    }
    for (const menu of document.getElementsByClassName("tox-tinymce")) {
      menu.style.border = 0;
    }
  });

  useEffect(() => {
    answer_methods.change_visibility_menu(null);
  }, []);

  return (
    <Grid container spacing={3}>
      {answers.map((answer, key) => (
        <Grid item xs={12}>
          <Badge
            badgeContent={"✓"}
            color="primary"
            invisible={!answer.is_correct_answer}
            style={{ width: "100%" }}
          >
            <div
              className={
                key === updatedAnswer
                  ? classes.questionFocus
                  : classes.questionUnfocus
              }
            >
              {key === updatedAnswer && (
                <div style={{ position: "relative", marginBottom: "-40px" }}>
                  <Stack direction="row" justifyContent="flex-end">
                    <Tooltip
                      title={
                        answer.fixed_position
                          ? "Unlock position"
                          : "Lock position"
                      }
                      style={{ zIndex: 10 }}
                    >
                      <IconButton
                        onClick={() => answer_methods.fix_position(key)}
                      >
                        {answer.fixed_position ? (
                          <Icon sx={{ color: "#3498DB" }}>lock_open</Icon>
                        ) : (
                          <Icon sx={{ color: "#3498DB" }}>push_pin</Icon>
                        )}
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={
                        answer.is_correct_answer
                          ? "Set as incorrect"
                          : "Set as correct"
                      }
                      style={{ zIndex: 10 }}
                    >
                      <IconButton
                        onClick={() => answer_methods.set_right_answer(key)}
                        disabled={answer.text === ""}
                      >
                        {answer.is_correct_answer ? (
                          <Icon sx={{ color: "#E74C3C" }}>dangerous</Icon>
                        ) : (
                          <Icon
                            sx={{
                              color: answer.text === "" ? "#ECF0F1" : "#1ABC9C",
                            }}
                          >
                            verified
                          </Icon>
                        )}
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete answer" style={{ zIndex: 10 }}>
                      <IconButton
                        onClick={() => answer_methods.delete_answer(key)}
                      >
                        <DeleteIcon sx={{ color: "#E74C3C" }} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </div>
              )}
              <div style={{ padding: "5px" }}>
                <MathJax>
                  <Editor
                    //Use this API Key just for development, for deployment please register in https://www.tiny.cloud/auth/signup/
                    apiKey="zb5p1n1wg7av2w0rdu6740e3gmrmofytghwmt7stx072bxfn"
                    value={answers[key].text}
                    onEditorChange={(content) =>
                      answer_methods.set_answer(key, content)
                    }
                    onClick={() => answer_methods.change_visibility_menu(key)}
                    style={{ borderRadius: "10px" }}
                    id={"choices_editor" + key}
                    init={{
                      branding: false,
                      height: 100,
                      max_height: 500,
                      min_height: 100,
                      placeholder: "Write your answers here",
                      menubar: false,
                      inline: false,
                      skin: "borderless",
                      elementpath: false,
                      plugins: [
                        "advlist autolink lists link image imagetools charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help codesample",
                      ],
                      toolbar:
                        "undo redo | " +
                        "bold italic | " +
                        "outdent indent | bullist numlist | " +
                        "table image codesample",
                      font_formats: "Roboto=roboto",
                      content_style:
                        "@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); body { font-family: 'Roboto', sans-serif; color: #092C4C }",
                      htmlAllowedTags: [".*"],
                      htmlAllowedAttrs: [".*"],
                      draggable_modal: true,
                      image_title: true,
                      automatic_uploads: true,
                      image_dimensions: false,
                      file_picker_types: "image",
                      file_picker_callback: function (cb, value, meta) {
                        var input = document.createElement("input");
                        input.setAttribute("type", "file");
                        input.setAttribute("accept", "image/*");
                        input.onchange = function () {
                          var file = this.files[0];
                          var reader = new FileReader();
                          reader.onload = function () {
                            var id = "blobid" + new Date().getTime();
                            var blobCache =
                              window.tinymce.activeEditor.editorUpload
                                .blobCache;
                            var base64 = reader.result.split(",")[1];
                            var blobInfo = blobCache.create(id, file, base64);
                            blobCache.add(blobInfo);
                            cb(blobInfo.blobUri(), { title: file.name });
                          };
                          reader.readAsDataURL(file);
                        };
                        input.click();
                      },
                    }}
                  />
                </MathJax>
              </div>
            </div>
          </Badge>
        </Grid>
      ))}
      <Grid item xs={12}>
        <div className={classes.questionButton}>
          <Button
            fullWidth
            sx={{ textTransform: "none" }}
            onClick={() => answer_methods.add_answer()}
            disabled={answers.some((answer) => answer.text === "")}
          >
            Add choice
          </Button>
        </div>
      </Grid>
      <Grid item xs={12}>
        {answers.length <= 1 && (
          <>
            <Alert severity="error">
              You must provide at least two choices!
            </Alert>
            <br />
          </>
        )}
        {!answers.some((answer) => answer.is_correct_answer) && (
          <>
            <Alert severity="error">
              You must mark at least one choice as correct answer!
            </Alert>
            <br />
          </>
        )}
      </Grid>
    </Grid>
  );
}

Choices.propTypes = {
  answers: PropTypes.array.isRequired,
  updatedAnswer: PropTypes.number.isRequired,
  answer_methods: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};
