import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress, Grid, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Title } from "../../components/elements/texts";
import CustomCard from "../../components/modules/cards";
import MasterPage from "../../components/modules/masterpage";
import { backend_client } from "../../helpers/api";
import styles from "../styles/ExamsAvailable";
import { useTheme } from "@mui/material/styles";

import BasicDialog from "../common/BasicDialog";
import ExamInformation from "./components/ExamInformation";
import ProgressBar from "./components/ColoredProgressBar";

const ExamsAvailable = () => {
  const useStyles = makeStyles(styles);
  const theme = useTheme();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [examsAvailables, setExamsAvailables] = useState({});

  const bringAvailableExams = () => {
    setLoading(true);
    const endpoint = "/exam-instances/available";
    backend_client
      .get(endpoint)
      .then((response) => {
        const exams = response.data.exams
          .filter((exam) => {
            return exam.has_enough_questions;
          })
          .sort((examA, examB) => {
            const currentProgressA =
              examA.number_of_asked_questions / examA.number_of_questions;
            const currentProgressB =
              examB.number_of_asked_questions / examB.number_of_questions;

            const examADate = new Date(examA.available_until);

            const examBDate = new Date(examB.available_until);

            if (currentProgressA < currentProgressB) {
              return 1;
            } else if (currentProgressA > currentProgressB) {
              return -1;
            } else {
              if (examADate < examBDate) {
                return -1;
              } else if (examADate > examBDate) {
                return 1;
              } else {
                return 0;
              }
            }
          });
        setExamsAvailables({ exams: exams });
      })
      .catch((error) => {
        Swal.fire({
          title: "An error occurred 😱",
          text: "Relax, we are working to fix it",
          icon: "error",
          confirmButtonText: "Got it!",
          confirmButtonColor: theme.palette.primary.main,
        });
        console.error(error);
      })
      .then(function () {
        setLoading(false);
      });
  };

  useEffect(() => {
    bringAvailableExams();
  }, []);

  const renderExam = (exam) => {
    return (
      <div className={classes.examCard}>
        <Grid container>
          <Grid container item xs={12} sm={6}>
            <ExamInformation
              examName={exam.name}
              examAvailableUntil={exam.available_until}
              examSkills={exam.skills}
              isExamInProgress={exam.started}
              styles={styles}
              examMaxDuration={exam.max_duration}
            />
          </Grid>
          {exam.number_of_asked_questions < exam.number_of_questions &&
          exam.started ? (
            <Grid item sm={6}>
              <ProgressBar
                currentValue={exam.number_of_asked_questions}
                totalValue={exam.number_of_questions}
              />
            </Grid>
          ) : (
            <div></div>
          )}
          <Grid container item xs={12} style={{ paddingTop: "2px" }}>
            <Box
              justifyContent={"end"}
              alignItems={"end"}
              width={"100%"}
              display={"flex"}
            >
              <BasicDialog
                buttonOpenText={exam.started ? "Continue" : "Start"}
                modalTitleText={"Attention!"}
                modalDescriptionText={`You're about to start the ${exam.name}.
                You will be asked one question at a time and you will have a certain duration of time
                (displayed in a timer) to answer each question.`}
                continueButtonRedirection={`/thebai/exam-instances/${exam.exam_instance_id}/answers`}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <MasterPage>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {loading ? (
                <CircularProgress />
              ) : (
                <CustomCard>
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Title
                        value="Available Exams"
                        color={theme.palette.primary.main}
                      />
                    </Stack>
                  </Box>
                  {examsAvailables.exams ? (
                    <Box sx={{ pt: 3, pb: 1 }}>
                      <Grid
                        item
                        container
                        xs={12}
                        ms={6}
                        justifyContent="center"
                        spacing={2}
                      >
                        {examsAvailables.exams.map((exam, key) => (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            key={key}
                            alignItems="stretch"
                          >
                            {renderExam(exam)}
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  ) : (
                    <div>{"You don't have any exams"}</div>
                  )}
                </CustomCard>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </MasterPage>
  );
};

export default ExamsAvailable;
