import React from "react";
import PropTypes from "prop-types";
import { TextField, Typography } from "@mui/material";
import { isEmail } from "../../../utils/validateEmail";

const EmailsContainer = ({
  placeholder,
  helperText,
  title,
  onEmailsChanged,
}) => {
  const validateEmails = (text) => {
    const newEmails = [];
    const lines = text.split("\n");
    lines.forEach((line) => {
      const trimedLine = line.trim();
      if (trimedLine && isEmail(trimedLine)) {
        newEmails.push(trimedLine);
      }
    });
    const uniqueEmails = [...new Set(newEmails)];
    return uniqueEmails;
  };

  const handleTextChange = (event) => {
    const uniqueEmails = validateEmails(event.target.value);
    onEmailsChanged(uniqueEmails);
  };

  return (
    <>
      <Typography sx={{ fontWeight: "600", fontSize: 14 }}>{title}</Typography>
      <TextField
        multiline
        fullWidth
        rows={3}
        sx={{ maxHeight: 120, overflow: "auto" }}
        placeholder={placeholder}
        onChange={(e) => handleTextChange(e)}
        helperText={helperText}
      />
    </>
  );
};

EmailsContainer.propTypes = {
  placeholder: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onEmailsChanged: PropTypes.func.isRequired,
};

export default EmailsContainer;
