import * as React from "react";
import {
  Alert,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { DifficultySelector } from "./DifficultySelector";

const convert_difficulty_to_color = (level) => {
  if (level === 1) {
    return "#21646d";
  } else if (level === 2) {
    return "#F1C40F";
  } else if (level === 3) {
    return "#E67E22";
  } else if (level === 4) {
    return "#E74C3C";
  } else {
    return "#7F8C8D";
  }
};

export default function Metadata(props) {
  const {
    questionType,
    setQuestionType,
    selectedSkill,
    setSelectedSkill,
    time,
    setTime,
    difficulty,
    setDifficulty,
    skills,
  } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Typography sx={{ fontWeight: "600", fontSize: 14 }}>
          Question Type
        </Typography>
        <FormControl size="small" fullWidth>
          <Select
            value={questionType}
            onChange={(event) => setQuestionType(event.target.value)}
          >
            <MenuItem value={0}>Select question type</MenuItem>
            <MenuItem value={"single_choice"}>Single Choice</MenuItem>
            <MenuItem value={"multiple_choice"}>Multiple Choice</MenuItem>
            <MenuItem value={"coding"}>Coding</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>

        <Typography sx={{ fontWeight: "600", fontSize: 14 }}>Skill</Typography>

        <FormControl size="small" fullWidth>
          <Select
            value={selectedSkill}
            onChange={(event) => setSelectedSkill(event.target.value)}
          >
            <MenuItem value={0}>Select skill</MenuItem>
            {skills.map((item, key) => (
              <MenuItem key={key} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography sx={{ fontWeight: "600", fontSize: 14 }}>
          Time (in seconds)
        </Typography>
        <TextField
          variant="outlined"
          size="small"
          type="number"
          min={1}
          fullWidth
          value={time}
          onChange={(event) => setTime(event.target.value)}
          inputProps={{ max: 999999, min: 1, maxLength: 6 }}
          required
        />
        <br />
        <br />
        {time <= 0 && (
          <Alert severity="error">Time must be greather than zero!</Alert>
        )}
      </Grid>

      <DifficultySelector
        difficulty={difficulty}
        onDifficultyChange={setDifficulty}
      />
    </Grid>
  );
}
