import axiosInstanceBuilder from "../config/axios/axiosInstanceBuilder";
import { mapToEmptyArrayIfNull } from "../config/axios/utils/mapToEmpyArrayIfNull";
import { useFetch } from "../config/axios/useFetch";

const baseUrl = process.env.REACT_APP_API_DEV_URL;
const endpoint = "/tags/list";

const createAxiosPromise = () => {
  return axiosInstanceBuilder(baseUrl).get(endpoint);
};

export const useFindAllTags = (callAfterRender = false) => {
  const { data, isPending, error, fetchApi } = useFetch(
    createAxiosPromise,
    callAfterRender
  );

  const findAllTags = async () => {
    const response = await fetchApi();
    const { data, ...rest } = response;
    return { tags: mapToEmptyArrayIfNull(data), ...rest };
  };

  return {
    tags: mapToEmptyArrayIfNull(data),
    isLoading: isPending,
    error,
    findAllTags,
  };
};
