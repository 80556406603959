import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetPolicies } from "../../api/common/useGetPolicies";
import theme from "../../components/foundations/themes";
import CustomCard from "../../components/modules/cards";
import { findAndReplaceLinks } from "../../utils/findAndReplaceLinks";


function Copyright(props) {
  return (
    <Typography
      variant="body2"
      align="center"
      {...props}
      style={{ color: theme.palette.grey[400] }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://factored.ai/">
        Factored
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function ViewPolicy() {
  const { doc_type } = useParams();
  const { getPolicy } = useGetPolicies(doc_type);
  const [policyEnglish, setPolicyEnglish] = useState("");
  const [policySpanish, setPolicySpanish] = useState("");

  useEffect(() => {
    const getPolicies = async () => {
      const {policyEnglish, policySpanish} = await getPolicy();

      setPolicyEnglish(policyEnglish);
      setPolicySpanish(policySpanish);
    };
    getPolicies();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
          sx={{
            display: "flex",
            minHeight: "100vh",
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <Grid container justifyContent={"space-around"} xs={12}>
            <Grid xs={5} sx={{margin: 3}}>
              <CustomCard>
                <div dangerouslySetInnerHTML={{ __html: findAndReplaceLinks(policyEnglish) }} />
              </CustomCard>
            </Grid>
            <Grid xs={5} sx={{margin: 3}}>
              <CustomCard>
                <div dangerouslySetInnerHTML={{ __html: findAndReplaceLinks(policySpanish) }} />
              </CustomCard>
            </Grid>
            <Grid xs={12}>
              <Copyright sx={{ mt: 8, mb: 4 }} />
            </Grid>
          </Grid>
        </Box>
    </ThemeProvider>
  );
}
