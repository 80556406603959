import React from "react";
import { Route, Routes } from "react-router-dom";

import Login from "./pages/common/login";
import PasswordChange from "./pages/common/PasswordChange";
import Home from "./pages/common/home";
import NotFound from "./pages/common/404";

import PrivateRoute from "./utils/privateRoute";

import ViewPolicy from "pages/common/ViewPolicy";
import ExamsAvailable from "pages/ExamsAvailable";
import QuestionList from "pages/QuestionList";
import QuestionDetail from "pages/QuestionDetail";
import QuestionSearch from "pages/QuestionSearch";
import ExamQuestion from "pages/ExamQuestion";
import AdminReport from "pages/AdminReport";
import ExamInstancesCreator from "pages/ExamInstancesCreator";
import ExamInstancesSearcher from "pages/ExamInstancesSearcher";
import ExamDesignCreator from "pages/ExamDesignCreator";
import QuestionDesigner from "pages/QuestionDesigner";
import QuestionEditor from "pages/QuestionEditor";
import RecoverPassword from "pages/common/ForgotPassword/PasswordRecover";
import UserInfo from "pages/common/UserInfo";

export const Roles = {
  BASIC: "basic",
  QUESTION_CREATOR: "question_creator",
  QUESTION_VALIDATOR: "question_validator",
  EXAM_CREATOR: "exam_creator",
  EXAM_DESIGNER: "exam_designer",
  ADMIN: "admin",
  TECHNICAL_ADMIN: "technical_admin",
};

export const Paths = {
  EXAMS_AVAILABLE: "/thebai/exams-available",
  QUESTION_DESIGNER: "/thebai/question-designer",
  QUESTION_LIST: "/thebai/question-list",
  QUESTION_SEARCH: "/thebai/question-search",
  EXAM_DESIGNER: "/thebai/exam-designer",
  EXAM_INSTANCE_CREATOR: "/thebai/exam-instances-creator",
  HOME: "/",
  QUESTION_DETAILS: "/thebai/question-details/:question_id",
  QUESTION_EDITOR: "/thebai/question-editor/:question_id",
  TAKE_EXAM: "/thebai/exam-instances/:exam_instance_id/answers",
  ADMIN_REPORT: "/thebai/exam-instances/:exam_instance_id/admin-report",
  EXAM_INSTANCES_SEARCHER: "/thebai/exam-instances/search",
  LOGIN: "/login",
  CHANGE_PASSWORD: "/user/password/change",
  FORGOT_PASSWORD: "/user/password/recover",
  CHANGE_USER_INFO: "/user/info/change",
  DATA_POLICY: "/common/legal/:doc_type",
  NOT_FOUND: "*",
};

export const routesConfiguration = [
  {
    path: Paths.LOGIN,
    element: Login,
    isPrivate: false,
  },
  {
    path: Paths.CHANGE_PASSWORD,
    element: PasswordChange,
    isPrivate: false,
  },
  {
    path: Paths.FORGOT_PASSWORD,
    element: RecoverPassword,
    isPrivate: false,
  },
  {
    path: Paths.DATA_POLICY,
    element: ViewPolicy,
    isPrivate: false,
  },
  {
    path: Paths.CHANGE_USER_INFO,
    element: UserInfo,
    allowedRoles: [
      Roles.BASIC,
      Roles.EXAM_CREATOR,
      Roles.EXAM_DESIGNER,
      Roles.QUESTION_CREATOR,
      Roles.QUESTION_VALIDATOR,
      Roles.TECHNICAL_ADMIN,
    ],
  },
  {
    path: Paths.EXAMS_AVAILABLE,
    element: ExamsAvailable,
    isPrivate: true,
    allowedRoles: [
      Roles.BASIC,
      Roles.EXAM_CREATOR,
      Roles.EXAM_DESIGNER,
      Roles.QUESTION_CREATOR,
      Roles.QUESTION_VALIDATOR,
      Roles.TECHNICAL_ADMIN,
    ],
  },
  {
    path: Paths.QUESTION_DESIGNER,
    element: QuestionDesigner,
    isPrivate: true,
    allowedRoles: [
      Roles.EXAM_CREATOR,
      Roles.EXAM_DESIGNER,
      Roles.QUESTION_CREATOR,
      Roles.QUESTION_VALIDATOR,
      Roles.TECHNICAL_ADMIN,
    ],
  },
  {
    path: Paths.QUESTION_LIST,
    element: QuestionList,
    isPrivate: true,
    allowedRoles: [
      Roles.ADMIN,
      Roles.EXAM_CREATOR,
      Roles.EXAM_DESIGNER,
      Roles.QUESTION_CREATOR,
      Roles.QUESTION_VALIDATOR,
      Roles.TECHNICAL_ADMIN,
    ],
  },
  {
    path: Paths.QUESTION_SEARCH,
    element: QuestionSearch,
    isPrivate: true,
    allowedRoles: [
      Roles.ADMIN,
      Roles.EXAM_CREATOR,
      Roles.EXAM_DESIGNER,
      Roles.QUESTION_CREATOR,
      Roles.QUESTION_VALIDATOR,
      Roles.TECHNICAL_ADMIN,
    ],
  },
  {
    path: Paths.EXAM_DESIGNER,
    element: ExamDesignCreator,
    isPrivate: true,
    allowedRoles: [
      Roles.ADMIN,
      Roles.EXAM_CREATOR,
      Roles.EXAM_DESIGNER,
      Roles.TECHNICAL_ADMIN,
    ],
  },
  {
    path: Paths.EXAM_INSTANCE_CREATOR,
    element: ExamInstancesCreator,
    isPrivate: true,
    allowedRoles: [
      Roles.ADMIN,
      Roles.EXAM_CREATOR,
      Roles.EXAM_DESIGNER,
      Roles.TECHNICAL_ADMIN,
    ],
  },
  {
    path: Paths.HOME,
    element: Home,
    isPrivate: true,
    allowedRoles: [
      Roles.BASIC,
      Roles.ADMIN,
      Roles.EXAM_CREATOR,
      Roles.EXAM_DESIGNER,
      Roles.QUESTION_CREATOR,
      Roles.QUESTION_VALIDATOR,
      Roles.TECHNICAL_ADMIN,
    ],
  },
  {
    path: Paths.QUESTION_DETAILS,
    element: QuestionDetail,
    isPrivate: true,
    allowedRoles: [
      Roles.ADMIN,
      Roles.EXAM_CREATOR,
      Roles.EXAM_DESIGNER,
      Roles.QUESTION_CREATOR,
      Roles.QUESTION_VALIDATOR,
      Roles.TECHNICAL_ADMIN,
    ],
  },
  {
    path: Paths.QUESTION_EDITOR,
    element: QuestionEditor,
    isPrivate: true,
    allowedRoles: [
      Roles.EXAM_CREATOR,
      Roles.EXAM_DESIGNER,
      Roles.QUESTION_CREATOR,
      Roles.QUESTION_VALIDATOR,
      Roles.TECHNICAL_ADMIN,
    ],
  },
  {
    path: Paths.TAKE_EXAM,
    element: ExamQuestion,
    isPrivate: true,
    allowedRoles: [
      Roles.BASIC,
      Roles.EXAM_CREATOR,
      Roles.EXAM_DESIGNER,
      Roles.QUESTION_CREATOR,
      Roles.QUESTION_VALIDATOR,
      Roles.TECHNICAL_ADMIN,
    ],
  },
  {
    path: Paths.ADMIN_REPORT,
    element: AdminReport,
    isPrivate: true,
    allowedRoles: [
      Roles.ADMIN,
      Roles.EXAM_CREATOR,
      Roles.EXAM_DESIGNER,
      Roles.TECHNICAL_ADMIN,
    ],
  },

  {
    path: Paths.EXAM_INSTANCES_SEARCHER,
    element: ExamInstancesSearcher,
    isPrivate: true,
    allowedRoles: [
      Roles.ADMIN,
      Roles.EXAM_CREATOR,
      Roles.EXAM_DESIGNER,
      Roles.QUESTION_CREATOR,
      Roles.QUESTION_VALIDATOR,
      Roles.TECHNICAL_ADMIN,
    ],
  },
  {
    path: Paths.NOT_FOUND,
    element: NotFound,
    isPrivate: false,
  },
];

export default function RouteList() {
  return (
    <Routes>
      {routesConfiguration.map(
        ({ path, element: Element, isPrivate, allowedRoles }) => (
          <Route
            key={path}
            path={path}
            element={
              isPrivate ? (
                <PrivateRoute key={path} allowedRoles={allowedRoles}>
                  <Element key={path} />
                </PrivateRoute>
              ) : (
                <Element key={path} />
              )
            }
          />
        )
      )}
    </Routes>
  );
}
