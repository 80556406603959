import axiosInstanceBuilder from "../config/axios/axiosInstanceBuilder";
import { useFetch } from "../config/axios/useFetch";

const baseUrl = process.env.REACT_APP_API_DEV_URL;
const endpoint = "/exam-instances";

const createAxiosPromise = (data) => {
  return axiosInstanceBuilder(baseUrl).post(endpoint, data);
};

const convertToUTCStringRemoveZ = (date) => {
  return date.toISOString().replace("Z", "");
};

const buildExamInstancesCreationRequest = ({
  examDesign,
  initialDate,
  finalDate,
  emails,
  selectedTags,
}) => {
  const initialDateAsString = convertToUTCStringRemoveZ(initialDate);
  const finalDateAsString = convertToUTCStringRemoveZ(finalDate);
  const tag_ids = selectedTags.map((t) => t.id);
  return {
    examinee_emails: emails,
    exam_design_id: examDesign,
    available_from: initialDateAsString,
    available_until: finalDateAsString,
    tag_ids: tag_ids,
    force_creation: false,
  };
};

export const useCreateExamInstances = () => {
  const { fetchApi } = useFetch(createAxiosPromise, false);

  const handleCreateExamInstances = async (instructions) => {
    const examInstancesCreationRequest =
      buildExamInstancesCreationRequest(instructions);

    const response = await fetchApi(examInstancesCreationRequest);
    const { data, ...rest } = response;
    return { result: data, ...rest };
  };

  return {
    handleCreateExamInstances,
  };
};

export default useCreateExamInstances;
