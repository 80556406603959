import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

const ColoredLinearProgress = styled(LinearProgress)(
  (barcolor, barbackgroundcolor) => ({
    width: "100%",
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: barbackgroundcolor,
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: barcolor,
    },
  })
);

const ProgressBar = (props) => {
  const { currentValue, totalValue } = props;
  const progressPercentage = Math.round((currentValue / totalValue) * 100);
  const theme = useTheme();
  return (
    <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <ColoredLinearProgress
          variant="determinate"
          value={progressPercentage}
          barcolor={theme.palette.primary.main}
          barbackgroundcolor={theme.palette.primary.background}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <p>{`${progressPercentage}%`}</p>
      </Box>
    </Box>
  );
};

ProgressBar.propTypes = {
  currentValue: PropTypes.number,
  totalValue: PropTypes.number,
};

export default ProgressBar;
