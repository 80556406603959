import React, { useState } from "react";
import PropTypes from "prop-types";

import { Autocomplete, TextField } from "@mui/material";

const noSelectedOptionError = "Must select one option";

const CustomizedAutocomplete = ({
  selectedOption,
  options,
  disabledOptions,
  label,
  placeholder,
  isLoading,
  onChange,
  forceShowErrors,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  const validate = (selectedValue) => {
    if (!selectedValue || selectedValue === "") {
      return noSelectedOptionError;
    }

    return null;
  };

  const shouldShowError = ({ hasErrors }) => {
    if ((isDirty || forceShowErrors) && hasErrors) return true;

    return false;
  };

  const handleChange = (newValue) => {
    let newId = null;
    if (newValue) {
      newId = newValue.id;
    }
    const error = validate(newId);

    setIsDirty(true);
    onChange({ value: newId, hasErrors: error !== null });
  };

  const getOptionFromValue = (selectedOption) => {
    if (!selectedOption.value) return null;
    return options.find((o) => o.id === selectedOption.value);
  };

  return (
    <Autocomplete
      value={getOptionFromValue(selectedOption)}
      options={options}
      getOptionDisabled={(option) =>
        disabledOptions.some((o) => o.value === option.id)
      }
      getOptionLabel={(option) => option.name}
      onChange={(event, newValue) => handleChange(newValue)}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          error={shouldShowError(selectedOption)}
          helperText={
            shouldShowError(selectedOption)
              ? validate(selectedOption.value)
              : ""
          }
          style={{ width: 250 }}
          label={label}
          placeholder={placeholder}
        />
      )}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      noOptionsText="Options not found"
    />
  );
};

CustomizedAutocomplete.propTypes = {
  selectedOption: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  disabledOptions: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  forceShowErrors: PropTypes.bool.isRequired,
};

export default CustomizedAutocomplete;
