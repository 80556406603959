import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MathJax } from "better-react-mathjax";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Icon,
  Link,
  Stack,
  Tooltip,
} from "@mui/material";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

import { Subtitle, Title } from "components/elements/texts";
import theme from "components/foundations/themes";
import CustomCard from "components/modules/cards";
import MasterPage from "components/modules/masterpage";
import { Statement } from "pages/ExamQuestion/components";
import { backend_client } from "helpers/api";
import { updateQuestion } from "state/slices/questionSlice";

const convert_difficulty_to_color = (level) => {
  if (level === 1) {
    return "#1ABC9C";
  } else if (level === 2) {
    return "#F1C40F";
  } else if (level === 3) {
    return "#E67E22";
  } else if (level === 4) {
    return "#E74C3C";
  } else {
    return "#7F8C8D";
  }
};

const convert_difficulty_to_string = (level) => {
  if (level === 1) {
    return "Easy";
  } else if (level === 2) {
    return "Medium";
  } else if (level === 3) {
    return "Hard";
  } else if (level === 4) {
    return "Very Hard";
  } else {
    return "Indeterminated";
  }
};

export default function QuestionDetail() {
  var { question_id } = useParams();
  const dispatch = useDispatch();
  const question = useSelector((state) => state.question);
  const [loading, setLoading] = useState(true);
  const [skills, setSkills] = useState(null);

  const get_question = () => {
    setLoading(true);
    var url = "/questions/" + question_id;
    backend_client
      .get(url)
      .then((response) => {
        dispatch(updateQuestion({ question: response.data }));
        get_skills();
      })
      .catch((error) => {
        Swal.fire({
          title: "An error occurred 😱",
          text: "Relax, we are working to fix it",
          icon: "error",
          confirmButtonText: "Got it!",
          confirmButtonColor: theme.palette.primary.main,
        });
        console.error(error);
      })
      .then(function () {});
  };

  const get_skills = () => {
    var url = "/common/skills?return_sorted=true";
    backend_client
      .get(url)
      .then((response) => {
        setSkills(response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "An error occurred 😱",
          text: "Relax, we are working to fix it",
          icon: "error",
          confirmButtonText: "Got it!",
          confirmButtonColor: theme.palette.primary.main,
        });
        console.error(error);
      })
      .then(function () {
        setLoading(false);
      });
  };

  const delete_question = () => {
    var url = "/questions/" + question_id;
    backend_client
      .delete(url)
      .catch((error) => {
        Swal.fire({
          title: "An error occurred 😱",
          text: "Relax, we are working to fix it",
          icon: "error",
          confirmButtonText: "Got it!",
          confirmButtonColor: theme.palette.primary.main,
        });
        console.error(error);
      })
      .then(function () {
        window.location = "/thebai/question-list";
      });
  };

  const confirm_delete = () => {
    Swal.fire({
      title: "Confirm delete",
      text: "Do you want to delete this question? This process is irreversible",
      showDenyButton: true,
      confirmButtonText: "Yes, delete",
    }).then((result) => {
      if (result.isConfirmed) {
        delete_question();
      }
    });
  };

  useEffect(() => {
    get_question();
  }, []);

  return (
    <MasterPage>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <div>
              <Title value="Question Details" />
              <Subtitle value={"Created by: " + question.question.created_by} />
            </div>
            <div>
              <Link href={"/thebai/question-editor/" + question_id}>
                <Button
                  variant="contained"
                  sx={{ textTransform: "none", mr: 1 }}
                >
                  Update
                </Button>
              </Link>
              <Button
                variant="contained"
                sx={{ textTransform: "none" }}
                color="error"
                onClick={() => confirm_delete()}
              >
                Delete
              </Button>
            </div>
          </Stack>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CustomCard>
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Title
                      value="Statements"
                      color={theme.palette.primary.main}
                    />
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Tooltip title="Difficulty">
                        <Chip
                          size="small"
                          label={convert_difficulty_to_string(
                            question.question.difficulty
                          )}
                          sx={{
                            color: "white",
                            backgroundColor: convert_difficulty_to_color(
                              question.question.difficulty
                            ),
                            marginRight: "3px",
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Skill">
                        <Chip
                          size="small"
                          label={
                            skills.find(
                              (skill) => skill.id === question.question.skill_id
                            ).name
                          }
                          sx={{
                            marginRight: "3px",
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Time">
                        <Chip
                          size="small"
                          label={question.question.time + " seconds"}
                          sx={{
                            marginRight: "3px",
                          }}
                        />
                      </Tooltip>
                      {question.question.is_validated ? (
                        <Tooltip title="Validated">
                          <Icon sx={{ color: "#1ABC9C" }}>check_circle</Icon>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Not validated">
                          <Icon sx={{ color: "#E74C3C" }}>error</Icon>
                        </Tooltip>
                      )}
                    </Stack>
                  </Stack>
                </Box>
                <Statement
                  editorBehaviourEnabled={false}
                  showModeSelector={false}
                />
              </CustomCard>
            </Grid>
            {question.question.choices.map((q, i) => (
              <Grid item xs={12} key={i}>
                <CustomCard>
                  <Stack direction="horizontal" alignItems={"center"}>
                    <Icon sx={{ color: theme.palette.primary.main, mr: 1 }}>
                      {q.is_correct_answer
                        ? "task_alt"
                        : "radio_button_unchecked"}
                    </Icon>
                    <MathJax>
                      <div dangerouslySetInnerHTML={{ __html: q.text }} />
                    </MathJax>
                  </Stack>
                </CustomCard>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </MasterPage>
  );
}
