import { backend_client } from "../../../helpers/api";

const examDesignEndpoint = "/exam-designs/search";
const findExamDesignsService = () => {
  return backend_client
    .get(examDesignEndpoint)
    .then((response) => response.data)
    .then((data) => mapResponseToExamDesigns(data));
};

const mapResponseToExamDesigns = (response) => {
  return response
    .filter((r) => r.is_active)
    .map((r) => ({ id: r.id, name: r.name }));
};

export default findExamDesignsService;
