import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField, Alert, Grid } from "@mui/material";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { format, isValid, isSameDay } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  datetime: {
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
  },
});

const InitialState = {
  initial: null,
  final: null,
};

const initialFieldName = "initial";
const finalFieldName = "final";

const DateTimeRangePicker = ({
  name,
  value: valueFromProps,
  initialLabel,
  finalLabel,
  onDateTimeRangeChanged,
  onHasErrorsAtInitialDateChanged,
  onHasErrorsAtFinalDateChanged,
  disabled = false,
  disableInitialPast = false,
  disableInitialFuture = false,
  disableFinalPast = false,
  disableFinalFuture = false,
}) => {
  const [initialDateTimeError, setInitialDateTimeError] = useState(null);
  const [finalDateTimeError, setFinalDateTimeError] = useState(null);
  const [internalDateTimeRange, setInternalDateTimeRange] =
    useState(InitialState);

  const classes = useStyles();

  const isControlled = typeof valueFromProps !== "undefined";

  const value = isControlled ? valueFromProps : internalDateTimeRange;

  const convertReasonAndRestrictionToErrorMessage = (
    reason,
    restrictionDateTime
  ) => {
    if (!reason) return null;

    const fromReasonToErrormessage = new Map([
      ["invalidDate", "Invalid date format"],
      ["disablePast", "Dates in the past are not allowed"],
      [
        "minDate",
        `Date should not be before ${
          isValid(restrictionDateTime) ? format(restrictionDateTime, "P") : ""
        }`,
      ],
      [
        "maxDate",
        `Date should not be after ${
          isValid(restrictionDateTime) ? format(restrictionDateTime, "P") : ""
        }`,
      ],
      [
        "minTime",
        `Time should not be before ${
          isValid(restrictionDateTime) ? format(restrictionDateTime, "p") : ""
        }`,
      ],
      [
        "maxTime",
        `Time should not be after ${
          isValid(restrictionDateTime) ? format(restrictionDateTime, "p") : ""
        }`,
      ],
    ]);

    const explanation = fromReasonToErrormessage.get(reason);
    return explanation ? explanation : "Unknown error";
  };

  const handleDateTimeRangeChanged = (newValue, sender) => {
    const newDateTimeRange = { ...value, [sender]: newValue };
    onDateTimeRangeChanged(newDateTimeRange, name);
    if (!isControlled) {
      setInternalDateTimeRange(newDateTimeRange);
    }
  };

  const handleErrors = (reason, sender) => {
    if (sender === "final") {
      onDateTimeRangeChanged({ ...value, [sender]: value.final });
      const maybeErrorMessage = convertReasonAndRestrictionToErrorMessage(
        reason,
        value.initial
      );
      if (maybeErrorMessage) {
        setFinalDateTimeError(maybeErrorMessage);
        onHasErrorsAtFinalDateChanged(true);
        return;
      }

      onHasErrorsAtFinalDateChanged(false);
      setFinalDateTimeError(null);
      return;
    }

    if (sender === "initial") {
      onDateTimeRangeChanged({ ...value, [sender]: value.final });
      const maybeErrorMessage = convertReasonAndRestrictionToErrorMessage(
        reason,
        value.final
      );
      if (maybeErrorMessage) {
        onHasErrorsAtInitialDateChanged(true);
        setInitialDateTimeError(maybeErrorMessage);
        return;
      }

      onHasErrorsAtInitialDateChanged(false);
      setInitialDateTimeError(null);
      return;
    }
  };

  const getMaximumInitialTime = ({ initial, final }) => {
    if (!final) return null;

    if (isSameDay(initial, final)) {
      return final;
    }

    return null;
  };

  const getMinimumFinalTime = ({ initial, final }) => {
    if (!initial) {
      return null;
    }

    if (isSameDay(initial, final)) {
      return initial;
    }

    return null;
  };

  const maximumInitialTime = getMaximumInitialTime(value);
  const minimumFinalTime = getMinimumFinalTime(value);

  return (
    <>
      <Grid container spacing={3} alignItems>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid item xs={12} sm={6}>
            <DesktopDateTimePicker
              disabled={disabled}
              className={classes.datetime}
              renderInput={(props) => <TextField {...props} />}
              disablePast={disableInitialPast}
              disableFuture={disableInitialFuture}
              maxDate={value.final}
              maxTime={maximumInitialTime}
              label={initialLabel}
              value={value.initial}
              onChange={(newValue) =>
                handleDateTimeRangeChanged(newValue, initialFieldName)
              }
              onError={(reason) => handleErrors(reason, initialFieldName)}
            />
            {initialDateTimeError ? (
              <Alert severity="error">{initialDateTimeError}</Alert>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <DesktopDateTimePicker
              disabled={disabled}
              className={classes.datetime}
              renderInput={(props) => <TextField {...props} />}
              disablePast={disableFinalPast}
              disableFuture={disableFinalFuture}
              label={finalLabel}
              minDate={value.initial}
              minTime={minimumFinalTime}
              value={value.final}
              onChange={(newValue) =>
                handleDateTimeRangeChanged(newValue, finalFieldName)
              }
              onError={(reason) => handleErrors(reason, finalFieldName)}
            />
            {finalDateTimeError ? (
              <Alert severity="error">{finalDateTimeError}</Alert>
            ) : null}
          </Grid>
        </LocalizationProvider>
      </Grid>
    </>
  );
};

DateTimeRangePicker.propTypes = {
  value: PropTypes.object,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  initialLabel: PropTypes.string.isRequired,
  finalLabel: PropTypes.string.isRequired,
  disableInitialPast: PropTypes.bool,
  disableInitialFuture: PropTypes.bool,
  disableFinalPast: PropTypes.bool,
  disableFinalFuture: PropTypes.bool,
  onDateTimeRangeChanged: PropTypes.func.isRequired,
  onHasErrorsAtInitialDateChanged: PropTypes.func.isRequired,
  onHasErrorsAtFinalDateChanged: PropTypes.func.isRequired,
};

export default DateTimeRangePicker;
