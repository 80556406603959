import * as React from "react";
import { Auth } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Button,
  Collapse,
  Container,
  CssBaseline,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  Toolbar,
  Typography,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import Icon from "@mui/material/Icon";
import { ThemeProvider, styled } from "@mui/material/styles";

import FactoredLogo from "assets/images/factored_logo.png";
import { menu_data } from "constants/menuContent";
import { useAppContext } from "libs/contextLib";
import theme from "components/foundations/themes";
import { routesConfiguration } from "Routes";

export const useStyles = makeStyles({
  auxiliarCard: {
    mt: 1,
    mb: 1,
    width: "350px",
  },
  cardAvatar: {
    width: "100%",
    aspectRatio: 1,
    borderRadius: "50%",
  },
  cardName: {
    fontWeight: "600",
    fontSize: 18,
  },
  cardJobTitle: {
    fontWeight: "100",
    fontSize: 14,
    color: theme.palette.grey[700],
  },
  cardEmailIcon: {
    color: theme.palette.grey[500],
    fontSize: 14,
  },
  cardEmail: {
    fontWeight: "500 !important",
    fontSize: "12px !important",
    color: theme.palette.grey[500],
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0px !important",
  },
  separator: {
    color: theme.palette.grey[600],
    fontSize: 14,
    fontWeight: "100",
    paddingTop: 10,
    paddingBottom: 0,
  },
  menuIcon: {
    color: theme.palette.grey[400],
  },
  content: {
    backgroundColor: "#f5f5f5",
    flexGrow: 1,
    height: "calc(100vh - 64px)",
    overflow: "auto",
    marginTop: "64px",
  },
  activeItem: {
    pl: 4,
    backgroundColor: "#ebf8f9 !important",
    borderRadius: "20px !important",
  },
  inactiveItem: {
    pl: 4,
    borderRadius: "20px !important",
  },
  activeLink: {
    width: "100%",
    color: theme.palette.primary.main + "!important",
    fontWeight: "bold",
    borderRadius: "20px",
  },
  inactiveLink: {
    width: "100%",
    borderRadius: "20px",
    color: theme.palette.grey[900],
  },
});

const drawerWidth = 320;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "#f5f5f5",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    height: "100vh",
    backgroundColor: "#ffffff",
    borderWidth: 0,
    padding: "0 24px",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      padding: "0",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: 0,
      },
    }),
  },
}));

const StyledMenu = styled((props) => <Menu elevation={0} {...props} />)(
  ({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 20,
      padding: 20,
      marginTop: 10,
      minWidth: 180,
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
      },
    },
  })
);

export default function MasterPage(props) {
  const [open, setOpen] = React.useState(true);
  const [filteredMenus, setFilteredMenus] = React.useState(null);
  const [showAuxMenu, setShowAuxMenu] = React.useState(false);

  const { onAuthStateChange, userRole } = useAppContext();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const filterMenusForRole = (routes, userRole) => {
    const menuOptions = menu_data[0].options;

    const filteredOptions = menuOptions.filter((option) =>
      routes.some(
        (route) =>
          route.path === option.route &&
          route.allowedRoles.some((role) => role === userRole)
      )
    );

    const filteredMenu = {
      name: menu_data[0].name,
      options: filteredOptions,
    };

    setFilteredMenus([filteredMenu, ...menu_data.slice(1)]);
  };

  if (!filteredMenus && userRole) {
    filterMenusForRole(routesConfiguration, userRole);
  }

  const toogleMenu = (option, submenu) => {
    var menu_temp = [...filteredMenus];
    menu_temp[option].options[submenu].open =
      !menu_temp[option].options[submenu].open;
    setFilteredMenus(menu_temp);
  };

  async function logout() {
    try {
      Auth.signOut();
      onAuthStateChange(false);
    } catch (error) {
      console.error("error signing out: ", error);
    }
  }

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position={"absolute"} elevation={0} open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
              backgroundColor: theme.palette.primary.main,
            }}
          >
            <IconButton
              edge="start"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                color: theme.palette.grey[100],
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: "flex" }}>
              <Button
                id="auxMenu"
                sx={{ p: 0, textTransform: "none" }}
                onClick={() => setShowAuxMenu(!showAuxMenu)}
              >
                <Button
                  variant="contained"
                  color="error"
                  sx={{ textTransform: "none" }}
                  startIcon={<Icon>logout</Icon>}
                  fullWidth
                  onClick={() => logout()}
                >
                  Logout
                </Button>
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar className={classes.toolbar}>
            <img src={FactoredLogo} style={{ width: "50%" }} />
          </Toolbar>
          <Toolbar />
          {filteredMenus ? (
            filteredMenus.map((menu, key) => (
              <React.Fragment key={key}>
                <Typography variant="span" className={classes.separator}>
                  {menu.name}
                </Typography>
                <List>
                  {menu.options.map((option, key2) => (
                    <React.Fragment key={key2 + " menu"}>
                      {option.submenu ? (
                        <ListItem
                          button
                          key={option.name}
                          onClick={() => toogleMenu(key, key2)}
                          sx={{ borderRadius: "20px" }}
                        >
                          <ListItemIcon>
                            <Icon className={classes.menuIcon}>
                              {option.icon}
                            </Icon>
                          </ListItemIcon>
                          <ListItemText
                            sx={{ color: theme.palette.grey[900] }}
                            primary={option.name}
                          />
                          {option.submenu && (
                            <Icon className={classes.menuIcon}>
                              {option.open ? "expand_less" : "expand_more"}
                            </Icon>
                          )}
                        </ListItem>
                      ) : (
                        <Link href={option.route} underline="none">
                          <ListItem
                            button
                            key={option.name}
                            onClick={() => toogleMenu(key, key2)}
                            sx={{ borderRadius: "20px" }}
                            className={
                              window.location.pathname === option.route
                                ? classes.activeItem
                                : classes.inactiveItem
                            }
                          >
                            <ListItemIcon>
                              <Icon
                                sx={{
                                  color:
                                    window.location.pathname === option.route
                                      ? theme.palette.primary.main
                                      : theme.palette.grey[500],
                                }}
                              >
                                {option.icon}
                              </Icon>
                            </ListItemIcon>
                            <ListItemText
                              disableTypography
                              primary={
                                <Typography
                                  variant="body"
                                  className={
                                    window.location.pathname === option.route
                                      ? classes.activeLink
                                      : classes.inactiveLink
                                  }
                                >
                                  {option.name}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </Link>
                      )}

                      <Collapse in={option.open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {option.submenu &&
                            option.submenu.map((item3) => (
                              <ListItem
                                button
                                key={option.name}
                                className={
                                  window.location.pathname === item3.route
                                    ? classes.activeItem
                                    : classes.inactiveItem
                                }
                              >
                                <Link
                                  href={item3.route}
                                  underline="none"
                                  color={theme.palette.grey[700]}
                                  className={
                                    window.location.pathname === item3.route
                                      ? classes.activeLink
                                      : classes.inactiveLink
                                  }
                                >
                                  {item3.name}
                                </Link>
                              </ListItem>
                            ))}
                        </List>
                      </Collapse>
                    </React.Fragment>
                  ))}
                </List>
              </React.Fragment>
            ))
          ) : (
            <></>
          )}
        </Drawer>
        <Box component="main" className={classes.content}>
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            {props.children}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
