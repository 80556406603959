import React, { useState } from "react";
import { FormControlLabel, FormGroup, Switch } from "@mui/material";

const PendingFinishedSwitch = ({ value, onSwitchChanged }) => {
  const getNewState = (event) => {
    const name = event.target.name;
    let checked = event.target.checked;

    if (name === "onlyFinished") {
      return {
        onlyFinished: checked,
        onlyPending: false,
        onlyExpired: false,
      };
    }

    if (name === "onlyPending") {
      return {
        onlyFinished: false,
        onlyPending: checked,
        onlyExpired: false,
      };
    }

    if (name === "onlyExpired") {
      return {
        onlyFinished: false,
        onlyPending: false,
        onlyExpired: checked,
      };
    }
  };

  const handleFormDataChanged = (event) => {
    const newState = getNewState(event);
    onSwitchChanged(newState);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            name="onlyFinished"
            checked={value.onlyFinished}
            onChange={handleFormDataChanged}
          />
        }
        label="Only Finished"
      />
      <FormControlLabel
        control={
          <Switch
            name="onlyPending"
            checked={value.onlyPending}
            onChange={handleFormDataChanged}
          />
        }
        label="Only Pending"
      />
      <FormControlLabel
        control={
          <Switch
            name="onlyExpired"
            checked={value.onlyExpired}
            onChange={handleFormDataChanged}
          />
        }
        label="Only Expired"
      />
    </FormGroup>
  );
};

export default PendingFinishedSwitch;
