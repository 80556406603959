import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { Navigate, useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import Swal from "sweetalert2";
import { Auth } from "aws-amplify";

import theme from "../../components/foundations/themes";
import CustomCard from "./../../components/modules/cards";
import { useAppContext } from "./../../libs/contextLib";
import { Paths } from "../../Routes";

import FactoredLogo from "../../assets/images/factored_logo.png";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      align="center"
      {...props}
      style={{ color: theme.palette.grey[400] }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://factored.ai/">
        Factored
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const querystring = (name, url = window.location.href) => {
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export default function SignIn() {
  const { onAuthStateChange, isAuthenticated } = useAppContext();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const redirect = querystring("redirect");
  const navigate = useNavigate();

  const login = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const user = await Auth.signIn(email, password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        Swal.fire({
          title: "Password Update Required",
          text: "Please change the provided password. You will be redirected",
          icon: "info",
          confirmButtonText: "Got it!",
          confirmButtonColor: theme.palette.primary.main,
        }).then(() => navigate("/user/password/change"));
      }
      setLoading(false);
      onAuthStateChange(true);
    } catch (e) {
      Swal.fire({
        title: "Wrong credentials",
        text: "Unable to authenticate, please confirm your credentials",
        icon: "error",
        confirmButtonText: "Got it!",
        confirmButtonColor: theme.palette.primary.main,
      });
      onAuthStateChange(false);
      setLoading(false);
    }
  };

  return isAuthenticated ? (
    <Navigate to={redirect === "" || redirect === null ? "/" : redirect} />
  ) : (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CustomCard>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={FactoredLogo} style={{ width: "50%" }} />
                <Box sx={{ mt: 1 }}>
                  <form onSubmit={login}>
                    <br />
                    <Typography sx={{ fontWeight: "600", fontSize: 14 }}>
                      Email
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      name="email"
                      type="email"
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    <br />
                    <br />
                    <Typography sx={{ fontWeight: "600", fontSize: 14 }}>
                      Password
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      name="password"
                      type="password"
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    {loading ? (
                      <LoadingButton
                        loading
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, textTransform: "none" }}
                      >
                        Submit
                      </LoadingButton>
                    ) : (
                      <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, textTransform: "none" }}
                        type="submit"
                      >
                        Sign In
                      </Button>
                    )}
                  </form>
                </Box>
              </Box>
            </CustomCard>
            <Grid container sx={{ mt: 1 }}>
              <Grid item xs>
                <Link
                  href={Paths.FORGOT_PASSWORD}
                  variant="body2"
                  style={{ color: "white" }}
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </Box>
    </ThemeProvider>
  );
}
