export const menu_data = [
  {
    name: "Menu",
    options: [
      {
        name: "Available Exams",
        icon: "pending_actions",
        open: false,
        route: "/thebai/exams-available",
      },
      {
        name: "Question Designer",
        icon: "edit_note",
        open: false,
        route: "/thebai/question-designer",
      },
      {
        name: "Question List",
        icon: "filter_list",
        open: false,
        route: "/thebai/question-list",
      },
      {
        name: "Question Search",
        icon: "manage_search",
        open: false,
        route: "/thebai/question-search",
      },
      {
        name: "Invite Participants",
        icon: "grading",
        open: false,
        route: "/thebai/exam-instances-creator",
      },
      {
        name: "Report and Results",
        icon: "manage_search",
        open: false,
        route: "/thebai/exam-instances/search",
      },
      {
        name: "Exam Designer",
        icon: "edit_note",
        open: false,
        route: "/thebai/exam-designer",
      },
    ],
  },
];
