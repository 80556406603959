import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import questionReducer from "./slices/questionSlice";
import userReducer from "./slices/userSlice";
import answerCodeReducer from "./slices/answerCodeSlice";
import questionDesignReducer from "./slices/questionDesignSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const rootReducer = combineReducers({
  question: questionReducer,
  user: userReducer,
  answerCode: answerCodeReducer,
  questionDesign: questionDesignReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
