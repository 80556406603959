import axiosInstanceBuilder from "../config/axios/axiosInstanceBuilder";
import { useFetch } from "../config/axios/useFetch";

const baseUrl = process.env.REACT_APP_API_DEV_URL;

export const useUpdateQuestion = (id) => {
  const endpoint = `/questions/${id}`;
  const createAxiosPromise = (data) => {
    return axiosInstanceBuilder(baseUrl).put(endpoint, data);
  };

  const { fetchApi } = useFetch(createAxiosPromise, false);

  const handleUpdateQuestion = async (question) => {
    const updateQuestionRequest = mapQuestionToUpdateRequest(question);
    const response = await fetchApi(updateQuestionRequest);
    const { data, ...rest } = response;
    return { id: data.id, ...rest };
  };

  const replacePreTags = (htmlString) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(htmlString, "text/html");
    const preElements = htmlDoc.querySelectorAll("pre.code_editor");
    let counter = 0;

    preElements.forEach((pre) => {
      const replacementText = `{{ code_editor(${counter}) }}`;
      pre.outerHTML = replacementText;
      counter++;
    });

    return htmlDoc.body.innerHTML;
  };

  const mapQuestionToUpdateRequest = ({
    type,
    skill: skill_id,
    difficulty,
    time,
    statement,
    choices,
    resources,
    codeEditors: code_editors,
    allowRunningTestCases: allows_running_test_cases,
    allowedLanguages: coding_languages,
    informsTestCaseCorrectness: informs_test_case_correctness,
    codeMode: solution_code_language,
    solutionCode: solution_code,
    testCases: test_cases,
  }) => ({
    type,
    skill_id,
    difficulty,
    time,
    statement: replacePreTags(statement),
    choices,
    resources,
    code_editors,
    allows_running_test_cases,
    coding_languages,
    solution_code,
    informs_test_case_correctness,
    solution_code_language,
    test_cases,
  });

  return { handleUpdateQuestion };
};
