export const findAndReplaceLinks = (htmlCode) => {
  // Regular expression to match URLs with HTTP/HTTPS
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Regular expression to match shortened URLs without protocol
  const shortUrlRegex = /[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)\/[a-zA-Z0-9\-\_]+/g;

  // Regular expression to match email addresses
  const emailRegex = /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/g;

  // Replace URLs with <a> tags
  let text = htmlCode.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');

  // Replace shortened URLs with <a> tags
  text = text.replace(shortUrlRegex, '<a href="http://$&" target="_blank">$&</a>');

  // Replace email addresses with <a> tags
  text = text.replace(emailRegex, '<a href="mailto:$1">$1</a>');

  return text;
};
