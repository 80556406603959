import React from "react";
import { Chip, Grid, Stack } from "@mui/material";
import PropTypes from "prop-types";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useTheme } from "@mui/material/styles";

const ExamSkillsInformation = (props) => {
  const theme = useTheme();
  const {examSkills} = props;

  return(
    <div>
      <Grid item xs={12}>
        {
          examSkills && (
            <Stack
              direction={"row"}
              flexWrap={"wrap"}
              spacing={2}
              alignItems={"center"}
            >
              <ErrorOutlineIcon
                style={{
                  color: theme
                    .palette
                    .primary
                    .main,
                }}
              />
              {
                examSkills.map(
                  (skill, keychip) => {
                    if(keychip > 3) {
                      return;
                    } else if(keychip === 3) {
                      return (
                        <Chip
                          key={keychip}
                          label="+3"
                          sx={{
                            mb: 1,
                            mr: 1,
                          }}
                          style={{
                            marginTop: "6px",
                          }}
                        />
                      );
                    } else {
                      return(
                        <Chip
                          key={keychip}
                          label={skill}
                          sx={{
                            mb: 1,
                            mr: 1,
                          }}
                          style={{
                            marginTop: "6px",
                          }}
                        />
                      );
                    }
                  }
                )
              }
            </Stack>
          )
        }
      </Grid>
    </div>
  );
};

ExamSkillsInformation.propTypes = {
  examSkills: PropTypes.arrayOf(PropTypes.string)
};

export default ExamSkillsInformation;
