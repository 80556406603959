import { useRef, useState } from "react";
import {
  Badge,
  Grid,
  Typography,
  Box,
  Autocomplete,
  InputLabel,
  TextField,
} from "@mui/material";
import theme from "components/foundations/themes";
import CodeTextField from "components/elements/codeTextField";
import Editor from "pages/ExamQuestion/components/Editor";

export default function Preview({
  question,
  answers,
  isCoding,
  classes,
  availableLanguages,
}) {
  const editor = useRef();
  const [editorLanguage, setEditorLanguage] = useState(availableLanguages[0]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <div className={classes.questionPreview}>
          <div dangerouslySetInnerHTML={{ __html: question }} />
        </div>
      </Grid>
      {isCoding ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          direction={"row"}
          mb={8}
          mt={4}
          ml={4}
          alignItems={"stretch"}
          xs={{ flexWrap: { xs: "wrap", md: "noWrap" } }}
          width={"100%"}
        >
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            flexWrap={"wrap"}
            width={"100%"}
          >
            <Box sx={{ width: { xs: "100%", md: "20%" }, marginBottom: 2 }}>
              <InputLabel>Language</InputLabel>
              <Autocomplete
                disablePortal
                id="editor-language-selection"
                options={availableLanguages}
                fullWidth
                renderInput={(params) => <TextField {...params} />}
                value={editorLanguage}
                onChange={(event, value) => {
                  setEditorLanguage(value);
                }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Editor
                code={"Put your code here"}
                allowEdit={true}
                allowCopy={true}
                allowPaste={true}
                language={editorLanguage}
                id={0}
              />
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexWrap={"wrap"}
            sx={{
              width: { xs: "100%", md: "40%" },
              marginLeft: { xs: 0, md: 5 },
              marginTop: { xs: 5, md: 3 },
            }}
          >
            <Box width={"100%"} mb={3}>
              <Typography mb={2} variant={"h6"}>
                STDIN
              </Typography>
              <CodeTextField
                multiline
                variant="filled"
                rows={6}
                sx={{
                  width: {
                    xs: "100%",
                    md: "90%",
                    fontFamily: "Courier New !important",
                  },
                }}
                className="ace_editor"
              />
            </Box>
            <Box width={"100%"}>
              <Typography mb={2} variant={"h6"}>
                STDOUT
              </Typography>
              <CodeTextField
                multiline
                variant="filled"
                rows={6}
                sx={{ width: "90%" }}
                disabled
              />
            </Box>
          </Box>
        </Box>
      ) : (
        <Grid item xs={12}>
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              color: theme.palette.primary.main,
            }}
          >
            Answers
          </Typography>
          <br />
          <Grid container spacing={3}>
            {answers.map((answer, key) => (
              <Grid item xs={12}>
                <Badge
                  badgeContent={"✓"}
                  color="primary"
                  invisible={!answer.is_correct_answer}
                  style={{ width: "100%" }}
                >
                  <div className={classes.answerPreview}>
                    <div dangerouslySetInnerHTML={{ __html: answer.text }} />
                  </div>
                </Badge>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
