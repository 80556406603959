import React, { useCallback, useState, useEffect, useRef } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTheme } from "@mui/material/styles";

import { Subtitle, Title } from "components/elements/texts";
import CustomCard from "components/modules/cards";
import MasterPage from "components/modules/masterpage";
import { QuestionDetail } from "./components";
import ProgressBar from "../ExamsAvailable/components/ColoredProgressBar";
import { backend_client } from "helpers/api";
import { clearQuestionState, updateQuestion } from "state/slices/questionSlice";
import { cleanAnswerCode } from "state/slices/answerCodeSlice";

const ExamQuestion = () => {
  const { exam_instance_id } = useParams();
  const dispatch = useDispatch();
  const currentQuestion = useSelector((state) => state.question);
  const answerCode = useSelector((state) => state.answerCode);
  const [loading, setLoading] = useState(false);
  const [examFinished, setExamFinished] = useState(false);
  const [openTimeoutCard, setOpenTimeoutCard] = useState(false);
  const [openFinalCard, setOpenFinalCard] = useState(false);
  const [openNoOptionSelectedCard, setOpenNoOptionSelectedCard] =
    useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [timerKey, setTimerKey] = useState(0);
  const [timerDuration, setTimerDuration] = useState(0);
  const [timerUpdated, setTimerUpdated] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [questionType, setQuestionType] = useState();

  const myRef = useRef(null);

  const scrollToTop = () => {
    myRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const theme = useTheme();

  let location = useLocation();
  useEffect(() => {}, [location]);

  const renderTimer = ({ remainingTime }) => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    let newTimerColor = "black";
    if (minutes === 0 && seconds <= 10 && seconds % 2 === 0) {
      newTimerColor = "red";
    }
    if (minutes === 0) {
      return <div style={{ color: `${newTimerColor}` }}>{seconds}</div>;
    }
    if (seconds < 10) {
      return `${minutes}:0${seconds}`;
    }
    return `${minutes}:${seconds}`;
  };

  const answerQuestion = useCallback(
    async (fetchNextQuestion = true) => {
      setIsPlaying(false);

      const params = {
        csrf_token: null,
        hash_id: currentQuestion ? currentQuestion.hash_id : null,
        exam_request_token: currentQuestion
          ? currentQuestion.exam_request_token
          : null,
        fetch_next_question: fetchNextQuestion,
      };

      if (currentQuestion?.question?.type === "coding") {
        params.open_response = answerCode.user_code;
        params.response_language = answerCode.language.language.toLowerCase();
      } else {
        params.selected_choices_ids = selectedOptions;
      }

      const endpoint = `/exam-instances/${exam_instance_id}/answers`;
      const data = await backend_client
        .post(endpoint, params)
        .then((response) => response.data);
      setTimeout(scrollToTop, 500);
      setSelectedOptions([]);
      if (data.finished_exam) {
        setExamFinished(true);
      }
      if (fetchNextQuestion || data.finished_exam) {
        dispatch(cleanAnswerCode());
        dispatch(updateQuestion(data));
        const formattedString = data?.question?.type
          .replace(/_/g, " ")
          .replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
        setQuestionType(formattedString);
        setLoading(false);
      }
    },
    [currentQuestion, selectedOptions, answerCode]
  );

  useEffect(() => {
    setTimerKey((prevKey) => prevKey + 1);
  }, [timerUpdated]);

  useEffect(() => {
    if (currentQuestion !== null) {
      setTimerDuration(currentQuestion.question.time);
      setIsPlaying(true);
    }
  }, [timerKey]);

  useEffect(() => {
    if (currentQuestion !== null && !currentQuestion.finished_exam) {
      setTimerUpdated((prevState) => !prevState);
    }

    currentQuestion && currentQuestion.finished_exam && setOpenFinalCard(true);
  }, [currentQuestion]);

  const sendAnswer = () => {
    if (
      (selectedOptions !== null && selectedOptions.length > 0) ||
      answerCode.user_code.length > 0
    ) {
      if (currentQuestion.question_number === currentQuestion.exam_length) {
        setOpenFinalCard(true);
        setExamFinished(true);
      }
      answerQuestion();
    } else {
      setOpenNoOptionSelectedCard(true);
    }
  };

  useEffect(() => {
    openFinalCard && dispatch(clearQuestionState());
  }, [openFinalCard]);

  const selectOption = (id, type) => {
    if (type === "radio") {
      setSelectedOptions([id]);
    } else if (type === "checkbox") {
      if (selectedOptions.includes(id)) {
        var newSelectedOptions = [...selectedOptions];
        newSelectedOptions.splice(newSelectedOptions.indexOf(id), 1);
        setSelectedOptions(newSelectedOptions);
      } else {
        setSelectedOptions((current) => [...current, id]);
      }
    }
  };

  useEffect(() => {
    !currentQuestion && !examFinished && answerQuestion();
  }, [currentQuestion]);

  const navigate = useNavigate();

  const routeChange = (route) => {
    setOpenFinalCard(false);
    navigate(route);
  };

  return (
    <MasterPage>
      <div ref={myRef} />
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {loading ? (
                <CircularProgress />
              ) : (
                currentQuestion &&
                !currentQuestion.finished_exam && (
                  <CustomCard>
                    <div style={{ position: "relative" }}>
                      <div style={{ position: "sticky", top: 100, zIndex: 10 }}>
                        <Grid justifyContent={"end"} container>
                          <CountdownCircleTimer
                            isPlaying={isPlaying}
                            key={timerKey}
                            duration={timerDuration}
                            colors={[
                              "#004777",
                              "#F7B801",
                              "#A30000",
                              "#A30000",
                            ]}
                            colorsTime={[
                              timerDuration,
                              timerDuration / 2,
                              10,
                              0,
                            ]}
                            size={60}
                            strokeWidth={6}
                            onComplete={() => {
                              setOpenTimeoutCard(true);
                              answerQuestion(false);
                              return { shouldRepeat: false };
                            }}
                          >
                            {renderTimer}
                          </CountdownCircleTimer>
                        </Grid>
                      </div>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                        }}
                      >
                        <Stack
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Title
                            value={`Question ${currentQuestion.question_number}/${currentQuestion.exam_length}`}
                            color={theme.palette.primary.main}
                          />
                          <Box
                            style={{
                              width: "50%",
                            }}
                          >
                            <ProgressBar
                              currentValue={currentQuestion.question_number}
                              totalValue={currentQuestion.exam_length}
                            />
                          </Box>
                          <Subtitle
                            value={questionType}
                            color={theme.palette.primary.main}
                          />
                        </Stack>
                      </Box>
                      <QuestionDetail
                        key={currentQuestion?.question?.id}
                        selectedOptions={selectedOptions}
                        onChange={selectOption}
                        language={currentQuestion.language || "python"}
                      />
                      <Box display={"flex"} justifyContent={"end"}>
                        <LoadingButton
                          loading={!isPlaying}
                          variant="contained"
                          style={{
                            backgroundColor: theme.palette.primary.main,
                            marginBottom: "4px",
                          }}
                          onClick={() => sendAnswer()}
                        >
                          {currentQuestion.question_number ===
                          currentQuestion.exam_length
                            ? "Finish Exam"
                            : "Next Question"}
                        </LoadingButton>
                      </Box>
                    </div>
                  </CustomCard>
                )
              )}
            </Grid>
          </Grid>
          <div>
            <Dialog
              open={openFinalCard}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              hideBackdrop={true}
            >
              <DialogTitle id="alert-dialog-title">
                <Typography variant="h6" component="h2">
                  {"Congratulations!"}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {"You have successfully completed the exam"}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    routeChange("/thebai/exams-available");
                  }}
                  variant={"contained"}
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    marginLeft: "10px",
                    color: "white",
                  }}
                  size={"medium"}
                  autoFocus
                >
                  {"Continue"}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div>
            <Dialog
              open={openTimeoutCard}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              slotProps={{
                backdrop: {
                  style: {
                    backgroundColor: "white",
                  },
                },
              }}
            >
              <DialogTitle id="alert-dialog-title">
                <Typography variant="h6" component="h2">
                  {"Time is Up!"}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {
                    "What you last marked was sent as the answer. Do you wish to continue now or later?"
                  }
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenTimeoutCard(false);
                    navigate("/thebai/exams-available");
                  }}
                  variant={"outlined"}
                  size={"medium"}
                >
                  {"Continue Later"}
                </Button>
                <Button
                  onClick={() => {
                    setOpenTimeoutCard(false);
                    answerQuestion();
                  }}
                  variant={"contained"}
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    marginLeft: "10px",
                    color: "white",
                  }}
                  size={"medium"}
                  autoFocus
                >
                  {"Continue Now"}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div>
            <Dialog
              open={openNoOptionSelectedCard}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Typography variant="h6" component="h2">
                  {currentQuestion?.question?.type === "coding"
                    ? "You haven not written any code"
                    : "You did not select any answer!"}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {
                    "You didn't answer the question. Do you wish to continue to the next question?"
                  }
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setOpenNoOptionSelectedCard(false);
                  }}
                  variant={"outlined"}
                  size={"medium"}
                >
                  {"Take me back"}
                </Button>
                <Button
                  onClick={() => {
                    setOpenNoOptionSelectedCard(false);
                    answerQuestion();
                  }}
                  variant={"contained"}
                  style={{
                    backgroundColor: theme.palette.primary.main,
                    marginLeft: "10px",
                    color: "white",
                  }}
                  size={"medium"}
                  autoFocus
                >
                  {"Continue"}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
      )}
    </MasterPage>
  );
};

export default ExamQuestion;
