import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  TextField,
  Box,
  Checkbox,
  FormControlLabel,
  Chip,
  Autocomplete,
} from "@mui/material";
import { toZonedTime, fromZonedTime } from "date-fns-tz";
import Grid from "@mui/material/Grid2";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { isValid, isSameDay } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";

const timezones = Intl.supportedValuesOf("timeZone");
const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const useStyles = makeStyles({
  datetime: {
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
  },
  spaces: {
    marginTop: 20,
    marginBottom: 20,
  },
});

const errorMessages = {
  noValue: "Must provide a date and time",
  invalid: "Invalid date format",
  disablePast: "Date should not be in the past",
  minDate: "Date should not be before the initial date",
  maxDate: "Date should not be after the final date",
};

const validateDate = (isInitialDate, maybeValidDate, restrictionDate) => {
  if (!maybeValidDate) return errorMessages.noValue;
  if (!isValid(maybeValidDate)) return errorMessages.invalid;

  const date = maybeValidDate;

  if (restrictionDate === null || !isValid(restrictionDate)) {
    return null;
  }

  if (isInitialDate) {
    if (date > restrictionDate) {
      return errorMessages.maxDate;
    }
  } else {
    if (date < restrictionDate) {
      return errorMessages.minDate;
    }
  }

  return null;
};

const findErrorsInRange = ({ initial, final }) => {
  const errorAtInitialDate = validateDate(true, initial, final);
  const errorAtFinalDate = validateDate(false, final, initial);

  return { initial: errorAtInitialDate, final: errorAtFinalDate };
};

const DateTimeRange = ({ range, onChange, forceShowErrors }) => {
  const errors = findErrorsInRange(range);

  const [isDirty, setIsDirty] = useState({ initial: false, final: false });
  const [checked, setChecked] = useState(false);

  // Initialized in client's timezone
  const [selectedTimezone, setSelectedTimezone] = useState(
    clientTimezone
  );

  const classes = useStyles();

  const initialDateTimeLabel = "Avaliable from";
  const finalDateTimeLabel = "Avaliable until";

  const updateDatesWithNewTimezone = (newTimezone) => {
    let updatedInitial = range.initial
      ? toZonedTime(range.initial, newTimezone)
      : null;
    updatedInitial.setHours(0, 0, 0, 0);
    updatedInitial = fromZonedTime(updatedInitial, newTimezone);

    let updatedFinal = range.final
      ? toZonedTime(range.final, newTimezone)
      : null;
    updatedFinal.setHours(23, 59, 0, 0);
    updatedFinal = fromZonedTime(updatedFinal, newTimezone);

    onChange({
      initial: {
        value: updatedInitial,
        hasErrors: validateDate(true, updatedInitial, updatedFinal) !== null,
      },
      final: {
        value: updatedFinal,
        hasErrors: validateDate(false, updatedFinal, updatedInitial) !== null,
      },
    });
  };


  const handleTimezoneChange = (event) => {
    setSelectedTimezone(event.target.value);
    updateDatesWithNewTimezone(event.target.value);
  };

  const handleDateTimeChanged = (type, newValue) => {
    if (!isDirty[type]) {
      setIsDirty((prevState) => ({ ...prevState, [type]: true }));
    }

    // Convert the selected date to UTC using the selected timezone
    const utcDate = fromZonedTime(newValue, selectedTimezone);

    const rangeToCheck = {
      initial: type === "initial" ? utcDate : range.initial,
      final: type === "final" ? utcDate : range.final,
    };
    const newErrors = findErrorsInRange(rangeToCheck);

    onChange({
      initial: {
        value: type === "initial" ? utcDate : range.initial,
        hasErrors: newErrors.initial !== null,
      },
      final: {
        value: type === "final" ? utcDate : range.final,
        hasErrors: newErrors.final !== null,
      },
    });
  };

  const getMaximumInitialTime = (initialDateTime, finalDateTime) => {
    if (!finalDateTime) return null;

    const zonedInitial = toZonedTime(initialDateTime, selectedTimezone); // Convert from UTC
    const zonedFinal = toZonedTime(finalDateTime, selectedTimezone);

    if (isSameDay(zonedInitial, zonedFinal)) {
      return zonedFinal;
    }

    return null;
  };

  const getMinimumFinalTime = (initialDateTime, finalDateTime) => {
    if (!initialDateTime) {
      return null;
    }

    const zonedInitial = toZonedTime(initialDateTime, selectedTimezone);
    const zonedFinal = toZonedTime(finalDateTime, selectedTimezone);

    if (isSameDay(zonedInitial, zonedFinal)) {
      return zonedInitial;
    }

    return null;
  };

  const maximumInitialTime = getMaximumInitialTime(range.initial, range.final);
  const minimumFinalTime = getMinimumFinalTime(range.initial, range.final);

  const shouldShowError = (error, name) => {
    const hasErrors = error !== null;
    return (isDirty[name] || forceShowErrors) && hasErrors;
  };

  const handleNowCheckboxChange = (event) => {
    setChecked(event.target.checked);

    if (event.target.checked) {
      const now = new Date();
      const zonedNow = toZonedTime(now, selectedTimezone);
      zonedNow.setHours(0, 0, 0, 0);

      handleDateTimeChanged("initial", zonedNow);
    }
  };

  return (
    <>
      <Box>
        <Autocomplete
          disablePortal
          id="timezone-autocomplete"
          options={timezones}
          value={selectedTimezone}
          onChange={(event, newValue) => {
          handleTimezoneChange({ target: { value: newValue } });
        }}
          getOptionLabel={(option) => option.replace(/_/g, " ")}
          renderInput={(params) => <TextField {...params} label="Timezone" />}
        />
      </Box>
      <Grid container size={12} spacing={3} alignItems style={{ paddingTop: 0, marginTop: "15px" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Chip label="From 00:00" style={{ marginBottom: 15 }} />
            <Box display="flex" alignItems="center">
              <FormControlLabel
                style={{ marginRight: 8 }}
                control={<Checkbox checked={checked} onChange={handleNowCheckboxChange} />}
                label="Now"
                labelPlacement="start"
              />
              <DesktopDatePicker
                name="initial"
                className={classes.datetime}
                disablePast
                maxDate={range.final ? toZonedTime(range.final, selectedTimezone) : null}
                maxTime={maximumInitialTime}
                label={initialDateTimeLabel}
                value={range.initial ? toZonedTime(range.initial, selectedTimezone) : null}
                onChange={(newValue) => {
                  const initialDateTime = new Date(newValue);
                  initialDateTime.setHours(0, 0, 0, 0);
                  handleDateTimeChanged("initial", initialDateTime);
                }}
                disabled={checked}
                renderInput={(props) => (
                  <TextField
                    {...props}
                    error={shouldShowError(errors.initial, "initial")}
                    helperText={
                      shouldShowError(errors.initial, "initial")
                        ? errors.initial
                        : ""
                    }
                  />
                )}
              />
            </Box>
            <div className={classes.spaces}> </div>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Chip label="Until 23:59" style={{ marginBottom: 15 }} />
            <DesktopDatePicker
              name="final"
              className={classes.datetime}
              disablePast
              label={finalDateTimeLabel}
              minDate={range.initial ? toZonedTime(range.initial, selectedTimezone) : null}
              minTime={minimumFinalTime}
              value={range.final ? toZonedTime(range.final, selectedTimezone) : null}
              onChange={(newValue) => {
                const finalDateTime = new Date(newValue);
                finalDateTime.setHours(23, 59, 0, 0);
                handleDateTimeChanged("final", finalDateTime);
              }}
              renderInput={(props) => (
                <TextField
                  {...props}
                  error={shouldShowError(errors.final, "final")}
                  helperText={
                    shouldShowError(errors.final, "final") ? errors.final : ""
                  }
                />
              )}
            />
          </Grid>
        </LocalizationProvider>
      </Grid>
    </>
  );
};

DateTimeRange.propTypes = {
  range: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  forceShowErrors: PropTypes.bool.isRequired,
};

export default DateTimeRange;
