const ExamsAvailableStyle = () => ({
  startTag: {
    backgroundColor: "#f7c95e",
    margin: 0,
    padding: "4px",
    position: "relative",
    left: "-12px",
    borderRadius: "4px 0px 5px 0px",
    textAlign: "center",
  },
  examCard: {
    border: "1.5px solid #21646d",
    borderRadius: "5px",
    padding: "0 12px",
    height: "100%",
  },
  expireTag: {
    backgroundColor: "#f55142",
    margin: 0,
    padding: "4px",
    textAlign: "center",
    position: "relative",
    right: "-12px",
    borderRadius: "0px 4px 0px 5px",
  },
});

export default ExamsAvailableStyle;
