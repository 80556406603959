import { useState, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Swal from "sweetalert2";

import { Subtitle, Title } from "components/elements/texts";
import theme from "components/foundations/themes";
import CustomCard from "components/modules/cards";
import MasterPage from "components/modules/masterpage";
import { DifficultySelector } from "components/modules/question/DifficultySelector";
import { backend_client } from "helpers/api";

const convert_difficulty_to_color = (level) => {
  if (level === 1) {
    return "#1ABC9C";
  } else if (level === 2) {
    return "#F1C40F";
  } else if (level === 3) {
    return "#E67E22";
  } else if (level === 4) {
    return "#E74C3C";
  } else {
    return "#7F8C8D";
  }
};

const convert_difficulty_to_string = (level) => {
  if (level === 1) {
    return "Easy";
  } else if (level === 2) {
    return "Medium";
  } else if (level === 3) {
    return "Hard";
  } else if (level === 4) {
    return "Very Hard";
  } else {
    return "Indeterminated";
  }
};

const sanitize_question_type = (question_type) => {
  if (question_type === "single_choice") {
    return "Single Choice";
  } else if (question_type === "multiple_choice") {
    return "Multiple Choice";
  } else if (question_type === "coding") {
    return "Coding";
  } else {
    return "Indeterminated";
  }
};

export default function QuestionSearch() {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState(0);
  const [questionType, setQuestionType] = useState(0);
  const [difficulty, setDifficulty] = useState(2);
  const [isValidated, setIsValidated] = useState(true);
  const [querying, setQuerying] = useState(false);
  const [finishedQuery, setFinishedQuery] = useState(false);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 150,
      renderCell: (params) => (
        <Link href={"/thebai/question-details/" + params.row.id}>
          <b>{params.row.id}</b>
        </Link>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      width: 250,
      renderCell: (params) => sanitize_question_type(params.row.type),
    },
    {
      field: "skill",
      headerName: "Skill",
      width: 250,
      renderCell: (params) =>
        skills.find((skill) => skill.id === params.row.skill_id).name,
      valueGetter: (params) =>
        skills.find((skill) => skill.id === params.row.skill_id).name,
    },
    {
      field: "difficulty",
      headerName: "Difficulty",
      width: 250,
      renderCell: (params) => (
        <Chip
          label={convert_difficulty_to_string(params.row.difficulty)}
          sx={{
            color: "white",
            backgroundColor: convert_difficulty_to_color(params.row.difficulty),
          }}
        />
      ),
    },
    {
      field: "time",
      headerName: "Time (seconds)",
      width: 150,
    },
    {
      field: "created_by",
      headerName: "Created by",
      width: 400,
    },
    {
      field: "is_validated",
      headerName: "Is Validated",
      width: 150,
      renderCell: (params) =>
        params.row.is_validated ? (
          <Icon sx={{ color: "#1ABC9C" }}>check_circle</Icon>
        ) : (
          <Icon sx={{ color: "#E74C3C" }}>cancel</Icon>
        ),
    },
  ];

  const get_skills = () => {
    setLoading(true);
    var url = "/common/skills?return_sorted=true";
    backend_client
      .get(url)
      .then((response) => {
        setSkills(response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "An error occurred 😱",
          text: "Relax, we are working to fix it",
          icon: "error",
          confirmButtonText: "Got it!",
          confirmButtonColor: theme.palette.primary.main,
        });
        console.error(error);
      })
      .then(function () {
        setLoading(false);
      });
  };

  const search_questions = () => {
    setQuerying(true);
    setFinishedQuery(false);
    var url = "/questions/search/";
    var params = {
      difficulty: difficulty,
      is_validated: isValidated,
    };
    if (questionType !== 0) {
      params.question_type = questionType;
    }
    if (selectedSkill !== 0) {
      params.skill_id = selectedSkill;
    }
    backend_client
      .get(url, { params: params })
      .then((response) => {
        setQuestions(response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "An error occurred 😱",
          text: "Relax, we are working to fix it",
          icon: "error",
          confirmButtonText: "Got it!",
          confirmButtonColor: theme.palette.primary.main,
        });
        console.error(error);
      })
      .then(() => {
        setQuerying(false);
        setFinishedQuery(true);
      });
  };

  useEffect(() => {
    get_skills();
  }, []);

  return (
    <MasterPage>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Title value="Questions" />
          <Subtitle value="Search questions" />
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <CustomCard>
                <Grid>
                  <>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontWeight: "600", fontSize: 14 }}>
                          Question Type
                        </Typography>
                        <FormControl size="small" fullWidth>
                          <Select
                            value={questionType}
                            onChange={(event) =>
                              setQuestionType(event.target.value)
                            }
                          >
                            <MenuItem value={0}>All questions</MenuItem>
                            <MenuItem value={"single_choice"}>
                              Single Choice
                            </MenuItem>
                            <MenuItem value={"multiple_choice"}>
                              Multiple Choice
                            </MenuItem>
                            <MenuItem value={"coding"}>Coding</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontWeight: "600", fontSize: 14 }}>
                          Skills
                        </Typography>
                        <FormControl size="small" fullWidth>
                          <Select
                            value={selectedSkill}
                            onChange={(event) =>
                              setSelectedSkill(event.target.value)
                            }
                          >
                            <MenuItem value={0}>All skills</MenuItem>
                            {skills.map((item, key) => (
                              <MenuItem key={key} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography sx={{ fontWeight: "600", fontSize: 14 }}>
                          Validated Questions
                        </Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={isValidated}
                              onChange={() => setIsValidated(!isValidated)}
                            />
                          }
                          label="Is Validated"
                        />
                      </Grid>
                      <DifficultySelector
                        difficulty={difficulty}
                        onDifficultyChange={setDifficulty}
                      />
                    </Grid>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <LoadingButton
                        variant="contained"
                        sx={{ textTransform: "none" }}
                        onClick={() => search_questions()}
                        loading={querying}
                      >
                        Search
                      </LoadingButton>
                    </Box>
                  </>
                </Grid>
                {finishedQuery === true && (
                  <Grid>
                    <br />
                    <div
                      style={{
                        height: "80vh",
                        width: "100%",
                      }}
                    >
                      {questions.length > 0 ? (
                        <DataGrid
                          rows={questions}
                          columns={columns}
                          disableSelectionOnClick
                          components={{
                            Toolbar: GridToolbar,
                          }}
                        />
                      ) : (
                        <Alert variant="filled" severity="warning">
                          No questions found for this filter criteria.
                        </Alert>
                      )}
                    </div>
                  </Grid>
                )}
              </CustomCard>
            </Grid>
          </Grid>
        </>
      )}
    </MasterPage>
  );
}
