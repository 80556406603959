import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  DialogContentText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import EmailsModal from "./EmailsModal";

const ConflictedUsersModal = ({
  onButtonClicked,
  numCreatedInstances,
  conflictedEmails,
}) => {
  const [openEmailsModal, setOpenEmailsModal] = useState(false);
  const theme = useTheme();

  const handleEmailsModalClosed = () => {
    setOpenEmailsModal(false);
  };

  return (
    <>
      <Dialog
        open={conflictedEmails && conflictedEmails.length > 0}
        onClose={onButtonClicked}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h6" component="h2">
            {"Exams instances have been created, but with some conflicts!"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="num-created-instances-description">
            {`Number created instances: ${numCreatedInstances}\n`}
          </DialogContentText>
          <DialogContentText id="num-conflited-users-desciption">
            {`Conflicted users: ${conflictedEmails.length}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <>
            <Button
              onClick={onButtonClicked}
              variant={"contained"}
              style={{
                backgroundColor: theme.palette.primary.main,
                marginLeft: "10px",
                color: "white",
              }}
              size={"medium"}
            >
              {"Continue"}
            </Button>
            <Button
              onClick={() => setOpenEmailsModal(true)}
              variant={"outlined"}
              style={{ marginLeft: "10px" }}
              size={"medium"}
            >
              {"View conflicted emails"}
            </Button>
          </>
        </DialogActions>
      </Dialog>
      <EmailsModal
        isOpen={openEmailsModal}
        emails={conflictedEmails}
        onEmailsModalClosed={handleEmailsModalClosed}
      />
    </>
  );
};

ConflictedUsersModal.propTypes = {
  onButtonClicked: PropTypes.func.isRequired,
  numCreatedInstances: PropTypes.number.isRequired,
  conflictedEmails: PropTypes.array.isRequired,
};

export default ConflictedUsersModal;
