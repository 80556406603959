import PropTypes from "prop-types";
import React from "react";
import { useTheme } from "@mui/material/styles";

const CircularProgressScore = ({radius, stroke, progress, subtitle, foregroundColor, textColor}) => {
  const theme = useTheme();
  const normalizedRadius = radius - (stroke/3) * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - progress / 100 * circumference;
  const textSize = (radius - stroke) / 2.2;
  const textSize2 = (radius - stroke) / 2.8;

  return(
    <svg
      height={radius * 2}
      width={radius * 2}
    >
      <circle
        className='circle'
        stroke="lightgray"
        fill="transparent"
        strokeWidth={ stroke }
        r={ normalizedRadius }
        cx={ radius }
        cy={ radius }
      />
      <circle
        className='circle'
        stroke={foregroundColor ? foregroundColor : theme.palette.primary.main}
        fill="transparent"
        strokeWidth={ stroke }
        strokeDasharray={ circumference + " " + circumference }
        style={ { strokeDashoffset } }
        r={ normalizedRadius }
        cx={ radius }
        cy={ radius }
      />
      <text x='50%' y='50%' textAnchor='middle' fontWeight='bold' fill={textColor ? textColor : theme.palette.primary.main} fontSize={textSize}>{progress}%</text>
      <text dx='50%' dy='65%' textAnchor='middle' fill={textColor ? textColor : theme.palette.primary.main} className='progressSubtitle' fontSize={textSize2}>{subtitle}</text>
    </svg>
  );
};

CircularProgressScore.propTypes = {
  radius: PropTypes.number,
  stroke: PropTypes.number,
  progress: PropTypes.number,
  subtitle: PropTypes.string,
  foregroundColor: PropTypes.string,
  textColor: PropTypes.string
};

export default CircularProgressScore;
