import React from "react";
import ReactDOM from "react-dom";
import { Amplify } from "aws-amplify";
import { BrowserRouter } from "react-router-dom";
import { MathJaxContext } from "better-react-mathjax";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import config from "./constants/config";
import { store, persistor } from "state/store";
import App from "./App";
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <MathJaxContext>
            <App />
          </MathJaxContext>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: {
      responseType: "token", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
});
