import { Auth } from "aws-amplify";
import OnError from "./../libs/errorLib";

const RefreshSessionCallback = async (callback) => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(
      currentSession.refreshToken,
      async (err, session) => {
        callback(session);
      }
    );
  } catch (e) {
    console.warn(e);
    OnError();
  }
};

const RefreshSession = async () => {
  return new Promise(function (resolve, reject) {
    RefreshSessionCallback(resolve);
  });
};

export default RefreshSession;
