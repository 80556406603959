import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import Swal from "sweetalert2";

import { Auth } from "aws-amplify";

import { Paths } from "../../../Routes";
import theme from "../../../components/foundations/themes";
import CustomCard from "../../../components/modules/cards";
import { useAppContext } from "../../../libs/contextLib";
import FactoredLogo from "../../../assets/images/factored_logo.png";

import SendCodeForm from "./SendCodeForm";
import ConfirmCodeForm from "./ConfirmCodeForm";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      align="center"
      {...props}
      style={{ color: theme.palette.grey[400] }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://factored.ai/">
        Factored
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const RecoverPassword = () => {
  const [email, setEmail] = useState("");
  const [hasSentCode, setHasSentCode] = useState(false);

  const { onAuthStateChange } = useAppContext();
  const navigate = useNavigate();

  const showError = (message) => {
    return Swal.fire({
      title: "Error",
      text: message,
      icon: "error",
      confirmButtonText: "Got it!",
      confirmButtonColor: theme.palette.primary.main,
    });
  };

  const showSuccess = (message) => {
    return Swal.fire({
      title: "Success",
      text: message,
      icon: "success",
      confirmButtonText: "Got it!",
      confirmButtonColor: theme.palette.primary.main,
    });
  };

  const handleSendCode = async (email) => {
    Auth.forgotPassword(email).then(() => {
      setEmail(email);
      setHasSentCode(true);
    });
  };

  const handleChangePasswordWithCode = (email, code, newPassword) => {
    Auth.forgotPasswordSubmit(email, code, newPassword)
      .then(() => {
        onAuthStateChange(false);
        Auth.signOut();
        showSuccess("Your password has been changed successfully.").then(() => {
          navigate(Paths.LOGIN);
        });
      })
      .catch((e) => {
        if (e.message) {
          showError(e.message);
        } else {
          showError(
            "Failed to change password. Please try again or contact support."
          );
        }
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CustomCard>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={FactoredLogo} style={{ width: "50%" }} />
                {hasSentCode ? (
                  <ConfirmCodeForm
                    email={email}
                    onChangePasswordWithCode={handleChangePasswordWithCode}
                  />
                ) : (
                  <SendCodeForm
                    email={email}
                    onEmailChange={setEmail}
                    onHandleSendCode={handleSendCode}
                  />
                )}
              </Box>
            </CustomCard>

            <Grid container sx={{ mt: 1 }}>
              <Grid item xs>
                <Link
                  href={Paths.FORGOT_PASSWORD}
                  variant="body2"
                  style={{ color: "white" }}
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default RecoverPassword;
