import axios from 'axios'
import Refresh from './../utils/currentSession'
import { url_dev, url_certificate } from '../constants/const'

const universalHeaders = { Authorization: "" }

const get_headers = async () => {
    const session = await Refresh()
    universalHeaders.Authorization = session.idToken.jwtToken
    const headers = {}
    const headers_2 = { ...headers, ...universalHeaders }
    return headers_2
}

const backend_client = axios.create({
    baseURL: url_dev,
})

backend_client.interceptors.request.use(
    async (config) => {
        const token_new = await get_headers()
        config.headers = token_new
        return config
})

export {backend_client}