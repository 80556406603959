import React, { useState } from "react";
import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { Title, Subtitle } from "../../components/elements/texts";
import ExamDesignSelector from "./components/ExamDesignSelector";
import CustomCard from "../../components/modules/cards";
import MasterPage from "../../components/modules/masterpage";
import EmailList from "./components/EmailList";
import EmailsValidator from "./components/EmailsValidator";
import TagsPicker from "./components/TagsPicker";
import DateTimeRange from "./components/DateTimeRange";
import theme from "../../components/foundations/themes";
import ConflictedUsersModal from "./components/ConflictedUsersModal";
import CustomModal from "../../components/modules/modals";

import { useFindAllExamDesigns } from "../../api/exam/useFindAllExamDesigns";
import useCreateExamInstances from "../../api/exam/useCreateExamInstances";
import LoadingButton from "@mui/lab/LoadingButton";

const InitialFetchResultState = {
  hasFetched: false,
  hasError: false,
  detail: null,
  conflicted: [],
  numCreated: 0,
};

const defaultDateRange = {
  initial: {
    value: null,
    hasErrors: true,
  },
  final: {
    value: null,
    hasErrors: true,
  },
};

const defaultExamDesign = {
  value: null,
  hasErrors: true,
};

const defaultNonValidatedText = {
  value: "",
  hasErrors: false,
};

const ExamInstancesCreator = () => {
  const [hasClickedSubmit, setHasClickedSubmit] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [examDesign, setExamDesign] = useState(defaultExamDesign);
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [emails, setEmails] = useState([]);
  const [nonValidatedText, setNonValidatedText] = useState(
    defaultNonValidatedText
  );
  const [selectedTags, setSelectedTags] = useState([]);
  const [fetchResults, setFetchResults] = useState(InitialFetchResultState);
  const [customModalData, setCustomModalData] = useState({
    open: false,
    onPrimaryButtonClick: () => {},
  });

  const { examDesigns, isLoading: isLoadingExamDesigns } =
    useFindAllExamDesigns(true);

  const { handleCreateExamInstances } = useCreateExamInstances();

  const hasValidDateRange = () =>
    !dateRange.initial.hasErrors || !dateRange.final.hasErrors;

  const hasValidEmails = () => emails.length > 0;

  const nonValidatedTextIsEmpty = () => nonValidatedText.value.length === 0;

  const hasValidSelectedExamDesign = () => examDesign.value !== null;

  const hasErrors = () =>
    !hasValidDateRange() ||
    !hasValidEmails() ||
    !hasValidSelectedExamDesign() ||
    !nonValidatedTextIsEmpty();

  const handleEmailRemoved = (email) => {
    setEmails(emails.filter((e) => e !== email));
    const newNonValidatedText =
      nonValidatedText.value.length > 0
        ? nonValidatedText.value + "\n" + email
        : email;
    setNonValidatedText({
      value: newNonValidatedText,
      hasErrors: newNonValidatedText.length > 0,
    });
  };

  const handleEmailEdited = (email) => {
    setEmails(emails.filter((e) => e !== email));
  };

  const isSubmitDisabled = () => {
    return hasClickedSubmit && hasErrors();
  };

  const handleSubmitClicked = async (event) => {
    event.preventDefault();
    setHasClickedSubmit(true);
    if (hasErrors()) return;

    setIsCreating(true);
    try {
      const { result, error, hasFinished } = await handleCreateExamInstances({
        examDesign: examDesign.value,
        initialDate: dateRange.initial.value,
        finalDate: dateRange.final.value,
        emails,
        selectedTags,
      });

      if (error !== null) {
        return;
      }

      resetForm();

      if (result.conflicted_users.length > 0) {
        setFetchResults({
          hasFetched: hasFinished,
          hasError: error !== null,
          conflicted: result.conflicted_users,
          numCreated: result.exam_instance_ids.length,
          detail: null,
        });
      } else {
        setCustomModalData(
          buildModalData(false, result.exam_instance_ids.length, null)
        );
      }
    } finally {
      setIsCreating(false);
    }
  };

  const resetForm = () => {
    setHasClickedSubmit(false);
    setIsCreating(false);
    setExamDesign(defaultExamDesign);
    setDateRange(defaultDateRange);
    setEmails([]);
    setNonValidatedText(defaultNonValidatedText);
    setSelectedTags([]);
  };

  const handleModalClosed = () => {
    if (shouldShowConflictedModal()) {
      setFetchResults(InitialFetchResultState);
      return;
    }
    setCustomModalData(buildModalData(true, null, null));
  };

  const buildModalData = (hideModal, numInstancesCreated) => {
    if (hideModal) {
      return { open: false };
    }
    const title = "Success";
    const body = `${numInstancesCreated} instances has been created`;

    return {
      open: true,
      title,
      body,
      textPrimaryButton: "Ok",
      onPrimaryButtonClick: handleModalClosed,
    };
  };

  const shouldShowConflictedModal = () => {
    return fetchResults.hasFetched && fetchResults.conflicted.length > 0;
  };

  return (
    <MasterPage>
      <>
        <Title value="Invite Participants" />
        <Subtitle value="Select an exam and invite participants" />
        <br />
        <CustomCard>
          <Grid container>
            <Grid container size={12} spacing={3}>
              <Grid size={{ xs: 12, sm: 6 }}>
                <ExamDesignSelector
                  value={examDesign}
                  examDesigns={examDesigns}
                  isLoading={isLoadingExamDesigns}
                  onChange={setExamDesign}
                  forceShowErrors={hasClickedSubmit}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <TagsPicker
                  tags={selectedTags}
                  onSelectedTagsChanged={setSelectedTags}
                />
              </Grid>
              <Grid size={12}>
                <DateTimeRange
                  range={{
                    initial: dateRange.initial.value,
                    final: dateRange.final.value,
                  }}
                  onChange={setDateRange}
                  forceShowErrors={hasClickedSubmit}
                />
              </Grid>
            </Grid>

            <Grid size={12}>
              <Divider />
              <EmailsValidator
                emails={emails}
                onEmailsChanged={setEmails}
                text={nonValidatedText.value}
                onTextChanged={setNonValidatedText}
                forceShowErrors={hasClickedSubmit}
              />
              <EmailList
                emails={emails}
                onEmailRemoved={handleEmailRemoved}
                onEmailEdited={handleEmailEdited}
                forceShowErrors={hasClickedSubmit}
              />
            </Grid>
            <Grid size={12} textAlign="center">
              <LoadingButton
                loading={isCreating}
                variant="contained"
                style={{
                  backgroundColor: theme.palette.primary.main,
                }}
                onClick={handleSubmitClicked}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Grid>
          <CustomModal {...customModalData} />
          <ConflictedUsersModal
            isOpen={
              fetchResults.hasFetched && fetchResults.conflicted.length > 0
            }
            conflictedEmails={fetchResults.conflicted}
            numCreatedInstances={fetchResults.numCreated}
            onButtonClicked={handleModalClosed}
          />
        </CustomCard>
      </>
    </MasterPage>
  );
};

export default ExamInstancesCreator;
