import axiosInstanceBuilder from "../config/axios/axiosInstanceBuilder";
import { useFetch } from "../config/axios/useFetch";

const baseUrl = process.env.REACT_APP_API_DEV_URL;
const endpoint = "/users/role";

const createAxiosPromise = () => {
  return axiosInstanceBuilder(baseUrl).get(endpoint);
};

export const useAuthenticatedUserRole = (callAfterMount = false) => {
  const { data, isPending, error, fetchApi } = useFetch(
    createAxiosPromise,
    callAfterMount
  );

  const getAuthenticatedUserRole = async () => {
    const response = await fetchApi();
    const { data, ...rest } = response;
    return { userRole: data.user_role, ...rest };
  };

  return {
    userRole: data,
    isLoading: isPending,
    error,
    getAuthenticatedUserRole,
  };
};
