import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";

import theme from "../../components/foundations/themes";
import { ReactComponent as NotFoundImg } from "../../assets/images/404.svg"

export default function NotFound() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <NotFoundImg
              style={{
                width: "80%",
                position: "absolute",
                top: 0,
                bottom: 0,
                margin: "auto",
              }}
            />
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
