import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Switch,
  Tab,
  TextField,
  Typography,
  Box,
  Collapse,
  Tooltip,
} from "@mui/material";
import { AddCircleOutlineOutlined } from "@material-ui/icons";
import AceEditor from "react-ace";
import "ace-builds";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-solarized_light";
import "ace-builds/src-noconflict/theme-solarized_dark";
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import Ajv from "ajv";
import Swal from "sweetalert2";
import { useFindCodingLanguages } from "api/common/skills/useFindCodingLanguages";
import TestCase from "./TestCase";
import PropTypes from "prop-types";
import {
  updateTestCasesEvaluationState,
  STATES,
} from "state/slices/questionDesignSlice";

const QuestionCodeEditor = ({ handleSetCodingOptions, codingOptions }) => {
  const dispatch = useDispatch();

  const { languages } = useFindCodingLanguages(true);

  const theme = useTheme();
  const darkMode = useSelector((state) => state.user.darkMode);
  const questionDesign = useSelector((state) => state.questionDesign);

  const [codeMode, setCodeMode] = useState(codingOptions["codeMode"]);
  const [allowRunningTestCases, setAllowRunningTestCases] = useState(
    codingOptions["allowRunningTestCases"]
  );
  const [informsTestCaseCorrectness, setInformsTestCaseCorrectness] = useState(
    codingOptions["informsTestCaseCorrectness"]
  );
  const [allowedLanguages, setAllowedLanguages] = useState(
    codingOptions["allowedLanguages"]
  );
  const [solutionCode, setSolutionCode] = useState(
    codingOptions["solutionCode"]
  );
  const [testCases, setTestCases] = useState(codingOptions["testCases"]);
  const [outPutLines, setOutPutLines] = useState(codingOptions["outPutLines"]);
  const [useCodeWrapper, setUseCodeWrapper] = useState(
    codingOptions["useCodeWrapper"]
  );
  const [customCodeWrapper, setCustomCodeWrapper] = useState(
    codingOptions["customCodeWrapper"]
  );
  const [jsonValue, setJsonValue] = useState(
    JSON.stringify({ test_cases: testCases }, null, 2)
  );
  const [isJsonValid, setIsJsonValid] = useState(true);

  const [currentTab, setCurrentTab] = useState("visual-editor");
  const [showAddButton, setShowAddButton] = useState(true);

  useEffect(() => {
    setShowAddButton(testCases.length < 5);
  }, [testCases]);

  useEffect(() => {
    dispatch(
      updateTestCasesEvaluationState(
        Array(codingOptions["testCases"].length).fill(STATES.NULL)
      )
    );
  }, []);

  const handleTabChange = (event, newTab) => {
    if (isJsonValid) {
      setCurrentTab(newTab);
    } else {
      Swal.fire({
        title: "There was an error parsing the JSON",
        text: "Please check your syntax and make sure that it is compliant with the provided schema",
        icon: "error",
        confirmButtonText: "Got it!",
        confirmButtonColor: theme.palette.primary.main,
      });
    }
  };

  const handleAddTestCase = () => {
    const newTestCases = [
      ...testCases,
      { stdin: "", expected_output: "", weight: 1 },
    ];
    setTestCases(newTestCases);
    setJsonValue(JSON.stringify({ test_cases: newTestCases }, null, 2));

    const tcStates = [...questionDesign.testCasesEvaluationState, STATES.NULL];
    dispatch(updateTestCasesEvaluationState(tcStates));
  };

  const handleJsonSelection = (value) => {
    setJsonValue(value);
    try {
      const parsed_json = JSON.parse(value);
      if (validate(parsed_json)) {
        setIsJsonValid(true);
        setTestCases(parsed_json["test_cases"]);
      } else {
        setIsJsonValid(false);
      }
    } catch (error) {
      setIsJsonValid(false);
    }
  };

  const handleRemoveTestCase = (index) => {
    const newTestCases = [...testCases];
    newTestCases.splice(index, 1);
    setTestCases(newTestCases);
    setJsonValue(JSON.stringify({ test_cases: newTestCases }, null, 2));

    const tcStates = Array(newTestCases.length).fill(STATES.NULL);
    dispatch(updateTestCasesEvaluationState(tcStates));
  };

  const handleOnChangeTestCase = (event, index) => {
    const { name, value } = event.target;

    const newTestCases = [...testCases];
    if (name === "weight") {
      newTestCases[index][name] = Number(value);
    } else {
      newTestCases[index][name] = value;
    }
    setTestCases(newTestCases);
    setJsonValue(JSON.stringify({ test_cases: newTestCases }, null, 2));
  };

  const ajv = new Ajv();

  const schema = {
    $schema: "http://json-schema.org/draft-07/schema#",
    type: "object",
    properties: {
      test_cases: {
        type: "array",
        items: {
          type: "object",
          properties: {
            stdin: {
              type: "string",
            },
            expected_output: {
              type: "string",
            },
            weight: {
              type: "number",
            },
          },
          required: ["stdin", "expected_output", "weight"],
          additionalProperties: false,
        },
      },
    },
    required: ["test_cases"],
    additionalProperties: false,
  };

  const validate = ajv.compile(schema);

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;

  useEffect(() => {
    handleSetCodingOptions({
      allowRunningTestCases: allowRunningTestCases,
      allowedLanguages: allowedLanguages,
      informsTestCaseCorrectness: informsTestCaseCorrectness,
      codeMode: codeMode,
      solutionCode: solutionCode,
      testCases: testCases,
      outPutLines: outPutLines,
      customCodeWrapper: customCodeWrapper,
      useCodeWrapper: useCodeWrapper,
    });

    allowedLanguages.length > 1 && setUseCodeWrapper(false);
  }, [
    allowRunningTestCases,
    allowedLanguages,
    informsTestCaseCorrectness,
    codeMode,
    solutionCode,
    testCases,
    outPutLines,
    customCodeWrapper,
    useCodeWrapper,
  ]);

  useEffect(() => {
    languages.length > 0 &&
      languages.forEach((lang) => {
        require(`ace-builds/src-noconflict/mode-${lang}`);
        require(`ace-builds/src-noconflict/snippets/${lang}`);
      });
  }, [languages]);

  return (
    <Grid container spacing={3} paddingTop={3}>
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={
            <Switch
              checked={allowRunningTestCases}
              onChange={() => setAllowRunningTestCases(!allowRunningTestCases)}
            />
          }
          label="Allow the candidate to run the test cases"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl size="small" fullWidth>
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={languages}
            disableCloseOnSelect
            getOptionLabel={(option) => option}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                  value={option}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Allowed Languages" />
            )}
            onChange={(event, value) => {
              setAllowedLanguages(
                typeof value === "string" ? value.split(",") : value
              );
            }}
            value={allowedLanguages}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControlLabel
          control={
            <Switch
              checked={informsTestCaseCorrectness}
              onChange={() =>
                setInformsTestCaseCorrectness(!informsTestCaseCorrectness)
              }
            />
          }
          label="Display the correctness of the test cases to the candidate"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl size="small" fullWidth>
          <Autocomplete
            disablePortal
            id="code-solution-select"
            options={languages}
            onChange={(event, value) => {
              setCodeMode(value);
            }}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Solution Code Language" />
            )}
            value={codeMode}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Tooltip title="You can only use Custom wrappers when there is just one language allowed">
          <FormControlLabel
            control={
              <Switch
                checked={useCodeWrapper}
                onChange={() => setUseCodeWrapper(!useCodeWrapper)}
                disabled={allowedLanguages.length > 1}
              />
            }
            label="This question has a custom code wrapper"
          />
        </Tooltip>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={useCodeWrapper}>
          <Typography sx={{ fontWeight: "600", fontSize: 16, mb: 3 }}>
            Custom Code Wrapper
          </Typography>
          <AceEditor
            fontSize={16}
            key={"code-wrapper"}
            name={"code-wrapper"}
            mode={codeMode}
            value={customCodeWrapper}
            theme={darkMode ? "solarized_dark" : "solarized_light"}
            onChange={(value) => setCustomCodeWrapper(value)}
            width="78%"
          />
        </Collapse>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: "600", fontSize: 16, mb: 3 }}>
          Solution Code
        </Typography>
        <AceEditor
          fontSize={16}
          key={"solution-code"}
          name={"solution-code"}
          mode={codeMode}
          value={solutionCode}
          theme={darkMode ? "solarized_dark" : "solarized_light"}
          onChange={(value) => setSolutionCode(value)}
          width="100%"
        />
      </Grid>
      <Grid item container xs={12}>
        <Box width={"40%"} my={3}>
          <Typography sx={{ fontWeight: "600", fontSize: 16, mb: 2 }}>
            Output Lines per Test Case
          </Typography>
          <TextField
            variant="outlined"
            size="small"
            type="number"
            min={1}
            fullWidth
            value={outPutLines}
            onChange={(event) => setOutPutLines(event.target.value)}
            inputProps={{ max: 999, min: 1, maxLength: 3 }}
            required
          />
        </Box>
        <Grid item xs={12}>
          <TabContext value={currentTab}>
            <TabList onChange={handleTabChange}>
              <Tab label={"Visual Editor"} value={"visual-editor"} />
              <Tab label={"JSON"} value={"json"} />
            </TabList>
            <TabPanel value={"visual-editor"}>
              {testCases.map((testCase, index) => (
                <TestCase
                  key={index}
                  onRemove={() => handleRemoveTestCase(index)}
                  onChange={(e) => handleOnChangeTestCase(e, index)}
                  index={index}
                  codingOptions={codingOptions}
                />
              ))}
              {showAddButton && (
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <IconButton
                    edge="start"
                    size="small"
                    sx={{
                      color: theme.palette.primary.main,
                      marginTop: 3,
                    }}
                    onClick={handleAddTestCase}
                  >
                    <AddCircleOutlineOutlined />
                  </IconButton>
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: 14,
                    }}
                  >
                    Add test case
                  </Typography>
                </Stack>
              )}
            </TabPanel>
            <TabPanel value={"json"}>
              <AceEditor
                key={"json-editor"}
                name={"json-editor"}
                mode={"json"}
                value={jsonValue}
                theme={darkMode ? "solarized_dark" : "solarized_light"}
                width="100%"
                onChange={(value) => handleJsonSelection(value)}
              />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </Grid>
  );
};

QuestionCodeEditor.propTypes = {
  handleSetCodingOptions: PropTypes.func.isRequired,
  codingOptions: PropTypes.object.isRequired,
};

export default QuestionCodeEditor;
