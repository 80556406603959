import axiosInstanceBuilder from "../../config/axios/axiosInstanceBuilder";
import { mapToEmptyArrayIfNull } from "../../config/axios/utils/mapToEmpyArrayIfNull";
import { useFetch } from "../../config/axios/useFetch";

const baseUrl = process.env.REACT_APP_API_DEV_URL;
const endpoint = "/common/skills?return_sorted=true";

const createAxiosPromise = () => {
  return axiosInstanceBuilder(baseUrl).get(endpoint);
};

export const useFindAllSkills = (callAfterMount = false) => {
  const { data, isPending, error, fetchApi } = useFetch(
    createAxiosPromise,
    callAfterMount
  );

  const findAllSkills = async () => {
    const response = await fetchApi();
    const { data, ...rest } = response;
    return { skills: mapToEmptyArrayIfNull(data), ...rest };
  };

  return {
    skills: mapToEmptyArrayIfNull(data),
    isLoading: isPending,
    error,
    findAllSkills,
  };
};
