import { useEffect } from "react";
import PropTypes from "prop-types";
import { MathJax } from "better-react-mathjax";
import { Box, Checkbox, Radio, Stack, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

import Statement from "./Statement";
import QuestionCodeEditor from "./QuestionCodeEditor";

const QuestionDetail = (props) => {
  const { selectedOptions, onChange, language } = props;

  const theme = useTheme();
  const currentQuestion = useSelector((state) => state.question.question);
  const isSingleChoice =
    currentQuestion.type === "single_choice" ? true : false;

  const QuestionOptions = () => {
    const sortedOptions = [...currentQuestion.choices];
    sortedOptions.sort((optionA, optionB) => {
      if (optionA.fixed_position < optionB.fixed_position) {
        return -1;
      } else if (optionA.fixed_position > optionB.fixed_position) {
        return 1;
      } else {
        return 0;
      }
    });

    return (
      <Box>
        {sortedOptions.map((questionOption) => (
          <Box key={questionOption.id}>
            <Box sx={{ background: "white", borderRadius: "1rem" }}>
              <Stack direction="row" alignItems="center">
                {isSingleChoice ? (
                  <Radio
                    checked={selectedOptions.includes(questionOption.id)}
                    onChange={() =>
                      onChange(
                        questionOption.id,
                        isSingleChoice ? "radio" : "checkbox"
                      )
                    }
                  />
                ) : (
                  <Checkbox
                    checked={selectedOptions.includes(questionOption.id)}
                    onChange={() =>
                      onChange(
                        questionOption.id,
                        isSingleChoice ? "radio" : "checkbox"
                      )
                    }
                  />
                )}
                <MathJax>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: questionOption.text,
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                </MathJax>
              </Stack>
            </Box>
            <br />
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <>
      <Statement />
      <Divider color={theme.palette.primary.main} />
      <Box
        style={{
          marginTop: "20px",
        }}
      >
        {currentQuestion.type === "coding" ? (
          <QuestionCodeEditor />
        ) : (
          <QuestionOptions />
        )}
      </Box>
    </>
  );
};

QuestionDetail.propTypes = {
  selectedOptions: PropTypes.array,
  onChange: PropTypes.func,
  language: PropTypes.string,
};

export default QuestionDetail;
