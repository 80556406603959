import axiosInstanceBuilder from "../config/axios/axiosInstanceBuilder";
import { useFetch } from "../config/axios/useFetch";

/**
 * @typedef {Object} FetchResponse
 * @property {any} data
 * @property {Error|null} error
 * @property {boolean} isPending
 * @property {boolean} hasFinished
 */

/**
 * @enum {string}
 */
const ProgrammingLanguage = {
  C_CPP: "c_cpp",
  Csharp: "csharp",
  Golang: "golang",
  Javascript: "javascript",
  Kotlin: "kotlin",
  Php: "php",
  Python: "python",
  Ruby: "ruby",
  Scala: "scala",
  Sh: "sh",
  Sql: "sql",
};

/**
 * @typedef {Object} CodingQuestionTestCaseRequestModel
 * @property {string} stdin
 * @property {string} expected_output
 * @property {number} weight
 */

/**
 * @typedef {Object} CodeDesignRun
 * @property {ProgrammingLanguage} language
 * @property {string} [custom_code_wrapper]
 * @property {string} solution_code
 * @property {CodingQuestionTestCaseRequestModel} test_case
 * @property {number} output_lines_per_test_case
 */

/**
 * @typedef {Object} CodeDesignRunResponse
 * @property {boolean} passed_test_cases
 */

const baseUrl = process.env.REACT_APP_API_DEV_URL;
const endpoint = "/questions/design-run-code";

/**
 * @param {CodeDesignRun} data
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
const createAxiosPromise = (data) => {
  return axiosInstanceBuilder(baseUrl).post(endpoint, data);
};

/**
 * @returns {{
 *   handleRunDesignCode: (params: CodeDesignRun) => Promise<CodeDesignRunResponse>
 * }}
 */
export const useRunDesignCode = () => {
  const { fetchApi } = /** @type {{ fetchApi: (params: CodeDesignRun) => Promise<CodeDesignRunResponse> }} */ (useFetch(createAxiosPromise, false));

  /**
   * @param {CodeDesignRun} params
   * @returns {Promise<CodeDesignRunResponse>}
   */
  const handleRunDesignCode = async (params) => {
    const response = await fetchApi(params);
    return response.data;
  };

  return { handleRunDesignCode };
};
