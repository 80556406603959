import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";

import theme from "components/foundations/themes";
import QuestionForm from "components/modules/question/QuestionForm";
import { useFindQuestionById } from "api/question/useFindQuestionById";
import { useUpdateQuestion } from "api/question/useUpdateQuestion";

const QuestionEditor = () => {
  const navigate = useNavigate();
  const { question_id: questionId } = useParams();
  const [isUpdating, setIsUpdating] = useState(false);

  const { question } = useFindQuestionById(questionId, true);
  const { handleUpdateQuestion } = useUpdateQuestion(questionId);

  const handleSubmit = async (question) => {
    setIsUpdating(true);
    handleUpdateQuestion(question)
      .then(({ id }) => {
        Swal.fire({
          title: "Question saved",
          text: `The Question with ID: ${id} has been updated`,
          confirmButtonText: "Continue",
          confirmButtonColor: theme.palette.primary.main,
        }).then(() => {
          navigate(`/thebai/question-details/${id}`);
        });
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  return (
    <>
      {question === null || isUpdating ? (
        <CircularProgress />
      ) : (
        <QuestionForm
          title="Question Editor"
          subtitle="Edit your questions using this form"
          question={question}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default QuestionEditor;
