import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Editor } from "@tinymce/tinymce-react";
import { MathJax } from "better-react-mathjax";

const CustomEditor = ({ languages, statement, onStatementChange }) => {
  const editorRef = useRef(null);
  const formatedLanguages = languages.map((l) => ({
    text: l,
    value: l,
  }));

  const codeConfig = {
    type: "textarea",
    name: "code",
    label: "Text: ",
    enabled: true,
    maximized: false,
  };

  const canEditCheckboxConfig = {
    type: "checkbox",
    name: "allow_edit",
    label: "Examinee can edit",
    enabled: true,
  };

  const canPasteCheckboxConfig = {
    type: "checkbox",
    name: "allow_paste",
    label: "Examinee can paste",
    enabled: true,
  };

  const canCopyCheckboxConfig = {
    type: "checkbox",
    name: "allow_copy",
    label: "Examinee can copy",
    enabled: true,
  };

  const buildLanguageListConfig = (languages) => ({
    type: "listbox",
    name: "language",
    label: "Language",
    enabled: true,
    items: languages,
  });

  const buildDialogConfig = (languages) => ({
    title: "Add code editors",
    body: {
      type: "panel",
      items: [
        canEditCheckboxConfig,
        canCopyCheckboxConfig,
        canPasteCheckboxConfig,
        buildLanguageListConfig(languages),
        codeConfig,
      ],
    },
    onSubmit: (e) => {
      handleSubmit(e.getData());
    },
    buttons: [
      {
        type: "submit",
        text: "OK",
      },
    ],
  });

  const handleSubmit = (editor) => {
    if (editorRef.current) {
      const currentEditor = editorRef.current;
      const codeEditorHtml =
        "<pre class='code_editor'" +
        ` data-language=${editor.language}` +
        ` data-allow-edit=${editor.allow_edit}` +
        ` data-allow-paste=${editor.allow_paste}` +
        ` data-allow-copy=${editor.allow_copy}>` +
        `${editor.code} </pre>`;
      currentEditor.insertContent(codeEditorHtml);
      currentEditor.windowManager.close();
    }
  };

  const handleAddCodeEditorButtonClicked = () => {
    if (editorRef.current) {
      const editor = editorRef.current;
      editor.windowManager.open(buildDialogConfig(formatedLanguages));
    }
  };

  return (
    <>
      <MathJax>
        <Editor
          //Use this API Key just for development, for deployment please register in https://www.tiny.cloud/auth/signup/
          id="question_editor"
          apiKey="zb5p1n1wg7av2w0rdu6740e3gmrmofytghwmt7stx072bxfn"
          value={statement}
          onEditorChange={(content) => onStatementChange(content)}
          onInit={(evt, editor) => (editorRef.current = editor)}
          init={{
            branding: false,
            height: 500,
            menubar: false,
            setup: function (editor) {
              editor.ui.registry.addButton("codeeditor", {
                text: "<strong>Add Code Editor</strong>",
                onAction: handleAddCodeEditorButtonClicked,
              });
            },
            plugins: [
              "advlist autolink lists link image imagetools charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount codesample",
            ],
            toolbar:
              "undo redo | " +
              "bold italic | " +
              "outdent indent | bullist numlist | " +
              "table image codesample" +
              "| codeeditor",
            font_formats: "Roboto=roboto",
            content_style:
              "@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); body { font-family: 'Roboto', sans-serif; color: #092C4C }",

            skin: "bootstrap",
            htmlAllowedTags: [".*"],
            htmlAllowedAttrs: [".*"],
            draggable_modal: true,
            image_title: true,
            automatic_uploads: true,
            image_dimensions: false,
            file_picker_types: "image",
            file_picker_callback: function (cb) {
              var input = document.createElement("input");
              input.setAttribute("type", "file");
              input.setAttribute("accept", "image/*");
              input.onchange = function () {
                var file = this.files[0];

                var reader = new FileReader();
                reader.onload = function () {
                  var id = "blobid" + new Date().getTime();
                  var blobCache =
                    window.tinymce.activeEditor.editorUpload.blobCache;
                  var base64 = reader.result.split(",")[1];
                  var blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);
                  cb(blobInfo.blobUri(), { title: file.name });
                };
                reader.readAsDataURL(file);
              };
              input.click();
            },
          }}
        />
      </MathJax>
    </>
  );
};

CustomEditor.propTypes = {
  languages: PropTypes.array.isRequired,
  statement: PropTypes.string.isRequired,
  onStatementChange: PropTypes.func.isRequired,
};

export default CustomEditor;
