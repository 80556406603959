import React from "react";
import PropTypes from "prop-types";
import { IconButton, Typography, Stack, Box } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import { IntegerInputRange, CustomizedAutocomplete } from "../../elements";

const styles = {
  box: {
    display: "flex",
    border: "1px",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid e0e0e0",
    paddingX: "16px",
    background: "#f5f5f5",
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
    height: 30,
    fontWeight: "600",
  },
  iconButton: {
    padding: 0,
  },
};

const DifficultyDistribution = ({
  title,
  selectedDifficulty,
  distribution,
  difficulties,
  disabledDifficulties,
  onChange,
  canBeRemoved,
  onRemove,
  forceShowErrors,
}) => {
  const hasAnyError = (distribution) => {
    const keys = Object.keys(distribution);
    const errors = keys.map((k) => distribution[k].hasErrors);
    return errors.some((e) => e);
  };

  const handleChange = ({ name, value, hasErrors }) => {
    const newDistribution = {
      ...distribution,
      [name]: { value, hasErrors },
    };
    const anyError = hasAnyError(newDistribution);
    onChange({ ...newDistribution, hasErrors: anyError });
  };

  const handleDifficultyChange = ({ value, hasErrors }) => {
    const newDifficulty = { value: value ? value : "", hasErrors };
    const newDistribution = { ...distribution, difficulty: newDifficulty };
    const anyError = hasAnyError(newDistribution);
    onChange({ ...newDistribution, hasErrors: anyError });
  };

  return (
    <Stack spacing={2} border={1}>
      <Box style={styles.box}>
        <Typography style={styles.title}>{title}</Typography>
        {canBeRemoved ? (
          <IconButton onClick={onRemove} sx={{ color: "red" }}>
            <DeleteOutlinedIcon />{" "}
          </IconButton>
        ) : null}
      </Box>
      <Stack direction="row" spacing={1} paddingX={3} paddingY={2}>
        <IntegerInputRange
          integer={distribution.numQuestions}
          name="numQuestions"
          label="Number of questions"
          min={1}
          forceShowErrors={forceShowErrors}
          onChange={handleChange}
        />
        <CustomizedAutocomplete
          selectedOption={selectedDifficulty}
          label="Difficulty"
          placeholder="Select one difficulty"
          options={difficulties}
          disabledOptions={disabledDifficulties}
          onChange={handleDifficultyChange}
          isLoading={false}
          forceShowErrors={forceShowErrors}
        />
        <IntegerInputRange
          integer={distribution.weight}
          name="weight"
          label="Weight"
          min={1}
          max={32767}
          forceShowErrors={forceShowErrors}
          onChange={handleChange}
        />
      </Stack>
    </Stack>
  );
};

DifficultyDistribution.propTypes = {
  title: PropTypes.string.isRequired,
  selectedDifficulty: PropTypes.object.isRequired,
  distribution: PropTypes.object.isRequired,
  difficulties: PropTypes.array.isRequired,
  disabledDifficulties: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  canBeRemoved: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  forceShowErrors: PropTypes.bool.isRequired,
};
export default DifficultyDistribution;
