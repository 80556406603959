import React, { useState } from "react";
import {
  CircularProgress,
  Stack,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { AddCircleOutlineOutlined } from "@material-ui/icons";
import { v4 } from "uuid";

import CustomCard from "../../components/modules/cards";
import MasterPage from "../../components/modules/masterpage";
import { Title, Subtitle } from "../../components/elements/texts";

import { DesignNameTextField, ExamSection } from "./components";
import { cloneObject } from "./utils/cloneObject";
import { useFindAllSkills } from "../../api/common/skills/useFindAllSkills";
import { useCreateExamDesign } from "../../api/exam/useCreateExamDesign";

import theme from "../../components/foundations/themes";
import CustomModal from "../../components/modules/modals";

const defaultDistribution = {
  numQuestions: {
    value: "",
    hasErrors: true,
  },
  difficulty: {
    value: "",
    hasErrors: true,
  },
  weight: {
    value: "",
    hasErrors: true,
  },
  hasErrors: true,
  id: v4(),
};

const defaultSection = {
  skill: { value: null, hasErrors: true },
  distributions: [{ ...defaultDistribution }],
  hasErrors: true,
  id: v4(),
};

const defaultName = { value: "", hasErrors: true };

const ExamDesignCreator = () => {
  const [sections, setSections] = useState([cloneObject(defaultSection)]);
  const [name, setName] = useState({ ...defaultName });
  const [hasClickedSubmit, setHasClickedSubmit] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [modalData, setModalData] = useState({
    open: false,
    onPrimaryButtonClick: () => {},
  });

  const { skills, isLoading: isLoadingSkills } = useFindAllSkills([]);
  const { handleCreateExamDesign } = useCreateExamDesign();

  const resetForm = () => {
    setSections([cloneObject(defaultSection)]);
    setName({ ...defaultName });
    setHasClickedSubmit(false);
  };

  const buildModalData = (hideModal, id, error) => {
    if (hideModal) {
      return { open: false };
    }

    const title = error ? "Error" : "Success";
    const body = error
      ? `${error}`
      : `Exam design with id ${id} has been created`;

    return {
      open: true,
      title,
      body,
      textPrimaryButton: "Ok",
      severity: error !== null ? "error" : "success",
      onPrimaryButtonClick: handleModalClosed,
    };
  };

  const canAddNewSection = () => {
    return sections.length < skills.length;
  };

  const getSelectedSkills = () => {
    return sections.reduce((result, section) => {
      if (section.skill.value !== null) {
        result.push(section.skill);
      }
      return result;
    }, []);
  };

  const hasInvalidSections = () => {
    return sections.some((s) => s.hasErrors);
  };

  const hasValidName = () => !name.hasErrors;

  const isValidExamDesign = () => {
    return !hasInvalidSections() && hasValidName();
  };

  const handleAddSection = () => {
    if (!canAddNewSection()) return;

    const newSection = { ...defaultSection, id: v4() };
    setSections([...sections, newSection]);
  };

  const handleSectionChanged = (index, updatedSection) => {
    const newSections = [...sections];
    newSections[index] = updatedSection;
    setSections(newSections);
  };

  const handleSectionRemoved = (index) => {
    if (sections.length === 1) return;

    const newSections = sections.filter((d, i) => i !== index);
    setSections(newSections);
  };

  const handleNameChanged = ({ value, hasErrors }) => {
    setName({ value, hasErrors });
  };

  const isSubmitDisabled = () => {
    return hasClickedSubmit && !isValidExamDesign();
  };

  const handleSubmitClicked = async () => {
    setHasClickedSubmit(true);
    if (!isValidExamDesign()) return;
    try {
      setIsCreating(true);
      const { id } = await handleCreateExamDesign({ name, sections });
      setModalData(buildModalData(false, id, null));
      resetForm();
    } finally {
      setIsCreating(false);
    }
  };

  const handleModalClosed = () => {
    setModalData(buildModalData(true, null, null));
  };

  return (
    <MasterPage>
      <Title value="Exam Designer" />
      <Subtitle value="Create a new exam design" />
      <br />
      {isCreating ? (
        <CircularProgress />
      ) : (
        <CustomCard>
          <Stack spacking={12} paddingX={3} paddingY={2}>
            <DesignNameTextField
              name={name}
              onChange={handleNameChanged}
              forceShowErrors={hasClickedSubmit}
            />
            {sections.map((sec, i) => (
              <ExamSection
                key={sec.id}
                section={sec}
                selectedSkill={sec.skill}
                skills={skills}
                isLoadingSkills={isLoadingSkills}
                disabledSkills={getSelectedSkills()}
                title={`Exam section ${i + 1}`}
                onChange={(updatedSection) =>
                  handleSectionChanged(i, updatedSection)
                }
                canBeRemoved={sections.length > 1}
                onRemove={() => handleSectionRemoved(i)}
                defaultDistribution={defaultDistribution}
                forceShowErrors={hasClickedSubmit}
              />
            ))}

            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <IconButton
                edge="start"
                size="small"
                sx={{
                  color: theme.palette.primary.main,
                  marginTop: 3,
                }}
                onClick={handleAddSection}
              >
                <AddCircleOutlineOutlined />
              </IconButton>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: 14,
                }}
              >
                Add section
              </Typography>

              <Button
                variant={
                  !hasClickedSubmit || isValidExamDesign()
                    ? "contained"
                    : "outlined"
                }
                disabled={isSubmitDisabled()}
                onClick={handleSubmitClicked}
                style={{
                  backgroundColor: isSubmitDisabled()
                    ? "#CECECE"
                    : theme.palette.primary.main,
                  color: "white",
                  marginTop: 8,
                  width: 200,
                }}
              >
                Submit
              </Button>
            </Stack>
          </Stack>
        </CustomCard>
      )}
      <CustomModal {...modalData} />
    </MasterPage>
  );
};

export default ExamDesignCreator;
