import axios from "axios";
import { getHeaders } from "../amplify/sessionManager";
import { mapErrorToMessage } from "./utils/mapErrorToMessage";
import Swal from "sweetalert2";
import theme from "../../../components/foundations/themes";

const genericTitle = "We got an unexpected error";

const axiosInstanceBuilder = (baseUrl) => {
  const axiosInstance = axios.create({
    baseURL: baseUrl,
  });

  axiosInstance.interceptors.request.use(async (config) => {
    config.headers = await getHeaders();
    return config;
  });

  axiosInstance.interceptors.response.use(undefined, (error) => {
    const message = mapErrorToMessage(error);
    Swal.fire({
      title: genericTitle,
      text: message,
      icon: "error",
      confirmButtonText: "Got it",
      confirmButtonColor: theme.palette.primary.main,
    });
    return Promise.reject(message);
  });

  return axiosInstance;
};

export default axiosInstanceBuilder;
