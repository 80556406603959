import axiosInstanceBuilder from "../config/axios/axiosInstanceBuilder";
import { useFetch } from "../config/axios/useFetch";

const baseUrl = process.env.REACT_APP_API_DEV_URL;
const endpoint = "/tags";

const createAxiosPromise = (tag) => {
  return axiosInstanceBuilder(baseUrl).post(endpoint, tag);
};

export const useCreateTag = () => {
  const { fetchApi } = useFetch(createAxiosPromise, false);

  const handleCreateTag = async (tag) => {
    const response = await fetchApi(tag);
    const { data, ...rest } = response;
    return { tag: data, ...rest };
  };

  return { handleCreateTag };
};
