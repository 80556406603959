import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, TextField } from "@mui/material";

import { clamp } from "../utils/clamp";

const defaultValue = { min: "", max: "" };
const minName = "min";
const maxName = "max";

const ScoreRange = ({
  value: valueFromProps = defaultValue,
  onScoreRangeChanged: onChangeFromProps,
  disabled,
}) => {
  const isControlled = typeof valueFromProps !== "undefined";
  const [internalValue, setInternalValue] = useState(defaultValue);

  const minimum = 0;
  const maximum = 100;

  const value = isControlled ? valueFromProps : internalValue;

  const getNewScoreRange = (event) => {
    const newValue = event.target.value;
    const name = event.target.name;
    if (newValue === "" || isNaN(newValue)) {
      return {
        ...value,
        [name]: "",
      };
    }

    let clampedValue = newValue;
    if (name === minName) {
      const currentMaximum = value.max !== "" ? value.max : maximum;
      clampedValue = clamp(newValue, minimum, currentMaximum);
    } else if (name === maxName) {
      const currentMinimum = value.min !== "" ? value.min : minimum;
      clampedValue = clamp(newValue, currentMinimum, maximum);
    }

    return { ...value, [name]: clampedValue };
  };

  const handleScoreRangeChanged = (event) => {
    const newScoreRange = getNewScoreRange(event);
    if (onChangeFromProps) {
      onChangeFromProps(newScoreRange);
    }

    if (!isControlled) {
      setInternalValue(newScoreRange);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField
          disabled={disabled}
          fullWidth
          value={value.min}
          type="number"
          name={minName}
          variant="outlined"
          placeholder="Min Score"
          onChange={handleScoreRangeChanged}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled={disabled}
          fullWidth
          value={value.max}
          type="number"
          name={maxName}
          variant="outlined"
          placeholder="Max Score"
          onChange={handleScoreRangeChanged}
        />
      </Grid>
    </Grid>
  );
};

ScoreRange.propTypes = {
  onScoreRangeChanged: PropTypes.func,
  value: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
};

export default ScoreRange;
