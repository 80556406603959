import React, { useState, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Grid, Divider, Button } from "@mui/material";

import MasterPage from "./../../components/modules/masterpage";
import { Subtitle, Title } from "../../components/elements/texts";
import CustomCard from "../../components/modules/cards";

import findExamDesignsService from "./services/findExamDesignsService";
import findTagsService from "./services/findTagsService";
import findExamInstancesService from "./services/findExamInstancesService";

import { BasicForm } from "./components/BasicForm";
import { AdvancedForm } from "./components/AdvancedForm";
import { ExamInstancesTable } from "./components/ExamInstancesTable";
import BasicModal from "./components/BasicModal";

const InitialState = {
  examineeEmails: [],
  examDesignIds: [],
  tagIds: [],
  onlyFinished: false,
  onlyPending: false,
  startedBefore: null,
  startedAfter: null,
  minScore: null,
  maxScore: null,
  finishedBefore: null,
  finishedAfter: null,
  createdBefore: null,
  createdAfter: null,
  availableBefore: null,
  availableAfter: null,
  createdBy: [],
};

const ExamInstancesSearcher = () => {
  const [foundTags, setFoundTags] = useState([]);
  const [foundExamDesigns, setFoundExamDesigns] = useState([]);
  const [foundExamInstances, setFoundExamInstances] = useState([]);

  const [hasSearched, setHasSearched] = useState(false);
  const [isFinding, setIsFinding] = useState(false);
  const [modalError, setModalError] = useState(null);

  const [hideAdvancedForm, setHideAdvancedForm] = useState(true);
  const [formData, setFormData] = useState(InitialState);

  const [hasErrorsAtBasicForm, setHasErrorsAtBasicForm] = useState(false);
  const [hasErrorsAtAdvancedForm, setHasErrorsAtAdvancedForm] = useState(false);

  useEffect(() => {
    setIsFinding(true);
    Promise.all([findExamDesignsService(), findTagsService()])
      .then(([examDesigns, tags]) => {
        setFoundExamDesigns(examDesigns);
        setFoundTags(tags);
      })
      .catch((error) => {
        const detail = error.response
          ? error.response.data.detail
          : "Something went wrong. Please try again and contact support if the issue persists";
        setModalError(detail);
      })
      .finally(() => {
        setIsFinding(false);
      });
  }, []);

  const findExamInstances = () => {
    setIsFinding(true);
    findExamInstancesService(formData)
      .then((examInstances) => {
        const examInstancesWithDesignsNames = mapExamDesignIdsToNamesFrom(
          examInstances,
          foundExamDesigns
        );
        setFoundExamInstances(examInstancesWithDesignsNames);
      })
      .catch((error) => {
        const detail = error.response
          ? error.response.data.detail
          : "Something went wrong. Please try again and contact support if the issue persists";
        setModalError(detail);
      })
      .finally(() => {
        setIsFinding(false);
      });
  };

  const mapExamDesignIdsToNamesFrom = (examInstances, foundExamDesigns) => {
    return examInstances.map((ei) => {
      const { id, examDesignId, examineeEmail, score, tags, user, ...rest } =
        ei;
      const examDesign = foundExamDesigns.find((ed) => ed.id === examDesignId);
      return {
        id,
        examineeEmail,
        examDesignName: examDesign.name,
        score,
        tags,
        examineeName:
          `${user.first_name || ""} ${user.last_name || ""}`.trim() ||
          "No Name Registered",
        ...rest,
      };
    });
  };

  const hasErrors = () => hasErrorsAtAdvancedForm || hasErrorsAtBasicForm;

  const handleClickOnFindExamInstances = () => {
    findExamInstances();
    setHasSearched(true);
  };

  const handleErrorsAtBasicFormChanged = (hasErrors) => {
    setHasErrorsAtBasicForm(hasErrors);
  };

  const handleErrorsAtAdvancedFormChanged = (hasErrors) => {
    setHasErrorsAtAdvancedForm(hasErrors);
  };

  const handleFormDataChanged = (newformData) => {
    setFormData({ ...formData, ...newformData });
  };

  return (
    <MasterPage>
      <>
        <Title value="Report and Results" />
        <Subtitle value="Search participants" />
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomCard>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <BasicForm
                    onFormDataChanged={handleFormDataChanged}
                    onErrorsChanged={handleErrorsAtBasicFormChanged}
                    foundExamDesigns={foundExamDesigns}
                    foundTags={foundTags}
                    isFinding={isFinding}
                  />
                </Grid>

                {hideAdvancedForm ? null : (
                  <>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <AdvancedForm
                        onFormDataChanged={handleFormDataChanged}
                        onErrorsChanged={handleErrorsAtAdvancedFormChanged}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Button
                      disabled={hasErrorsAtAdvancedForm}
                      variant="outlined"
                      sx={{ textTransform: "none" }}
                      onClick={() => {
                        setHideAdvancedForm(!hideAdvancedForm);
                        console.log(formData);
                      }}
                    >
                      {hideAdvancedForm
                        ? "Show additional filters"
                        : "Hide additional filters"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                <LoadingButton
                  disabled={hasErrors()}
                  variant="contained"
                  sx={{ textTransform: "none" }}
                  onClick={handleClickOnFindExamInstances}
                  loading={isFinding}
                >
                  Search
                </LoadingButton>
              </Box>

              {isFinding ? null : (
                <ExamInstancesTable
                  examInstances={foundExamInstances}
                  hasSearched={hasSearched}
                />
              )}
            </CustomCard>
          </Grid>
        </Grid>
        <BasicModal
          isOpen={modalError !== null}
          title="Error!"
          description={modalError !== null ? modalError : ""}
          onButtonClicked={() => setModalError(null)}
        />
      </>
    </MasterPage>
  );
};

export default ExamInstancesSearcher;
