import * as React from "react";
import {
  Box,
} from "@mui/material";

import MasterPage from "./../../components/modules/masterpage";
import { Title } from "../../components/elements/texts";
import { ReactComponent as Landing } from "../../assets/images/landing.svg"

export default function Home() {
  return (
    <MasterPage>
      <Title value="Welcome!" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Landing
          style={{
            width: "50%",
            position: "absolute",
            top: 50,
            bottom: 0,
            margin: "auto",
          }}
        />
      </Box>
    </MasterPage>
  );
}
