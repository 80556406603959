import * as React from "react";
import { Typography } from "@mui/material";
import theme from "../foundations/themes";

function Title(props) {
  return (
    <Typography
      variant="h5"
      sx={{
        color: props.color ? props.color : theme.palette.grey[900],
        fontWeight: "bold",
      }}
    >
      {props.value}
    </Typography>
  );
}

function Subtitle(props) {
  return (
    <Typography
      variant="h6"
      sx={{
        color: props.color ? props.color : theme.palette.grey[700],
        fontWeight: "100",
      }}
    >
      {props.value}
    </Typography>
  );
}

export { Title, Subtitle };
