import { createSlice } from '@reduxjs/toolkit';

export const STATES = {
  NULL: null,
  READY_TO_RUN: "toRun",
  RUNNING: "running",
  PASSED: true,
  FAILED: false,
}

const initialState = {
  testCasesEvaluationState: []  // Values: STATES Enum
}

export const questionDesignSlice = createSlice({
  name: "questionDesign",
  initialState,
  reducers: {
    setQuestionDesign: (_state, action) => action.payload,
    updateTestCasesEvaluationState: (state, action) => {
      state.testCasesEvaluationState = action.payload;
    },
    updateTestCasesEvaluationStateIndex: (state, action) => {
      const { index, value } = action.payload;
      state.testCasesEvaluationState[index] = value;
    },
    resetQuestionDesign: () => initialState,
  },
});

export const {
  resetQuestionDesign,
  updateTestCasesEvaluationState,
  updateTestCasesEvaluationStateIndex,
  setQuestionDesign,
} = questionDesignSlice.actions;

export default questionDesignSlice.reducer;
