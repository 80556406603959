import React, { useState, useEffect } from "react";
import "./App.css";
import RouteList from "./Routes";
import { AppContext } from "./libs/contextLib";
import { ThemeProvider } from "@mui/material/styles";
import { Auth } from "aws-amplify";
import theme from "./components/foundations/themes";

import { useAuthenticatedUserRole } from "./api/auth/useGetCurrentUserRole";
import { Toaster } from "react-hot-toast";

function App() {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    isAuthenticating: true,
    userRole: null,
  });

  const { getAuthenticatedUserRole } = useAuthenticatedUserRole();

  useEffect(() => {
    const authenticateAndFetchRole = async () => {
      let isAuthenticated = false;
      let currentRole = null;

      try {
        await Auth.currentSession();
        isAuthenticated = true;
        const { userRole } = await getAuthenticatedUserRole();
        currentRole = userRole;
      } catch (err) {
        Auth.signOut();
      }

      setAuthState({
        isAuthenticated,
        isAuthenticating: false,
        userRole: currentRole,
      });
    };

    authenticateAndFetchRole();
  }, []);

  const handleAuthStateChange = async (isAuthenticated) => {
    let currentRole = null;
    if (isAuthenticated) {
      const { userRole } = await getAuthenticatedUserRole();
      currentRole = userRole;
    }

    setAuthState({
      isAuthenticated,
      isAuthenticating: false,
      userRole: currentRole,
    });
  };

  return (
    !authState.isAuthenticating && (
      <div>
        <Toaster />
        <ThemeProvider theme={theme}>
          <AppContext.Provider
            value={{
              onAuthStateChange: handleAuthStateChange,
              isAuthenticated: authState.isAuthenticated,
              userRole: authState.userRole,
            }}
          >
            <RouteList />
          </AppContext.Provider>
        </ThemeProvider>
      </div>
    )
  );
}

export default App;
