import axiosInstanceBuilder from "../config/axios/axiosInstanceBuilder";
import { useFetch } from "../config/axios/useFetch";

const baseUrl = process.env.REACT_APP_API_DEV_URL;
const endpoint = "/users";

const createAxiosPromise = (personalInfo) => {
  return axiosInstanceBuilder(baseUrl).patch(endpoint, personalInfo);
};

export const useUpdatePersonalInfo = () => {
  const { fetchApi } = useFetch(createAxiosPromise, false);

  const handleUpdatePersonalInfo = async (personalInfo) => {
    const { firstName, lastName, acceptedPolicy } = personalInfo;
    const apiPersonalInfo = {
      first_name: firstName,
      last_name: lastName,
      accepted_policy: acceptedPolicy
    };
    const response = await fetchApi(apiPersonalInfo);
    const { data, ...rest } = response;
    return { data, ...rest };
  };

  return { handleUpdatePersonalInfo };
};
