import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  darkMode: true,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    changeDarkMode: (state, action) => {
      state.darkMode = !state.darkMode;
    },
  },
});

export const { changeDarkMode } = userSlice.actions;
export default userSlice.reducer;
