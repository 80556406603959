import React from "react";
import { MathJax } from "better-react-mathjax";
import { Box, Switch, FormControlLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import Editor from "./Editor";
import { changeDarkMode } from "state/slices/userSlice";

const EditorWrapper = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginBottom: 8,
  direction: "column",
  alignItems: "center",
});

const MathJaxWrapper = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  marginBottom: 8,
  spacing: 2,
  direction: "column",
});

const Statement = ({
  questionId = null,
  value = null,
  editors = null,
  editorBehaviourEnabled = true,
  showModeSelector = true,
}) => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.user.darkMode);
  const currentQuestion = useSelector((state) => state.question?.question);

  const parseString = (str) => {
    /* eslint-disable */
    const matches = str.match(/{{ code_editor\((\d+)\) }}/g);
    const splitString = str.split(/({{ code_editor\(\d+\) }})/);

    const result = [];
    for (let i = 0; i < splitString.length; i++) {
      if (!matches) {
        result.push({ isCode: false, value: splitString[i] });
        continue;
      }

      const isCode = matches.includes(splitString[i]);
      if (splitString[i] !== "") {
        const value = isCode
          ? splitString[i].replace(
              /{{ code_editor\((\d+)\) }}/g,
              "code_editor($1)"
            )
          : splitString[i];
        result.push({ isCode, value });
      }
    }
    /* eslint-enable */
    return result;
  };

  function convertEscapedNewline(str) {
    return str.replace(/\\n/g, "\n");
  }

  const buildEditorConfig = (codeEditorOrHtmlList, codeEditors) => {
    return codeEditorOrHtmlList.map((data, index) => {
      if (!data.isCode) {
        return { id: index, ...data };
      }

      const codeEditor = codeEditors.find(
        (ce) => data.value === ce.placeholder_string
      );

      return {
        isCode: true,
        value: {
          id: codeEditor.html_id,
          code: convertEscapedNewline(codeEditor.code),
          language: codeEditor.language,
          allowEdit: editorBehaviourEnabled ? codeEditor.allow_edit : false,
          allowCopy: editorBehaviourEnabled ? codeEditor.allow_copy : false,
          allowPaste: editorBehaviourEnabled ? codeEditor.allow_paste : false,
          minLines: codeEditor.min_lines,
          maxLines: codeEditor.max_lines,
        },
      };
    });
  };

  const codeEditorOrHtml = parseString(currentQuestion?.statement || value);
  const editorConfig = buildEditorConfig(
    codeEditorOrHtml,
    currentQuestion?.code_editors || editors
  );

  return (
    <div>
      <Box
        style={{
          marginTop: "10px",
          marginBottom: "20px",
        }}
      >
        {showModeSelector &&
        (currentQuestion?.code_editors || editors) &&
        (currentQuestion?.code_editors.length || editors?.length) > 0 ? (
          <FormControlLabel
            control={
              <Switch
                checked={darkMode}
                onChange={() => dispatch(changeDarkMode())}
              />
            }
            label="light/dark mode"
          />
        ) : null}

        {editorConfig.map((config, index) => {
          if (!config.isCode) {
            return (
              <MathJaxWrapper
                key={index + "WRAPPER"}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
              >
                <MathJax>
                  <div dangerouslySetInnerHTML={{ __html: config.value }} />
                </MathJax>
              </MathJaxWrapper>
            );
          }
          return (
            <EditorWrapper key={config.id}>
              <Box sx={{ width: { xs: "100%", md: "60%" } }}>
                <Editor
                  {...config.value}
                  id={(currentQuestion?.id || questionId) + index}
                />
              </Box>
            </EditorWrapper>
          );
        })}
      </Box>
    </div>
  );
};

Statement.propTypes = {
  questionId: PropTypes.number,
  value: PropTypes.string,
  editors: PropTypes.array,
  showModeSelector: PropTypes.bool,
  editorBehaviourEnabled: PropTypes.bool,
};

export default Statement;
