import axiosInstanceBuilder from "../config/axios/axiosInstanceBuilder";
import { useFetch } from "../config/axios/useFetch";

const baseUrl = process.env.REACT_APP_API_DEV_URL;

export const useFindQuestionById = (id, callAfterMount = false) => {
  const endpoint = `/questions/${id}`;

  const createAxiosPromise = () => {
    return axiosInstanceBuilder(baseUrl).get(endpoint);
  };

  const { data, isPending, error, fetchApi } = useFetch(
    createAxiosPromise,
    callAfterMount
  );

  const findQuestion = async () => {
    const response = await fetchApi();
    const { data, ...rest } = response;
    const question = mapDataToQuestion(data);
    return { question, ...rest };
  };

  const buildQuestion = (statement, codeEditorObjects) => {
    let resultStr = statement;

    codeEditorObjects.forEach((editorObj) => {
      const preTag = `<pre class="code_editor" data-language="${editorObj.language}" data-allow-edit="${editorObj.allow_edit}" data-allow-paste="${editorObj.allow_paste}" data-allow-copy="${editorObj.allow_copy}">${editorObj.code}</pre>`;

      resultStr = resultStr.replace(
        `{{ ${editorObj.placeholder_string} }}`,
        preTag
      );
    });

    return resultStr;
  };

  const mapDataToQuestion = (props) => {
    if (!props) return null;

    const {
      choices,
      code_editors,
      created_by,
      difficulty,
      id,
      is_validated,
      resources,
      skill_id,
      statement,
      time,
      type,
      coding_languages,
      allows_running_test_cases,
      informs_test_case_correctness,
      custom_code_wrapper,
      solution_code,
      solution_code_language,
      output_lines_per_test_case,
      test_cases,
    } = props;

    return {
      id,
      skill: skill_id,
      choices,
      codeEditors: code_editors,
      createdBy: created_by,
      difficulty,
      isValidated: is_validated,
      resources,
      statement: buildQuestion(statement, code_editors),
      time,
      type,
      codingLanguages: coding_languages,
      allowsRunningTestCases: allows_running_test_cases,
      informsTestCaseCorrectness: informs_test_case_correctness,
      customCodeWrapper: custom_code_wrapper,
      solutionCode: solution_code,
      solutionCodeLanguage: solution_code_language,
      outputLinesPerTestCase: output_lines_per_test_case,
      testCases: test_cases,
    };
  };

  return {
    question: mapDataToQuestion(data),
    isLoading: isPending,
    error,
    findQuestion,
  };
};
