import axiosInstanceBuilder from "../config/axios/axiosInstanceBuilder";
import { useFetch } from "../config/axios/useFetch";

const baseUrl = process.env.REACT_APP_API_DEV_URL;
const endpoint = "/exam-designs";

const createAxiosPromise = (data) => {
  return axiosInstanceBuilder(baseUrl).post(endpoint, data);
};

export const useCreateExamDesign = () => {
  const { fetchApi } = useFetch(createAxiosPromise, false);

  const handleCreateExamDesign = async (examDesign) => {
    const examDesignRequest = mapExamDesignToCreationRequest(examDesign);
    const response = await fetchApi(examDesignRequest);
    const { data, ...rest } = response;
    return { id: data.id, ...rest };
  };

  return {
    handleCreateExamDesign,
  };
};

const mapExamDesignToCreationRequest = (examDesign) => {
  if (!examDesign) return {};

  const name = examDesign.name.value;
  const sections = examDesign.sections.map((section) => ({
    skill_id: section.skill.value,
    difficulty_dists: section.distributions.map((d) =>
      mapDistributionToRequest(d)
    ),
  }));

  return { name, exam_sections: sections };
};

const mapDistributionToRequest = (distribution) => {
  const difficulty = distribution.difficulty.value;
  const numberOfQuestions = distribution.numQuestions.value;
  const weight = distribution.weight.value;
  return { difficulty, number_of_questions: numberOfQuestions, weight };
};
