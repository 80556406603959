import React from "react";
import PropTypes from "prop-types";
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  Typography,
  Alert,
  FormControl,
} from "@mui/material";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

const EmailList = ({
  emails,
  onEmailRemoved,
  onEmailEdited,
  forceShowErrors,
}) => {
  return (
    <FormControl size="small" fullWidth>
      <Typography sx={{ fontWeight: "600", fontSize: 14, mb: 1, mt: 2 }}>
        Valid email list
      </Typography>
      <Paper
        elevation={2}
        style={{
          minHeight: 200,
          maxHeight: 200,
          overflow: "auto",
          width: "100%",
          maxWidth: "100%",
        }}
      >
        <List dense={true} sx={{ width: "100%", maxWidth: "100%" }}>
          {emails.map((email) => (
            <ListItem
              key={email}
              secondaryAction={
                <>
                  <IconButton
                    sx={{ mr: 1 }}
                    edge="end"
                    aria-label="edit"
                    onClick={(e) => onEmailRemoved(email)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={(e) => onEmailEdited(email)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              }
            >
              <ListItemText primary={email} />
            </ListItem>
          ))}
        </List>
      </Paper>
      <Typography sx={{ fontWeight: "400", fontSize: 13, textAlign: "right" }}>
        Emails validated: {emails.length}
      </Typography>
      {forceShowErrors && emails.length === 0 ? (
        <Alert severity="error">
          <Typography>There must be at least one valid email!</Typography>
        </Alert>
      ) : null}
    </FormControl>
  );
};

EmailList.propTypes = {
  emails: PropTypes.array.isRequired,
  onEmailRemoved: PropTypes.func.isRequired,
  onEmailEdited: PropTypes.func.isRequired,
  forceShowErrors: PropTypes.bool.isRequired,
};

export default EmailList;
