import { backend_client } from "../../../helpers/api";

const tagEndpoint = "/tags/list";
const findTagsService = async () => {
  return backend_client
    .get(tagEndpoint)
    .then((response) => response.data)
    .then((data) => mapResponseToTags(data));
};

const mapResponseToTags = (response) => {
  return response.map((r) => ({ id: r.id, name: r.name }));
};

export default findTagsService;
