// import { makeStyles } from "@material-ui/core/styles";
import { 
  // Box,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
  // Link
} from "@mui/material";
import React, { useState } from "react";
// import styles from "../styles/common/BasicModal";
import PropTypes from "prop-types";
import { Button, Typography } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const BasicDialog = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const theme = useTheme();

  const {
    buttonOpenText,
    modalTitleText,
    modalDescriptionText,
    continueButtonRedirection
  } = props;

  const navigate = useNavigate();

  const routeChange = (route) => {
    setOpen(false);
    navigate(route);
  };

  return (
    <div>
      <Button
        variant="contained"
        style={{
          backgroundColor: theme.palette.primary.main,
          marginBottom: "4px",
          color: "#FFFFFF"
        }}
        onClick={handleOpen}
      >
        {buttonOpenText}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h6" component="h2">
            {modalTitleText}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {modalDescriptionText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant={"outlined"}
            size={"medium"}
          >
            {"Take me back"}
          </Button>
          <Button
            onClick={() => routeChange(continueButtonRedirection)}
            variant={"contained"}
            style={{
              backgroundColor: theme.palette.primary.main,
              marginLeft: "10px",
              color: "white"
            }}
            size={"medium"}
            autoFocus
          >
            {"Continue"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

BasicDialog.propTypes = {
  buttonOpenText: PropTypes.string,
  modalTitleText: PropTypes.string,
  modalDescriptionText: PropTypes.string,
  continueButtonRedirection: PropTypes.string
};

export default BasicDialog;