import PropTypes from "prop-types";
import { Stack, Typography, Grid, Slider } from "@mui/material";

export const DifficultySelector = ({ difficulty, onDifficultyChange }) => {
  const fromDifficultyToColor = (level) => {
    if (level === 1) {
      return "#21646d";
    } else if (level === 2) {
      return "#F1C40F";
    } else if (level === 3) {
      return "#E67E22";
    } else if (level === 4) {
      return "#E74C3C";
    } else {
      return "#7F8C8D";
    }
  };

  return (
    <Grid item xs={12} sm={6}>
      <Stack direction="row">
        <Typography sx={{ fontWeight: "600", fontSize: 14, mr: 0.5 }}>
          Difficulty:{" "}
        </Typography>
        <Typography sx={{ fontSize: 14 }}>
          {["Easy", "Medium", "Hard", "Very Hard"][difficulty - 1]}
        </Typography>
      </Stack>
      <Slider
        min={1}
        max={4}
        value={difficulty}
        step={1}
        marks
        onChange={(event, newValue) => onDifficultyChange(newValue)}
        sx={{ color: fromDifficultyToColor(difficulty) }}
      />
      <Stack
        spacing={2}
        direction="row"
        sx={{ mb: 1 }}
        alignItems="center"
        justifyContent="space-between"
      >
        <label style={{ fontSize: 12 }}>Easy</label>
        <label style={{ fontSize: 12 }}>Very Hard</label>
      </Stack>
    </Grid>
  );
};

DifficultySelector.propTypes = {
  difficulty: PropTypes.number.isRequired,
  onDifficultyChange: PropTypes.func.isRequired,
};
