import React from "react";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Grid } from "@mui/material";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ExamSkillsInformation from "./ExamSkillsInformation";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@material-ui/core";

const ExamInformation = (props) => {
  const {
    examName,
    examAvailableUntil,
    examSkills,
    isExamInProgress,
    styles,
    examMaxDuration,
  } = props;

  const maxDurationMinutes = (examMaxDuration / 60).toFixed(2);

  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const theme = useTheme();

  const dateOption = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneName: "short",
  };

  return (
    <div style={{ width: "100%" }}>
      <Grid container item xs={12}>
        {isExamInProgress ? (
          <Grid item sm={3}>
            <p className={classes.startTag}>{"In Progress"}</p>
          </Grid>
        ) : (
          <div></div>
        )}
        <Grid container item sm={9} alignItems={"center"}>
          <strong>{examName}</strong>
        </Grid>
      </Grid>
      <Grid container item xs={12} alignItems={"center"} marginTop={"1rem"}>
        <Grid item xs={1}>
          <DateRangeIcon
            style={{
              color: theme.palette.primary.main,
            }}
          />
        </Grid>
        <Grid item xs={11}>
          <Typography>
            {`Available Until: ${new Date(
              examAvailableUntil + "Z"  // Force parse as UTC
            ).toLocaleString("en-US", dateOption)}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid container item xs={12} alignItems={"center"}>
        <Grid item xs={1}>
          <ScheduleIcon
            style={{
              color: theme.palette.primary.main,
            }}
          />
        </Grid>
        <Grid item xs={11}>
          <Typography>
            {`This exam may take you between ${Math.round(
              maxDurationMinutes * 0.5
            )}
              and ${Math.round(maxDurationMinutes)} minutes`}
          </Typography>
        </Grid>
      </Grid>
      <ExamSkillsInformation examSkills={examSkills} />
    </div>
  );
};

ExamInformation.propTypes = {
  examName: PropTypes.string,
  examAvailableUntil: PropTypes.string,
  examSkills: PropTypes.arrayOf(PropTypes.string),
  isExamInProgress: PropTypes.bool,
  styles: PropTypes.func,
  examMaxDuration: PropTypes.number,
};

export default ExamInformation;
