import axiosInstanceBuilder from "../config/axios/axiosInstanceBuilder";
import { useFetch } from "../config/axios/useFetch";

const baseUrl = process.env.REACT_APP_API_DEV_URL;

export const useGetPolicies = (docType, callAfterMount = false) => {
  const endpoint = `/common/legal/${docType}`;

  const createAxiosPromise = () => {
    return axiosInstanceBuilder(baseUrl).get(endpoint);
  };

  const { data, isPending, error, fetchApi } = useFetch(
    createAxiosPromise,
    callAfterMount
  );

  const getPolicy = async () => {
    const response = await fetchApi();
    const { data, ...rest } = response;
    return { policyEnglish: data.english, policySpanish: data.spanish, ...rest };
  };

  return {
    userRole: data,
    isLoading: isPending,
    error,
    getPolicy,
  };
};
