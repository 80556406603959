import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

export const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    clearQuestionState: () => initialState,
    updateQuestion: (state, action) => {
      return action.payload;
    },
  },
});

export const { clearQuestionState, updateQuestion } = questionSlice.actions;
export default questionSlice.reducer;
