import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import CustomModal from "../../../components/modules/modals";

import { useFindAllTags } from "../../../api/tags/useFindAllTags";
import { useCreateTag } from "../../../api/tags/useCreateTag";

const useStyles = makeStyles({
  tags: {
    width: "100%",
    marginTop: 40,
    marginBottom: 20,
  },
});

const TagsPicker = ({ onSelectedTagsChanged }) => {
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [newTag, setNewTag] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const { findAllTags } = useFindAllTags(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const resp = await findAllTags();
        const { tags } = resp;
        setTags(tags);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const { handleCreateTag } = useCreateTag();

  const isValidTagName = (text) => {
    if (!text) false;

    if (tags.some((t) => t.name === text)) {
      return false;
    }

    if (text.trim().length === 0) {
      setModalError("The tag name cannot be composed entirely of empty space");
      return false;
    }

    return true;
  };

  const createNewTagIfNeeded = async (maybeName) => {
    if (!isValidTagName(maybeName)) return;

    try {
      setIsCreating(true);
      const { tag } = await handleCreateTag({ name: maybeName });
      setNewTag(tag);
      setTags([...tags, tag]);
      const newSelectedTags = [...selectedTags, tag];
      setSelectedTags(newSelectedTags);
      handleOnChange("createOption", newSelectedTags);
    } finally {
      setIsCreating(false);
    }
  };

  const getLastCreatedTagName = () => {
    return newTag !== null ? newTag.name : "";
  };

  const handleModalButtonClick = () => {
    setNewTag(null);
  };

  const handleOnChange = (reason, tags) => {
    if (
      reason === "removeOption" ||
      reason === "selectOption" ||
      reason === "createOption"
    ) {
      setSelectedTags([...tags]);
      onSelectedTagsChanged(tags);
      return;
    }
  };

  return (
    <>
      <Autocomplete
        value={selectedTags}
        className={classes.tags}
        multiple
        options={tags}
        getOptionLabel={(option) => option.name}
        onChange={(event, value, reason) => handleOnChange(reason, value)}
        loading={isLoading || isCreating}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Tags"
            placeholder="Select multiple tags or create new ones"
          />
        )}
        noOptionsText="Tag no found, press Enter to create it"
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            createNewTagIfNeeded(e.target.value);
          }
        }}
      />
      <CustomModal
        open={newTag !== null}
        title="Success!"
        body={
          newTag !== null
            ? `You have created the new tag: ${getLastCreatedTagName()}`
            : ""
        }
        textPrimaryButton="Ok"
        onPrimaryButtonClick={handleModalButtonClick}
      />
    </>
  );
};

TagsPicker.propTypes = {
  tags: PropTypes.array.isRequired,
  onSelectedTagsChanged: PropTypes.func.isRequired,
};

export default TagsPicker;
