import * as React from "react";
import { Paper } from "@mui/material";

const CustomCard = React.forwardRef((props, ref) => {
  return (
    <Paper
      ref={ref}
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: props.color ? props.color : "secondary",
        width: "100%",
        height: props.height ? props.height : "auto",
        boxShadow: "0 1px 10px 0 rgba(0, 0, 0, 0.1)",
        borderRadius: "20px",
        padding: props.noPadding ? "0px" : "2rem",
      }}
    >
      {props.children}
    </Paper>
  );
});

export default CustomCard;
