import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, TextField, Autocomplete } from "@mui/material";

import DateTimeRangePicker from "./DateTimeRangePicker";
import PendingFinishedSwitch from "../components/PendingFinishedSwitch";
import EmailsContainer from "../components/EmailsContainer";
import ScoreRange from "../components/ScoreRange";

const InitialState = {
  examineeEmails: [],
  examDesignIds: [],
  tagIds: [],
  onlyFinished: false,
  onlyPending: false,
  onlyExpired: false,
  startedBefore: null,
  startedAfter: null,
  minScore: "",
  maxScore: "",
  finishedBefore: null,
  finishedAfter: null,
};

const InitialErrors = {
  hasErrorsAtStartedAfter: false,
  hasErrorsAtStartedBefore: false,
  hasErrorsAtFinishedAfter: false,
  hasErrorsAtFinishedBefore: false,
};

const started = "started";
const finished = "finished";

export const BasicForm = ({
  foundExamDesigns,
  foundTags,
  isFinding,
  onFormDataChanged,
  onErrorsChanged,
}) => {
  const [formData, setFormData] = useState(InitialState);
  const [errors, setErrors] = useState(InitialErrors);

  const updateFormData = (newFormData) => {
    setFormData(newFormData);
    onFormDataChanged(newFormData);
  };

  const handleExamineeEmailsChanged = (newEmails) => {
    updateFormData({ ...formData, examineeEmails: newEmails });
  };

  const handleSeletedTagsChanged = (newSelectedTags) => {
    const newTagIds = newSelectedTags.map((tag) => tag.id);
    updateFormData({ ...formData, tagIds: newTagIds });
  };

  const handleSelectedExamDesignsChanged = (newSelectedExamDesigns) => {
    const examDesignIds = newSelectedExamDesigns.map((ed) => ed.id);
    updateFormData({
      ...formData,
      examDesignIds: examDesignIds,
    });
  };

  const handleAnyOnlyChanged = (newOnlyState) => {
    const mustDeleteFinishedRange =
      newOnlyState.onlyPending || newOnlyState.onlyExpired;

    const updatedFinished = {
      finishedBefore: mustDeleteFinishedRange ? null : formData.finishedBefore,
      finishedAfter: mustDeleteFinishedRange ? null : formData.finishedAfter,
    };

    const updatedScoreRange = {
      maxScore: mustDeleteFinishedRange ? "" : formData.maxScore,
      minScore: mustDeleteFinishedRange ? "" : formData.minScore,
    };

    const newFormData = {
      ...formData,
      onlyPending: newOnlyState.onlyPending,
      onlyFinished: newOnlyState.onlyFinished,
      onlyExpired: newOnlyState.onlyExpired,
      ...updatedFinished,
      ...updatedScoreRange,
    };

    updateFormData(newFormData);
  };

  const handleScoreRangeChanged = ({ min, max }) => {
    let newFormData = {
      ...formData,
      minScore: min,
      maxScore: max,
    };

    if (min !== "" || max !== "") {
      newFormData = {
        ...newFormData,
        onlyPending: false,
        onlyExpired: false,
      };
    }
    updateFormData(newFormData);
  };

  const handleDateTimeRangeChanged = (dateTimeRange, name) => {
    if (name === started) {
      updateFormData({
        ...formData,
        startedAfter: dateTimeRange.initial,
        startedBefore: dateTimeRange.final,
      });
    }

    const checkOnlyFinished =
      dateTimeRange.initial !== null || dateTimeRange.final !== null;

    if (name === finished) {
      updateFormData({
        ...formData,
        onlyFinished: checkOnlyFinished,
        finishedAfter: dateTimeRange.initial,
        finishedBefore: dateTimeRange.final,
      });
    }
  };

  const hasErrorsInObject = (obj) => {
    return Object.values(obj).some((error) => error === true);
  };

  const updateErrorAt = (name, hasErrors) => {
    const newErrors = { ...errors, [name]: hasErrors };
    onErrorsChanged(hasErrorsInObject(newErrors));
    setErrors(newErrors);
  };

  const handleErrorsAtStartedAfter = (hasErrors) => {
    updateErrorAt("hasErrorsAtStartedAfter", hasErrors);
  };

  const handleErrorsAtStartedBefore = (hasErrors) => {
    updateErrorAt("hasErrorsAtStartedBefore", hasErrors);
  };

  const handleErrorsAtFinishedAfter = (hasErrors) => {
    updateErrorAt("hasErrorsAtFinishedAfter", hasErrors);
  };

  const handleErrorsAtFinishedBefore = (hasErrors) => {
    updateErrorAt("hasErrorsAtFinishedBefore", hasErrors);
  };

  return (
    <Grid container spacing={3}>
      <Grid container spacing={3} item xs={6}>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={foundExamDesigns}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) =>
              handleSelectedExamDesignsChanged(newValue)
            }
            loading={isFinding}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Exam designs"
                placeholder="Select multiple exam designs"
              />
            )}
            noOptionsText="Exam designs not found"
          />
        </Grid>
        <Grid item xs={12}>
          <PendingFinishedSwitch
            value={{
              onlyPending: formData.onlyPending,
              onlyFinished: formData.onlyFinished,
              onlyExpired: formData.onlyExpired,
            }}
            onSwitchChanged={handleAnyOnlyChanged}
          />
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <EmailsContainer
          placeholder="Insert the examinee emails"
          title="Examinee Emails"
          helperText={`${formData.examineeEmails.length} emails`}
          onEmailsChanged={handleExamineeEmailsChanged}
        />
      </Grid>
      <Grid item xs={6} container spacing={3}>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth={false}
            multiple
            options={foundTags}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => handleSeletedTagsChanged(newValue)}
            loading={isFinding}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Tags"
                placeholder="Select multiple tags"
              />
            )}
            noOptionsText="Tags not found"
          />
        </Grid>
        <Grid item xs={12}>
          <ScoreRange
            disabled={formData.onlyPending || formData.onlyExpired}
            onScoreRangeChanged={handleScoreRangeChanged}
            value={{ min: formData.minScore, max: formData.maxScore }}
          />
        </Grid>
      </Grid>
      <Grid item xs={6} container spacing={3}>
        <Grid item xs={12}>
          <DateTimeRangePicker
            name={started}
            disabled={false}
            disableInitialFuture={true}
            disableFinalFuture={true}
            initialLabel="Started after"
            finalLabel="Started before"
            onDateTimeRangeChanged={handleDateTimeRangeChanged}
            onHasErrorsAtInitialDateChanged={handleErrorsAtStartedAfter}
            onHasErrorsAtFinalDateChanged={handleErrorsAtStartedBefore}
          />
        </Grid>
        <Grid item xs={12}>
          <DateTimeRangePicker
            value={{
              initial: formData.finishedAfter,
              final: formData.finishedBefore,
            }}
            name={finished}
            disabled={formData.onlyPending || formData.onlyExpired}
            initialLabel="Finished after"
            finalLabel="Finished before"
            onDateTimeRangeChanged={handleDateTimeRangeChanged}
            onHasErrorsAtInitialDateChanged={handleErrorsAtFinishedAfter}
            onHasErrorsAtFinalDateChanged={handleErrorsAtFinishedBefore}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

BasicForm.propTypes = {
  foundExamDesigns: PropTypes.array.isRequired,
  foundTags: PropTypes.array.isRequired,
  isFinding: PropTypes.bool.isRequired,
  onFormDataChanged: PropTypes.func.isRequired,
  onErrorsChanged: PropTypes.func.isRequired,
};
