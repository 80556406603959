import React from "react";
import PropTypes from "prop-types";
import { v4 } from "uuid";
import { Typography, IconButton, Stack, Box } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { AddCircleOutlineOutlined } from "@material-ui/icons";

import { DifficultyDistribution } from "../index";
import { CustomizedAutocomplete } from "../../elements";
import theme from "../../../../../components/foundations/themes";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid",
    borderRadius: 2,
    padding: "8px 16px",
    background: "#ebf8f9",
  },
  title: {
    flexGrox: 1,
    textAlign: "center",
    fontWeight: "600",
    fontSize: 18,
    borderRadius: 2,
  },
  iconButton: {
    padding: 0,
    color: "red",
  },
};

const difficulties = [
  { id: 1, name: "Easy" },
  { id: 2, name: "Medium" },
  { id: 3, name: "Hard" },
  { id: 4, name: "Very Hard" },
];

const ExamSection = ({
  title,
  selectedSkill,
  skills,
  isLoadingSkills,
  disabledSkills,
  section,
  onChange,
  canBeRemoved,
  onRemove,
  defaultDistribution,
  forceShowErrors,
}) => {
  const handleDistributionChange = (index, distribution) => {
    let newDistributions = [...section.distributions];
    newDistributions[index] = distribution;

    const hasErrorsInSection = someErrorInSection(
      newDistributions,
      section.skill
    );
    onChange({
      ...section,
      distributions: newDistributions,
      hasErrors: hasErrorsInSection,
    });
  };

  const getSelectedDifficulties = () => {
    return section.distributions.reduce((result, dist) => {
      const difficulty = dist.difficulty;
      if (difficulty.value !== "") {
        result.push(difficulty);
      }
      return result;
    }, []);
  };

  const handleAddDistribution = () => {
    if (section.distributions.length === difficulties.length) return;

    const distributionToAdd = { ...defaultDistribution, id: v4() };
    const newDistritubions = [...section.distributions, distributionToAdd];
    onChange({ ...section, distributions: newDistritubions, hasErrors: true });
  };

  const handleRemoveDistribution = (index) => {
    if (section.distributions.length === 1) return;

    const newDistributions = section.distributions.filter(
      (d, i) => i !== index
    );
    const hasErrors = someErrorInSection(newDistributions, section.skill);
    onChange({ ...section, distributions: newDistributions, hasErrors });
  };

  const someErrorInSection = (distributions, skill) => {
    const hasErrorsInAnyDistribution = distributions.some(
      (dist) => dist.hasErrors
    );
    return hasErrorsInAnyDistribution || skill.hasErrors;
  };

  const handleNewSkillSelected = (skill) => {
    const hasErrorsInSection = someErrorInSection(section.distributions, skill);
    onChange({ ...section, skill, hasErrors: hasErrorsInSection });
  };

  const canAddDistribution = () => section.distributions.length < 4;

  return (
    <Stack spacing={2} border={1} borderRadius={1} marginTop={2}>
      <Box
        spacing={0}
        sx={{ borderRadius: 3, background: "black" }}
        borderRadius={3}
      >
        <div style={styles.root}>
          <Typography style={styles.title}>{title}</Typography>
          {canBeRemoved ? (
            <IconButton
              edge="start"
              onClick={onRemove}
              style={styles.iconButton}
            >
              <DeleteOutlinedIcon />
            </IconButton>
          ) : null}
        </div>
      </Box>
      <Stack spacing={2} paddingX={2} paddingBottom={1}>
        <CustomizedAutocomplete
          selectedOption={selectedSkill}
          label="Skill"
          placeholder="Select one skill"
          options={skills}
          disabledOptions={disabledSkills}
          onChange={handleNewSkillSelected}
          isLoading={isLoadingSkills}
          forceShowErrors={forceShowErrors}
        />
        {section.distributions.map((dist, i) => (
          <DifficultyDistribution
            key={dist.id}
            selectedDifficulty={dist.difficulty}
            title={`Difficulty distribution ${i + 1}`}
            distribution={dist}
            difficulties={difficulties}
            disabledDifficulties={getSelectedDifficulties()}
            onChange={(distribution) =>
              handleDistributionChange(i, distribution)
            }
            canBeRemoved={section.distributions.length > 1}
            onRemove={() => handleRemoveDistribution(i)}
            forceShowErrors={forceShowErrors}
          />
        ))}

        {canAddDistribution() ? (
          <Stack direction="column" alignItems="center" justifyContent="center">
            <IconButton
              edge="start"
              sx={{ color: theme.palette.primary.main, margin: 0 }}
              onClick={handleAddDistribution}
            >
              <AddCircleOutlineOutlined />
            </IconButton>
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: 14,
              }}
            >
              Add difficulty distribution
            </Typography>
          </Stack>
        ) : null}
      </Stack>
    </Stack>
  );
};

ExamSection.propTypes = {
  title: PropTypes.string.isRequired,
  selectedSkill: PropTypes.object.isRequired,
  skills: PropTypes.array.isRequired,
  isLoadingSkills: PropTypes.bool.isRequired,
  disabledSkills: PropTypes.array.isRequired,
  section: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  canBeRemoved: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  forceShowErrors: PropTypes.bool.isRequired,
  defaultDistribution: PropTypes.object.isRequired,
};

export default ExamSection;
