const useStyles = (theme) => ({
    questionButton: {
        borderWidth:'1px',
        borderStyle:'dotted',
        borderColor: theme.palette.primary.main,
        borderRadius:'10px',
    },
    questionFocus: {
        width:'100%',
        borderWidth: '3px',
        borderStyle:'solid',
        borderColor: theme.palette.primary.dark,
        borderRadius:'10px',
        overflow:'hidden',
    },
    questionUnfocus: {
        width:'100%',
        borderWidth: '0.5px',
        borderStyle:'solid',
        borderColor: theme.palette.primary.dark,
        borderRadius:'10px',
        overflow:'hidden',
    },
    questionPreview: {
        width:'100%',
        borderWidth:'3px',
        borderStyle:'solid',
        borderColor: theme.palette.primary.dark,
        borderRadius:'10px',
        overflow:'hidden',
        padding:'10px',
        backgroundColor:'#f3f3f3',
    },
    answerPreview: {
        width:'100%',
        borderWidth:'0.5px',
        borderStyle:'solid',
        borderColor:theme.palette.primary.main,
        borderRadius:'10px',
        overflow:'hidden',
        padding:'10px',
        backgroundColor:'#f3f3f3',
    }
})

export default useStyles