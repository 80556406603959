import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextField, Typography, Alert } from "@mui/material";
import { isEmail } from "../utils/validateEmail";

const EmailsValidator = ({
  emails,
  onEmailsChanged,
  text,
  onTextChanged,
  forceShowErrors,
}) => {
  const validateText = (text) => {
    const newEmails = [];
    const newNonEmails = [];
    const lines = text.split("\n");
    lines.forEach((line) => {
      const trimedLine = line.trim();
      if (trimedLine && isEmail(trimedLine)) {
        newEmails.push(trimedLine);
      } else if (trimedLine.length > 0) {
        newNonEmails.push(trimedLine);
      }
    });
    const uniqueEmails = [...new Set([...emails, ...newEmails])];
    const uniqueNonEmails = [...new Set(newNonEmails)];
    onEmailsChanged(uniqueEmails);
    const newNonValidatedText = uniqueNonEmails.join("\n");
    onTextChanged({
      value: newNonValidatedText,
      hasErrors: newNonValidatedText.length > 0,
    });
  };

  const handleTextChange = (event) => {
    const trimedText = event.target.value?.trim();
    onTextChanged({ value: trimedText, hasErrors: trimedText.length > 0 });
  };

  const shouldShowErrors = () => {
    const hasErrors = text.length > 0;
    return forceShowErrors && hasErrors;
  };

  const buildHelperText = () => {
    if (!shouldShowErrors()) return "For multiple emails, please create a list with an email per line (e.g., copy and paste a spreadsheet column), then press Enter to validate emails";

    return "Press Enter to validate emails or delete no valid emails";
  };

  return (
    <>
      <Typography sx={{ fontWeight: "600", fontSize: 14, mt: 2 }}>
        Emails to validate
      </Typography>
      <TextField
        multiline
        fullWidth
        sx={{ maxHeight: 150, overflow: "auto" }}
        placeholder="Insert emails to be validated here"
        value={text}
        onChange={(e) => handleTextChange(e)}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            validateText(e.target.value);
          }
        }}
        error={shouldShowErrors()}
        helperText={buildHelperText()}
      />
    </>
  );
};

EmailsValidator.propTypes = {
  emails: PropTypes.array.isRequired,
  onEmailsChanged: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  onTextChanged: PropTypes.func.isRequired,
  forceShowErrors: PropTypes.bool.isRequired,
};

export default EmailsValidator;
