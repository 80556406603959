import React, { useState } from "react";
import { TextField } from "@mui/material";

const emptyError = "Can't be empty";
const integerError = "Must be a integer";

const IntegerInputRange = ({
  integer,
  min,
  max,
  name,
  label,
  onChange,
  forceShowErrors,
}) => {
  const [isDirty, setIsDirty] = useState(false);

  const sanitazeInt = (value) => {
    if (value === "") {
      return { value, error: emptyError };
    }

    const maybeInt = parseInt(value);
    if (isNaN(maybeInt)) {
      return { value, error: integerError };
    }

    if (max) {
      if (value > max) {
        return {
          value: maybeInt,
          error: `Max value is ${max}`,
        };
      }
    }

    if (value < min) {
      return { value: maybeInt, error: `Min value is ${min}` };
    }

    return { value: maybeInt, error: null };
  };

  const handleChange = (event) => {
    const valueAsString = event.target.value;
    const { value, error } = sanitazeInt(valueAsString);
    setIsDirty(true);

    onChange({ name, value, hasErrors: error !== null });
  };

  const shouldShowError = (hasErrors) => {
    if ((isDirty || forceShowErrors) && hasErrors) return true;

    return false;
  };

  const getHelperText = (integer) => {
    return shouldShowError(integer.hasErrors)
      ? sanitazeInt(integer.value).error
      : "";
  };

  return (
    <TextField
      sx={{ width: 200 }}
      value={integer.value}
      type="text"
      error={shouldShowError(integer.hasErrors)}
      helperText={getHelperText(integer)}
      name={name}
      label={label}
      variant="outlined"
      onChange={handleChange}
    />
  );
};

export default IntegerInputRange;
