import { useRef, useEffect, useState, useCallback } from "react";
import Editor from "./Editor";
import CodeTextField from "components/elements/codeTextField";
import {
  updateLanguage,
  updateSTDIN,
  updateCode,
} from "state/slices/answerCodeSlice";

import {
  Box,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  lighten,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { toast, useToasterStore } from "react-hot-toast";
import { useTheme } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  updateOUTPut,
  updateExpectedOutput,
  updatePassedTestCases,
  resetPassedTestCases,
} from "state/slices/answerCodeSlice";
import { backend_client } from "../../../helpers/api";
import { PlayArrow } from "@mui/icons-material";

const QuestionCodeEditor = ({
  id,
  solutionLanguage,
  disableLanguage = false,
  solutionCode,
  allowEdit = true,
  showRightBlock = true,
  ...props
}) => {
  const theme = useTheme();

  const answerCode = useSelector((state) => state.answerCode);
  const currentQuestion = useSelector((state) => state.question?.question);
  const editorLanguage = useSelector((state) => state.answerCode.language);
  const STDIN = useSelector((state) => state.answerCode.stdin);
  const STDOUT = useSelector((state) => state.answerCode.stdout);
  const expectedOutput = useSelector((state) => state.answerCode.expected_output);
  const passedTestCases = useSelector((state) => state.answerCode.passed_test_cases);
  const userCode = useSelector((state) => state.answerCode.user_code);
  const dispatch = useDispatch();
  const languages = useSelector(
    (state) => state.question?.question?.coding_languages
  );
  const [outputColor, setOutputColor] = useState("default");
  const [runCodeLoading, setRunCodeLoading] = useState(false);
  const editor = useRef();
  const stdoutRef = useRef();

  const runCode = useCallback(async () => {
    setRunCodeLoading(true);
    dispatch(resetPassedTestCases());

    const params = {
      question_id: currentQuestion?.id,
      language: answerCode.language.language.toLowerCase(),
      user_code: answerCode.user_code,
      stdin: answerCode.stdin,
    };
    const endpoint = "/questions/run-code";
    try {
      const data = await backend_client
        .post(endpoint, params)
        .then((response) => response.data);
      dispatch(updateOUTPut(data.stdout));
      dispatch(updateExpectedOutput(data.expected_output));
      dispatch(updatePassedTestCases(data.passed_test_cases));
    } catch (error) {
      console.warn(error);
    } finally {
      setRunCodeLoading(false);
    }
  }, [answerCode]);

  const { toasts } = useToasterStore();

  // Ensures that the latest toast replaces the previous one
  useEffect(() => {
    toasts
      .filter((t) => t.visible)
      .filter((_, i) => i >= 1)
      .forEach((t) => toast.dismiss(t.id));
  }, [toasts]);

  const handleLanguageSelection = (e) => {
    e.preventDefault();
    const selectedLanguage = languages.filter(
      (obj) => obj.id === e.target.value
    );
    dispatch(updateLanguage(selectedLanguage[0]));
  };

  const handleSTDINChange = (e) => {
    e.preventDefault();
    dispatch(updateSTDIN(e.target.value));
  };

  const handleChangeUserCode = (payload) => {
    dispatch(updateCode(payload));
  };

  useEffect(() => {
    languages && dispatch(updateLanguage(languages[0]));
  }, [languages]);

  useEffect(() => {
    switch (passedTestCases) {
      case true:
        setOutputColor(lighten(theme.palette.secondary.main, 0.3));
        toast.success("Output is correct", {duration: 3000});
        break;
      case false:
        setOutputColor(lighten(theme.palette.error.main, 0.3))
        toast.error("Output is incorrect", {duration: 3000});
        break;
      default:
        setOutputColor("default");
        break;
    }
  }, [expectedOutput, passedTestCases]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (stdoutRef.current && !stdoutRef.current.contains(event.target)) {
        setOutputColor("default");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box
      display={"flex"}
      justifyContent={showRightBlock ? "center" : "flex-start"}
      direction={"row"}
      mb={8}
      mt={4}
      alignItems={"stretch"}
      xs={{ flexWrap: { xs: "wrap", md: "noWrap" } }}
    >
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        flexWrap={"wrap"}
        ref={editor}
        sx={{
          width: { xs: "100%", md: "60%" },
          marginRight: { xs: 0, md: 2 },
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "20%" }, marginBottom: 2 }}>
          <FormControl fullWidth disabled={disableLanguage}>
            <InputLabel>Language</InputLabel>
            <Select
              id="editor-language-selection"
              value={solutionLanguage || editorLanguage.id}
              label="Language"
              onChange={(e) => handleLanguageSelection(e)}
            >
              {languages &&
                languages.map((language, index) => {
                  return (
                    <MenuItem key={index} value={language.id}>
                      {language.language}
                    </MenuItem>
                  );
                })}
              {solutionLanguage && (
                <MenuItem value={solutionLanguage}>{solutionLanguage}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Editor
            code={solutionCode ? solutionCode : userCode}
            allowEdit={allowEdit}
            allowCopy={true}
            allowPaste={true}
            onChange={handleChangeUserCode}
            language={
              solutionLanguage ? solutionLanguage : editorLanguage.language
            }
            id={id ? id : currentQuestion?.id}
          />
        </Box>
      </Box>
      {showRightBlock && (
        <Box
          display={"flex"}
          justifyContent={"center"}
          flexWrap={"wrap"}
          sx={{
            width: { xs: "100%", md: "40%" },
            marginLeft: { xs: 0, md: 5 },
            marginTop: { xs: 5, md: 3 },
          }}
        >
          <Box width={"100%"} mb={3}>
            <Typography mb={2} variant={"h6"}>
              Input - STDIN
            </Typography>
            <CodeTextField
              ref={stdoutRef}
              value={STDIN}
              multiline
              variant="filled"
              rows={6}
              sx={{
                width: {
                  xs: "100%",
                  md: "90%",
                  fontFamily: "Courier New !important",
                },
              }}
              className="ace_editor"
              onChange={(e) => handleSTDINChange(e)}
            />
          </Box>
          <Box>
            <LoadingButton
              variant="contained"
              style={{
                marginBottom: "4px",
                marginRight: "20px",
              }}
              startIcon={<PlayArrow />}
              onClick={() => runCode()}
              loading={runCodeLoading}
            >
            Run Code
            </LoadingButton>
          </Box>
          <Box width={"100%"}>
            <Typography mb={2} variant={"h6"}>
              Your Output - STDOUT
            </Typography>
            <CodeTextField
              value={STDOUT}
              multiline
              variant="filled"
              rows={6}
              sx={{
                width: "90%",
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: theme.palette.text.primary,
                },
                backgroundColor: outputColor,
                transition: "background-color 0.5s ease",
              }}
              disabled
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default QuestionCodeEditor;
