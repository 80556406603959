import { useEffect, useState } from "react";

export const useFetch = (
  createAxiosPromise,
  callAfterRender = false,
  params = {}
) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [hasFinished, setHasFinished] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (callAfterRender) {
      fetchApi(params);
    }
  }, []);

  const fetchApi = async (otherParams) => {
    setIsPending(true);
    setHasFinished(false);
    try {
      const axiosPromise = createAxiosPromise(otherParams);
      const response = await axiosPromise;
      setData(response.data);
      return {
        data: response.data,
        error: null,
        isPending: false,
        hasFinished: true,
      };
    } catch (error) {
      setError(error);
      return {
        data: null,
        error: error,
        isPending: false,
        hasFinished: true,
      };
    } finally {
      setIsPending(false);
      setHasFinished(true);
    }
  };

  return { data, isPending, hasFinished, error, fetchApi };
};
