import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import AceEditor from "react-ace";
import "ace-builds";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/theme-solarized_light";
import "ace-builds/src-noconflict/theme-solarized_dark";

const title = "Action not allowed";
const icon = "warning";

const Editor = ({
  id,
  code: initialCode,
  language,
  allowPaste,
  allowCopy,
  allowEdit,
  minLines,
  maxLines,
  onChange,
}) => {
  const [copiedContent, setCopiedContent] = useState("");

  const darkMode = useSelector((state) => state.user.darkMode);
  language = language != null && language.toLowerCase();

  useEffect(() => {
    setModeLoaded(false);

    if (language != null) {
      try {
        require(`ace-builds/src-noconflict/mode-${language}`);
        require(`ace-builds/src-noconflict/snippets/${language}`);
      } catch (error) {
        console.error(`Failed to load ACE modules for language: ${language}`, error);
      }
    }

    setModeLoaded(true);
  }, [language]);

  const [code, setCode] = useState("");
  const [modeLoaded, setModeLoaded] = useState(false);

  const buildErrorTextFromTemplate = (action) => {
    return `Sorry, ${action} is not allowed in this editor.`;
  };

  const handleActionNotAllowed = async (action) => {
    await navigator.clipboard.writeText("");
    Swal.fire({
      title,
      text: buildErrorTextFromTemplate(action),
      icon,
    });
  };

  const buildCommands = (allowCopy, allowPaste) => {
    const commands = [];
    if (!allowCopy) {
      commands.push({
        name: "copy",
        bindKey: { win: "Ctrl-C", mac: "Command-C" },
        exec: () => handleActionNotAllowed("copying"),
      });

      commands.push({
        name: "cut",
        bindKey: { win: "Ctrl-X", mac: "Command-X" },
        exec: () => handleActionNotAllowed("cutting"),
      });
    }

    if (!allowPaste) {
      commands.push({
        name: "paste",
        bindKey: { win: "Ctrl-V", mac: "Command-V" },
        exec: () => handleActionNotAllowed("pasting"),
      });
    }

    return commands;
  };

  const handleChange = (newValue) => {
    setCode(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const buildOptions = (allowEdit) => {
    return {
      enableBasicAutocompletion: true,
      enableSnippets: true,
      readOnly: !allowEdit,
      tabSize: 4,
      showLineNumbers: true,
    };
  };

  const handlePaste = async () => {
    const backupCode = code;
    const clipboardText = await navigator.clipboard.readText();

    if (!allowPaste) {
      await navigator.clipboard.writeText("");
    }
    // Only allow to paste content copied from the editor
    if (clipboardText !== copiedContent) {
      setCopiedContent("");
      setCode(backupCode);
    }
  };

  const handleCopy = async () => {
    if (!allowCopy) {
      await navigator.clipboard.writeText("");
    }
    const copiedText = await navigator.clipboard.readText();
    setCopiedContent(copiedText);
  };

  const onLoad = () => {
    setCode(initialCode);
  };

  return (
    <>
      {modeLoaded ? (
        <AceEditor
          key={id.toString()}
          name={id.toString()}
          value={code}
          mode={language}
          theme={darkMode ? "solarized_dark" : "solarized_light"}
          fontSize={16}
          onChange={handleChange}
          onPaste={handlePaste}
          onCopy={handleCopy}
          onCut={handleCopy}
          onLoad={onLoad}
          minLines={minLines}
          maxLines={maxLines}
          width="100%"
          setOptions={buildOptions(allowEdit)}
          commands={buildCommands(allowCopy, allowPaste)}
        />
      ) : null}
    </>
  );
};

Editor.propTypes = {
  id: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  allowPaste: PropTypes.bool.isRequired,
  allowCopy: PropTypes.bool.isRequired,
  allowEdit: PropTypes.bool.isRequired,
  minLines: PropTypes.number,
  maxLines: PropTypes.number,
  onChange: PropTypes.func,
};

export default Editor;
