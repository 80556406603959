import { Auth } from "aws-amplify";

const RefreshSessionCallback = async (callback) => {
  try {
    const session = await Auth.currentSession();
    callback(session);
  } catch (e) {
    console.warn(e);
  }
};

const RefreshSession = () => {
  return new Promise((resolve) => {
    RefreshSessionCallback(resolve);
  });
};

export const getHeaders = async () => {
  const session = await RefreshSession();
  return { Authorization: session.idToken.jwtToken };
};
