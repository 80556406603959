import React, { forwardRef } from "react";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomTextField = styled(TextField)(({}) => ({
  "& .css-1xk009d-MuiInputBase-root-MuiFilledInput-root": {
    fontFamily:
      "'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro'",
  },
}));

const CodeTextField = forwardRef((props, ref) => {
  return <CustomTextField {...props} ref={ref} />;
});

export default CodeTextField;
