import { useState, useEffect } from "react";
import { Chip, CircularProgress, Icon, Link, Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Swal from "sweetalert2";

import { Subtitle, Title } from "components/elements/texts";
import theme from "components/foundations/themes";
import CustomCard from "components/modules/cards";
import MasterPage from "components/modules/masterpage";

import { backend_client } from "helpers/api";

const convert_difficulty_to_color = (level) => {
  let color = "";

  switch (level) {
    case 1:
      color = "#1ABC9C";
      break;
    case 2:
      color = "#F1C40F";
      break;
    case 3:
      color = "#E67E22";
      break;
    case 4:
      color = "#E74C3C";
      break;
    default:
      color = "#7F8C8D";
      break;
  }

  return color;
};

const convert_difficulty_to_string = (level) => {
  let difficulty = "";

  switch (level) {
    case 1:
      difficulty = "Easy";
      break;
    case 2:
      difficulty = "Medium";
      break;
    case 3:
      difficulty = "Hard";
      break;
    case 4:
      difficulty = "Very Hard";
      break;
    default:
      difficulty = "Indeterminated";
      break;
  }

  return difficulty;
};

const sanitize_question_type = (question_type) => {
  if (question_type === "single_choice") {
    return "Single Choice";
  } else if (question_type === "multiple_choice") {
    return "Multiple Choice";
  } else if (question_type === "coding") {
    return "Coding";
  } else {
    return "Indeterminated";
  }
};

const QuestionList = () => {
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [skills, setSkills] = useState([]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 150,
      renderCell: (params) => (
        <Link href={"/thebai/question-details/" + params.row.id}>
          <b>{params.row.id}</b>
        </Link>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      width: 250,
      renderCell: (params) => sanitize_question_type(params.row.type),
    },
    {
      field: "skill_id",
      headerName: "Skill",
      width: 250,
      valueGetter: (rawValue) => {
        return skills.find((skill) => skill.id === rawValue).name;
      },
    },
    {
      field: "difficulty",
      headerName: "Difficulty",
      width: 250,
      renderCell: (params) => (
        <Chip
          label={convert_difficulty_to_string(params.row.difficulty)}
          sx={{
            color: "white",
            backgroundColor: convert_difficulty_to_color(params.row.difficulty),
          }}
        />
      ),
    },
    {
      field: "time",
      headerName: "Time (seconds)",
      width: 150,
    },
    {
      field: "created_by",
      headerName: "Created by",
      width: 400,
    },
    {
      field: "is_validated",
      headerName: "Is Validated",
      width: 150,
      renderCell: (params) =>
        params.row.is_validated ? (
          <Icon sx={{ color: "#1ABC9C" }}>check_circle</Icon>
        ) : (
          <Icon sx={{ color: "#E74C3C" }}>cancel</Icon>
        ),
    },
  ];

  const get_questions = () => {
    setLoading(true);
    var url = "/questions/list/100";
    backend_client
      .get(url)
      .then((response) => {
        setQuestions(response.data);
        get_skills();
      })
      .catch((error) => {
        Swal.fire({
          title: "An error occurred 😱",
          text: "Relax, we are working to fix it",
          icon: "error",
          confirmButtonText: "Got it!",
          confirmButtonColor: theme.palette.primary.main,
        });
        console.error(error);
      })
      .then(function () {});
  };

  const get_skills = () => {
    var url = "/common/skills?return_sorted=true";
    backend_client
      .get(url)
      .then((response) => {
        setSkills(response.data);
      })
      .catch((error) => {
        Swal.fire({
          title: "An error occurred 😱",
          text: "Relax, we are working to fix it",
          icon: "error",
          confirmButtonText: "Got it!",
          confirmButtonColor: theme.palette.primary.main,
        });
        console.error(error);
      })
      .then(function () {
        setLoading(false);
      });
  };

  useEffect(() => {
    get_questions();
  }, []);

  return (
    <MasterPage>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Title value="Questions" />
          <Subtitle value="List of last 100 questions" />
          <br />
          <Box spacing={3}>
            <CustomCard>
              <div
                style={{
                  height: "80vh",
                  width: "100%",
                }}
              >
                <DataGrid
                  rows={questions}
                  columns={columns}
                  disableSelectionOnClick
                  components={{
                    Toolbar: GridToolbar,
                  }}
                />
              </div>
            </CustomCard>
          </Box>
        </>
      )}
    </MasterPage>
  );
};

export default QuestionList;
