import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  RadialLinearScale,
  Title as TitleChart,
} from "chart.js";
import {
  CircularProgress,
  Chip,
  Divider,
  Grid,
  Icon,
  LinearProgress,
  Stack,
  Typography,
  Tooltip,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Box } from "@mui/system";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { Radar } from "react-chartjs-2";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import { Subtitle, Title } from "components/elements/texts";
import CircularProgressScore from "./components/CircularProgressScore";
import CustomCard from "components/modules/cards";
import MasterPage from "components/modules/masterpage";
import QuestionCodeEditor from "pages/ExamQuestion/components/QuestionCodeEditor";
import { Statement } from "pages/ExamQuestion/components";
import getAdminReportService from "./services/getAdminReportService";
import html2pdf from "html2pdf.js";
import { MailOutline } from "@mui/icons-material";

const convert_difficulty_to_color = (difficulty_id) => {
  switch (difficulty_id) {
    case 1:
      return "#1ABC9C";
    case 2:
      return "#F1C40F";
    case 3:
      return "#E67E22";
    case 4:
      return "#E74C3C";
    default:
      return "#7F8C8D";
  }
};

const convert_difficulty_to_string = (difficulty_id) => {
  switch (difficulty_id) {
    case 1:
      return "Easy";
    case 2:
      return "Medium";
    case 3:
      return "Hard";
    case 4:
      return "Very Hard";
    default:
      return "Indeterminated";
  }
};

const get_card_choice_color = (choices_info, current_choice_id) => {
  const choice = choices_info.find(
    ({ choice_id }) => choice_id == current_choice_id
  );

  if (choice.is_correct && choice.is_selected) return "#c1f5c3";
  if (!choice.is_correct && choice.is_selected) return "#f5c1c1";
  return "secondary";
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  TitleChart,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Legend
);

const pdfOptions = {
  margin:       0.5,
  filename:     'report.pdf',
  image:        { type: 'jpeg', quality: 0.98 },
  html2canvas:  { scale: 2 },
  jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
  pagebreak:    { mode: 'avoid-all' }
};

const AdminReport = () => {
  const theme = useTheme();

  const { exam_instance_id } = useParams();

  const ref = useRef(null);
  const pagePDFRef = useRef(null);

  const [examReport, setExamReport] = useState({});
  const [loading, setLoading] = useState(true);
  const [width, setWidth] = useState(0);
  const [dataChart, setDataChart] = useState(null);

  const exportPDF = () => {
    const input = pagePDFRef.current;
    html2pdf(input, pdfOptions).save();
  };

  const getAdminReport = () => {
    setLoading(true);
    getAdminReportService(exam_instance_id)
      .then((response) => {
        setExamReport(response.data);
        formatData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        Swal.fire({
          title: "An error occurred 😱",
          text: "Relax, we are working to fix it",
          icon: "error",
          confirmButtonText: "Got it!",
        });
        console.error(error);
      });
  };

  useEffect(() => {
    getAdminReport();
  }, []);

  // Because the Grid enclosing the Score graph is lazy initialized
  // the graph has width 0 initially, this ensures that when the
  // grid is finished initializing it will update the reference
  // thus triggering the useEffect that handles the graph width
  const setRef = useCallback((node) => {
    if (node !== null) {
      ref.current = node;
      setWidth(node.offsetWidth);
    }
  }, []);

  // Keep listening to the window updates to ensure the graph size
  // is kept consistent with the page layout and window size
  useEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth);
      }
    };

    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const formatData = (examReport) => {
    var labels = [];
    var scores = [];
    for (var score of examReport.score_per_skill) {
      labels.push(score.skill_name);
      scores.push(Math.round(score.skill_score));
    }
    var newData = {
      labels: labels,
      datasets: [
        {
          label: "Skills",
          data: scores,
          backgroundColor: "rgba(33, 100, 109, 0.2)",
          borderColor: "rgba(33, 100, 109, 1)",
          borderWidth: 1,
        },
      ],
    };
    setDataChart(newData);
  };

  const TextWithIcon = ({ title, text, MuiIcon }) => {
    return (
      <Box width="100%" mb={2}>
        <Stack direction="row">
          <MuiIcon
            style={{
              color: theme.palette.primary.main,
            }}
            fontSize="large"
          />
          <Stack ml={1}>
            <Typography color="primary" fontWeight="bold">
              {title}
            </Typography>
            <Typography>{text}</Typography>
          </Stack>
        </Stack>
      </Box>
    );
  };

  TextWithIcon.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    MuiIcon: PropTypes.element,
  };

  const dateOption = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
  };

  return (
    <MasterPage>
      {loading ? (
        <CircularProgress />
      ) : (
        <CustomCard ref={pagePDFRef}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: 3,
            }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Title
                value={examReport.exam_design_name}
                color={theme.palette.primary.main}
              />
            </Stack>
          </Box>
          <Grid container item xs={12}>
            <Grid container item xs={6} md={8}>
              <Button
                variant="contained"
                sx={{ textTransform: "none", marginBottom: "20px" }}
                onClick={exportPDF}
              >Export to PDF</Button>
              <TextWithIcon
                title={"Examinee name"}
                text={examReport.user_name}
                MuiIcon={AccountCircleOutlinedIcon}
              />
              <TextWithIcon
                title={"Examinee email"}
                text={examReport.user_email}
                MuiIcon={MailOutline}
              />

              <TextWithIcon
                title={"Start Time"}
                text={new Date(examReport.start_time).toLocaleDateString(
                  "en-US",
                  dateOption
                )}
                MuiIcon={DateRangeIcon}
              />

              <TextWithIcon
                title={"Finish Time"}
                text={new Date(examReport.finish_time).toLocaleDateString(
                  "en-US",
                  dateOption
                )}
                MuiIcon={DateRangeIcon}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={4}
              justifyContent={"center"}
              ref={setRef}
            >
              <Box sx={{ margin: `0% ${0.15 * width}px` }}>
                <CircularProgressScore
                  radius={0.35 * width}
                  stroke={0.1 * width}
                  progress={Math.round(examReport.score)}
                  subtitle={"Score"}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Subtitle
              value={"Score per skill"}
              color={theme.palette.primary.main}
            />
          </Grid>
          <Divider />
          <Box>
            <br />
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <Box width="100%" px="5%">
                  {examReport?.score_per_skill?.map((score, index) => (
                    <Box width="100%" mb={2} key={index}>
                      <Typography color="primary">
                        {score.skill_name}
                      </Typography>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <LinearProgress
                          variant="determinate"
                          value={score.skill_score}
                          sx={{
                            width: "80%",
                            height: "10px",
                            borderRadius: "5px",
                          }}
                        />
                        <Typography variant="body2" color="primary">
                          {Math.round(score.skill_score)}%
                        </Typography>
                      </Stack>
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box width="100%" px="5%">
                  {dataChart && (
                    <Radar
                      data={dataChart}
                      options={{
                        scales: {
                          r: {
                            beginAtZero: true,
                            max: 100,
                          },
                        },
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginBottom: 3,
            }}
          >
            <Title
              value={"Complete Exam Report"}
              color={theme.palette.primary.main}
            />
          </Box>
          <Box>
            {examReport.questions_report.map((currentQuestion, index) => (
              <Box key={currentQuestion.id} mt={1} mb={2} pb={2}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Title
                      value={`Question ${index + 1}`}
                      color={theme.palette.primary.main}
                    />
                    <Tooltip title="Question ID">
                      <Chip
                        size="small"
                        label={`ID: ${currentQuestion.question.id}`}
                        sx={{
                          marginLeft: "5px",
                        }}
                      />
                    </Tooltip>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Tooltip title="Points">
                      <Chip
                        size="small"
                        label={`Score: ${(
                          parseFloat(
                            parseFloat(currentQuestion.score).toFixed(4)
                          ) * 100
                        ).toFixed(2)}%`}
                        sx={{
                          marginRight: "3px",
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Difficulty">
                      <Chip
                        size="small"
                        label={convert_difficulty_to_string(
                          currentQuestion.question.difficulty
                        )}
                        sx={{
                          color: "white",
                          backgroundColor: convert_difficulty_to_color(
                            currentQuestion.question.difficulty
                          ),
                          marginRight: "3px",
                        }}
                      />
                    </Tooltip>
                    <Tooltip title="Time">
                      <Chip
                        size="small"
                        label={currentQuestion.question.time + " seconds"}
                        sx={{
                          marginRight: "3px",
                        }}
                      />
                    </Tooltip>
                    {currentQuestion.question.is_validated ? (
                      <Tooltip title="Validated">
                        <Icon sx={{ color: "#1ABC9C" }}>check_circle</Icon>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Not validated">
                        <Icon sx={{ color: "#E74C3C" }}>error</Icon>
                      </Tooltip>
                    )}
                  </Stack>
                </Stack>
                <Statement
                  value={currentQuestion.question.statement}
                  editors={currentQuestion.question.code_editors}
                  editorBehaviourEnabled={false}
                  showModeSelector={false}
                />
                {currentQuestion.question.choices.map((q, i) => (
                  <Grid item xs={12} key={q.id} sx={{ marginBottom: "12px" }}>
                    <CustomCard
                      color={get_card_choice_color(
                        currentQuestion.choices_info,
                        q.id
                      )}
                    >
                      <Stack direction="horizontal" alignItems={"center"}>
                        <Icon sx={{ color: theme.palette.primary.main, mr: 1 }}>
                          {q.is_correct_answer
                            ? "task_alt"
                            : "radio_button_unchecked"}
                        </Icon>
                        <div dangerouslySetInnerHTML={{ __html: q.text }} />
                      </Stack>
                    </CustomCard>
                  </Grid>
                ))}
                {currentQuestion.question.type === "coding" && (
                  <QuestionCodeEditor
                    disableLanguage={true}
                    solutionLanguage={
                      currentQuestion.question.solution_code_language
                    }
                    solutionCode={currentQuestion.question.solution_code}
                    allowEdit={false}
                    showRightBlock={false}
                    id={currentQuestion.id}
                  />
                )}
              </Box>
            ))}
          </Box>
        </CustomCard>
      )}
    </MasterPage>
  );
};

export default AdminReport;
