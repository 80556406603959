import axiosInstanceBuilder from "../config/axios/axiosInstanceBuilder";
import { useFetch } from "../config/axios/useFetch";

const baseUrl = process.env.REACT_APP_API_DEV_URL;
const endpoint = "/users/info";

const createAxiosPromise = () => {
  return axiosInstanceBuilder(baseUrl).get(endpoint);
};

export const useUserPersonalInfo = (callAfterMount = false) => {
  const { data, isPending, error, fetchApi } = useFetch(
    createAxiosPromise,
    callAfterMount
  );

  const getUserPersonalInfo = async () => {
    const response = await fetchApi();
    const { data, ...rest } = response;
    return { firstName: data.first_name, lastName: data.last_name, acceptedPolicy: data.accepted_policy, ...rest };
  };

  return {
    userRole: data,
    isLoading: isPending,
    error,
    getUserPersonalInfo,
  };
};
