import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import Swal from "sweetalert2";
import { Auth } from "aws-amplify";

import { Paths } from "../../Routes";
import theme from "../../components/foundations/themes";
import CustomCard from "./../../components/modules/cards";
import { useAppContext } from "./../../libs/contextLib";

import FactoredLogo from "../../assets/images/factored_logo.png";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      align="center"
      {...props}
      style={{ color: theme.palette.grey[400] }}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://factored.ai/">
        Factored
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const confirmationError = "Passwords don't match";

export default function PasswordChange() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPasword, setNewPassword] = useState("");
  const [newPaswordConfirmation, setNewPasswordConfirmation] = useState("");

  const { onAuthStateChange } = useAppContext();

  const navigate = useNavigate();

  const showError = (message) => {
    return Swal.fire({
      title: "Error",
      text: message,
      icon: "error",
      confirmButtonText: "Got it!",
      confirmButtonColor: theme.palette.primary.main,
    });
  };

  const showSuccess = (message) => {
    return Swal.fire({
      title: "Success",
      text: message,
      icon: "success",
      confirmButtonText: "Got it!",
      confirmButtonColor: theme.palette.primary.main,
    });
  };

  const newPasswordAndConfirmationEqual = () =>
    newPasword === newPaswordConfirmation;

  const changePassword = async (event) => {
    event.preventDefault();
    setLoading(true);
    let newPasswordRequired = false;

    try {
      const user = await Auth.signIn(email, oldPassword);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        newPasswordRequired = true;
        await Auth.completeNewPassword(user, newPasword);
      } else {
        await Auth.changePassword(user, oldPassword, newPasword);
      }
      showSuccess("Your password has been changed successfully.").then(() => {
        onAuthStateChange(false);
        Auth.signOut();
        navigate(Paths.LOGIN);
      });
    } catch (e) {
      if (e.message === newPasswordRequired) {
        showError(
          "New password must be at least 2 characters long and cannot start or end with spaces."
        );
      } else {
        showError("Incorrect email or password.");
      }
    } finally {
      setLoading(false);
      onAuthStateChange(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          minHeight: "100vh",
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CustomCard>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img src={FactoredLogo} style={{ width: "50%" }} />
                <Box sx={{ mt: 1 }}>
                  <form onSubmit={changePassword}>
                    <br />
                    <Typography sx={{ fontWeight: "600", fontSize: 14 }}>
                      Email
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      name="email"
                      type="email"
                      onChange={(event) => setEmail(event.target.value)}
                    />
                    <br />
                    <br />
                    <Typography sx={{ fontWeight: "600", fontSize: 14 }}>
                      Old Password
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      name="old-password"
                      type="password"
                      onChange={(event) => setOldPassword(event.target.value)}
                    />
                    <br />
                    <br />
                    <Typography sx={{ fontWeight: "600", fontSize: 14 }}>
                      New Password
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      name="new-password"
                      type="password"
                      onChange={(event) => setNewPassword(event.target.value)}
                    />
                    <br />
                    <br />
                    <Typography sx={{ fontWeight: "600", fontSize: 14 }}>
                      Confirm the New Password
                    </Typography>
                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      required
                      name="new-password-confirmation"
                      type="password"
                      error={!newPasswordAndConfirmationEqual()}
                      helperText={
                        newPasswordAndConfirmationEqual()
                          ? ""
                          : confirmationError
                      }
                      onChange={(event) =>
                        setNewPasswordConfirmation(event.target.value)
                      }
                    />
                    {loading ? (
                      <LoadingButton
                        loading
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, textTransform: "none" }}
                      >
                        Submit
                      </LoadingButton>
                    ) : (
                      <Button
                        disabled={!newPasswordAndConfirmationEqual()}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, textTransform: "none" }}
                        type="submit"
                      >
                        Confirm
                      </Button>
                    )}
                  </form>
                </Box>
              </Box>
            </CustomCard>
            <Grid container sx={{ mt: 1 }}>
              <Grid item xs>
                <Link
                  href={Paths.FORGOT_PASSWORD}
                  variant="body2"
                  style={{ color: "white" }}
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </Box>
    </ThemeProvider>
  );
}
