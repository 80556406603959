import { useAppContext } from "./contextLib";
import { Auth } from "aws-amplify";

const OnError = async (error) => {
  const { onAuthStateChange } = useAppContext();
  let message = error.toString();

  if (!(error instanceof Error) && error.message) {
    message = error.message;
  }
  alert(message);
  await Auth.signOut();
  localStorage.clear();
  onAuthStateChange(false);
  return;
};

export default OnError;
