import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";

const EmailsModal = ({ isOpen, emails, onEmailsModalClosed }) => {
  const theme = useTheme();
  const onCopyToClipboardClicked = () => {
    navigator.clipboard.writeText(emails);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onEmailsModalClosed}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h6" component="h2">
          {"Conflicted Users"}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="num-conflited-users-desciption">
          <Typography
            sx={{ fontWeight: "400", fontSize: 16, textAlign: "Left" }}
          >
            Total: {emails.length}
          </Typography>
        </DialogContentText>
        <List dense={true} sx={{ width: "100%", maxHeight: "240" }}>
          {emails.map((email) => (
            <ListItem key={email}>
              <ListItemText primary={email} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <>
          <Button
            onClick={onEmailsModalClosed}
            variant={"contained"}
            style={{
              backgroundColor: theme.palette.primary.main,
              marginLeft: "10px",
              color: "white",
            }}
            size={"medium"}
          >
            {"Close"}
          </Button>
          <Button
            onClick={onCopyToClipboardClicked}
            variant={"outlined"}
            size={"medium"}
          >
            {"Copy emails to the clipboard"}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

EmailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  emails: PropTypes.array.isRequired,
  onEmailsModalClosed: PropTypes.func.isRequired,
};

export default EmailsModal;
