import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#21646d",
      light: "#42b5c4",
      background: "#b7c8cb",
    },
    secondary: {
      main: "#86e566",
    },
    levels: {
      easy: "#1ABC9C",
      medium: "#F1C40F",
      hard: "#E67E22",
      veryhard: "#7F8C8D",
    },
    common: {
      white: "#FFF",
      black: "#000",
      grayC: "#989898",
      grayD: "#D1D1D1",
    },
    error: {
      main: "#E74C3C",
    }
  },
});

export default theme;
