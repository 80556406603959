import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import DateTimeRangePicker from "./DateTimeRangePicker";
import EmailsContainer from "../components/EmailsContainer";

const InitialState = {
  createdBefore: null,
  createdAfter: null,
  availableBefore: null,
  availableAfter: null,
  createdBy: [],
};

const created = "created";
const available = "available";

const InitialErrors = {
  hasErrorsAtAvailableAfter: false,
  hasErrorsAtAvailableBefore: false,
  hasErrorsAtCreatedAfter: false,
  hasErrorsAtCreatedBefore: false,
};

export const AdvancedForm = ({ onFormDataChanged, onErrorsChanged }) => {
  const [formData, setFormData] = useState(InitialState);
  const [errors, setErrors] = useState(InitialErrors);

  const updateFormData = (newFormData) => {
    setFormData(newFormData);
    onFormDataChanged(newFormData);
  };

  const handleExamCreatorEmailsChanged = (newEmails) => {
    updateFormData({ ...formData, createdBy: newEmails });
  };

  const handleDateTimeRangeChanged = (dateTimeRange, name) => {
    if (name === created) {
      updateFormData({
        ...formData,
        createdAfter: dateTimeRange.initial,
        createdBefore: dateTimeRange.final,
      });
    }
    if (name === available) {
      updateFormData({
        ...formData,
        availableAfter: dateTimeRange.initial,
        availableBefore: dateTimeRange.final,
      });
    }
  };

  const hasErrorsInObject = (obj) => {
    return Object.values(obj).some((error) => error === true);
  };

  const updateErrorAt = (name, hasErrors) => {
    setErrors((prevState) => {
      const newState = { ...prevState, [name]: hasErrors };
      onErrorsChanged(hasErrorsInObject(newState));
      return newState;
    });
  };

  const handleErrorsAtAvaliableAfter = (hasErrors) => {
    updateErrorAt("hasErrorsAtAvailableAfter", hasErrors);
  };

  const handleErrorsAtAvailableBefore = (hasErrors) => {
    updateErrorAt("hasErrorsAtAvailableBefore", hasErrors);
  };

  const handleErrorsAtCreatedAfter = (hasErrors) => {
    updateErrorAt("hasErrorsAtCreatedAfter", hasErrors);
  };

  const handleErrorsAtCreatedBefore = (hasErrors) => {
    updateErrorAt("hasErrorsAtCreatedBefore", hasErrors);
  };

  return (
    <Grid container spacing={3}>
      <Grid container spacing={3} item xs={6}>
        <Grid item xs={12}>
          <DateTimeRangePicker
            name={available}
            disablePast={false}
            initialLabel="Available after"
            finalLabel="Available before"
            onDateTimeRangeChanged={handleDateTimeRangeChanged}
            onHasErrorsAtInitialDateChanged={handleErrorsAtAvaliableAfter}
            onHasErrorsAtFinalDateChanged={handleErrorsAtAvailableBefore}
          />
        </Grid>

        <Grid item xs={12}>
          <DateTimeRangePicker
            name={created}
            disablePast={false}
            initialLabel="Created after"
            finalLabel="Created before"
            onDateTimeRangeChanged={handleDateTimeRangeChanged}
            onHasErrorsAtInitialDateChanged={handleErrorsAtCreatedAfter}
            onHasErrorsAtFinalDateChanged={handleErrorsAtCreatedBefore}
          />
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <EmailsContainer
          placeholder="Insert the exam creators emails"
          title="Exam Creators Emails"
          helperText={`${formData.createdBy.length} emails`}
          onEmailsChanged={handleExamCreatorEmailsChanged}
        />
      </Grid>
    </Grid>
  );
};

AdvancedForm.propTypes = {
  onFormDataChanged: PropTypes.func.isRequired,
  onErrorsChanged: PropTypes.func.isRequired,
};
