import axiosInstanceBuilder from "api/config/axios/axiosInstanceBuilder";
import { mapToEmptyArrayIfNull } from "api/config/axios/utils/mapToEmpyArrayIfNull";
import { useFetch } from "api/config/axios/useFetch";

const baseUrl = process.env.REACT_APP_API_DEV_URL;
const endpoint = "/common/coding-languages?return_sorted=true";

const createAxiosPromise = () => {
  return axiosInstanceBuilder(baseUrl).get(endpoint);
};

export const useFindCodingLanguages = (callAfterMount = false) => {
  const { data, isPending, error, fetchApi } = useFetch(
    createAxiosPromise,
    callAfterMount
  );

  const findCodingLanguages = async () => {
    const response = await fetchApi();
    const { data, ...rest } = response;
    return { languages: mapToEmptyArrayIfNull(data), ...rest };
  };

  return {
    languages: mapToEmptyArrayIfNull(data),
    isLoading: isPending,
    error,
    findCodingLanguages,
  };
};
