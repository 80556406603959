import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { TextField, Autocomplete } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const noSelectedOptionError = "Must select one option";

const useStyles = makeStyles({
  exams: {
    width: "100%",
    marginTop: 40,
    marginBottom: 20,
  },
});

const ExamDesignSelector = ({
  examDesigns,
  isLoading,
  value: selectedOption,
  onChange,
  forceShowErrors,
}) => {
  const classes = useStyles();
  const [isDirty, setIsDirty] = useState(false);

  const validate = (selectedValue) => {
    if (!selectedValue || selectedValue === "") {
      return noSelectedOptionError;
    }

    return null;
  };

  const shouldShowError = ({ hasErrors }) => {
    if ((isDirty || forceShowErrors) && hasErrors) return true;

    return false;
  };

  const getOptionFromValue = (selectedOption) => {
    if (!selectedOption.value) return null;
    return examDesigns.find((o) => o.id === selectedOption.value);
  };

  const handleChange = (newValue) => {
    let newId = null;
    if (newValue) {
      newId = newValue.id;
    }
    const error = validate(newId);

    setIsDirty(true);
    onChange({ value: newId, hasErrors: error !== null });
  };

  return (
    <>
      <Autocomplete
        className={classes.exams}
        value={getOptionFromValue(selectedOption)}
        options={examDesigns}
        loading={isLoading}
        getOptionLabel={(option) => option.name}
        onChange={(event, newValue) => handleChange(newValue)}
        isOptionEqualToValue={(o, v) => o.id === v.id}
        renderInput={(params) => (
          <TextField
            {...params}
            error={shouldShowError(selectedOption)}
            helperText={
              shouldShowError(selectedOption)
                ? validate(selectedOption.value)
                : ""
            }
            label="Select an exam design"
          />
        )}
      />
    </>
  );
};

ExamDesignSelector.propTypes = {
  value: PropTypes.object.isRequired,
  examDesigns: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  forceShowErrors: PropTypes.bool.isRequired,
};

export default ExamDesignSelector;
