import React from "react";
import { PropTypes } from "prop-types";

import Box from "@mui/material/Box";
import { TextField, Typography, Button } from "@mui/material";

import { isEmail } from "../../../utils/validateEmail";

const SendCodeForm = ({ email, onEmailChange, onHandleSendCode }) => {
  const handleSendCode = (e) => {
    e.preventDefault();
    onHandleSendCode(email);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box sx={{ mt: 1 }}>
        <br />
        <Typography sx={{ fontWeight: "600", fontSize: 14 }}>Email</Typography>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          required
          name="email"
          type="email"
          onChange={(event) => onEmailChange(event.target.value)}
        />

        <Button
          disabled={!isEmail(email)}
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, textTransform: "none" }}
          type="submit"
          onClick={handleSendCode}
        >
          Send Code
        </Button>
      </Box>
    </Box>
  );
};

SendCodeForm.propTypes = {
  email: PropTypes.string.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  onHandleSendCode: PropTypes.func.isRequired,
};

export default SendCodeForm;
