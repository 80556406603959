import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import theme from "components/foundations/themes";
import { useCreateQuestion } from "api/question/useCreateQuestion";
import QuestionForm from "components/modules/question/QuestionForm";

const QuestionDesigner = () => {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const { handleCreateQuestion } = useCreateQuestion();

  const handleSubmit = async (question) => {
    setIsSaving(true);
    handleCreateQuestion(question)
      .then(({ id }) => {
        Swal.fire({
          title: "Question saved",
          text: "The Question ID is: " + id,
          confirmButtonText: "Continue",
          confirmButtonColor: theme.palette.primary.main,
        }).then(() => {
          navigate(`/thebai/question-details/${id}`);
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <>
      {isSaving ? (
        <CircularProgress />
      ) : (
        <QuestionForm
          title="Question Designer"
          subtitle="Create your questions using this form"
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default QuestionDesigner;
